import React from "react";
import Typography from "@material-ui/core/Typography";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import WarningIcon from "@material-ui/icons/Warning";
import PowerOffIcon from "@material-ui/icons/PowerOff";
import HelpIcon from "@material-ui/icons/Help";
import ServiceIcon from "../../../../../../icons/Service";
import { parseText } from "../../../../../../utils/helpers/text";
import { generateID } from "@digitallab/grid-common-components";

import {
  iconStyle,
  StatusStyled,
  ErrorStatusesDetailed,
  NormalStatusesDetailed,
  UnknownStatusesDetailed,
  ServiceStatusesDetailed
} from "../../shared/statues";
import { Module } from "../../../../../../constants";

const getIcon = (status) => {
  const statusText = parseText(status);

  if (Object.values(NormalStatusesDetailed).includes(statusText)) {
    return (
      <PlayCircleFilledWhiteIcon
        data-testid="normal-icon"
        color="primary"
        style={iconStyle}
        id={generateID.UUID(Module.MonitoringName, `devicecard`, "playcircle")}
      />
    );
  }
  if (Object.values(ErrorStatusesDetailed).includes(statusText)) {
    return (
      <WarningIcon 
      id={generateID.UUID(Module.MonitoringName, `devicecard`, "warningicon")}
      data-testid="error-icon" color="error" style={iconStyle} />
    );
  }
  if (Object.values(ServiceStatusesDetailed).includes(statusText)) {
    return (
      <ServiceIcon
        id={generateID.UUID(Module.MonitoringName, `devicecard`, "serviceicon")}
        data-testid="service-icon"
        className="orange"
        style={iconStyle}
      />
    );
  }
  if (Object.values(UnknownStatusesDetailed).includes(statusText)) {
    return (
      <PowerOffIcon
        id={generateID.UUID(Module.MonitoringName, `devicecard`, "poweroff")}
        data-testid="unknown-icon"
        className="gray"
        style={iconStyle}
      />
    );
  }
  return (
    <HelpIcon 
    id={generateID.UUID(Module.MonitoringName, `devicecard_instrumentstatus`, "help_icon")}
    data-testid="help-icon" className="gray" style={iconStyle} />
  );
};

const InstrumentStatus = ({ detailedStatus }) => {
  return (
    <StatusStyled id={generateID.UUID(Module.MonitoringName, `devicecard_instrumentstatus`, "style")}>
      {getIcon(detailedStatus)}{" "}
      <Typography
        variant="subtitle1"
        data-testid="deviceCardInstrumentStatusText"
        style={{ fontSize: 14 }}
        id={generateID.UUID(Module.MonitoringName, `devicecard_instrumentstatus`, "typography")}
      >
        {detailedStatus ?? "-"}
      </Typography>
    </StatusStyled>
  );
};

export default InstrumentStatus;
