import React from "react";
import Typography from "@material-ui/core/Typography";
import { BasicPartialCardHeaderLayout } from "../../shared/statues";
import { mainTheme } from "../../../../../../utils/theme/main";
import DATA_MODEL_TABLE from "../../../../../../utils/constants/dataModelTable";
import { Tooltip, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Module } from "../../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const useStyles = makeStyles(() => ({
  typography: {
    textOverflow: "ellipsis",
    maxWidth: "100px",
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
}));

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    padding: 15,
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: theme.typography.pxToRem(16),
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.301961)"
  }
}))(Tooltip);

const DeviceCardHeader = ({ data }) => {
  const classes = useStyles();
  return (
    <BasicPartialCardHeaderLayout
      data-testid={`deviceCardHeader-${data?.inventoryId}`}
      id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "layout")}
    >
      <div id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "div")}>
        <Typography
          data-testid="equipmentModelLabel"
          variant="caption"
          display="block"
          style={{ color: mainTheme.indicator.unknown }}
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography")}
        >
          {DATA_MODEL_TABLE.equipmentModel.value}
        </Typography>
        <CustomTooltip
          title={<ValCheck item={data?.equipmentModel} />}
          placement="bottom-end"
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "custom_tooltip")}
        >
          <Typography
            variant="subtitle2"
            display="block"
            data-testid="equipmentModel"
            gutterBottom
            className={classes.typography}
            id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography_2")}
          >
            <ValCheck item={data?.equipmentModel} id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "valcheck")}/>
          </Typography>
        </CustomTooltip>
        <Typography
          data-testid="serialNumberLabel"
          variant="caption"
          display="block"
          style={{ color: mainTheme.indicator.unknown }}
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography_3")}
        >
          {DATA_MODEL_TABLE.serialNumber.value}
        </Typography>
        <CustomTooltip
          title={<ValCheck id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "valcheck_2")} item={data?.serialNumber} />}
          placement="bottom-end"
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "tooltip_2")}
        >
          <Typography
            variant="subtitle2"
            display="block"
            data-testid="serialNumber"
            className={classes.typography}
            id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography_4")}
          >
            <ValCheck item={data?.serialNumber} id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "tooltip_3")}/>
          </Typography>
        </CustomTooltip>
      </div>
      <div>
      <Typography
          data-testid="equipmentNickNameLabel"
          variant="caption"
          display="block"
          style={{ color: mainTheme.indicator.unknown }}
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography_5")}
        >
          {DATA_MODEL_TABLE.equipmentNickName.value}
        </Typography>
        <CustomTooltip
          title={<ValCheck item={data?.equipmentNickName} id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "valcheck_3")}/>}
          placement="bottom-end"
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "tooltip_4")}
        >
          <Typography
            variant="subtitle2"
            display="block"
            data-testid="equipmentNickName"
            gutterBottom
            className={classes.typography}
            id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography_6")}
          >
            <ValCheck item={data?.equipmentNickName} id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "valcheck_4")}/>
          </Typography>
        </CustomTooltip>
        <Typography
          data-testid="materialNumberLabel"
          variant="caption"
          display="block"
          style={{ color: mainTheme.indicator.unknown }}
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography_7")}
        >
          {DATA_MODEL_TABLE.materialNumber.value}
        </Typography>
        <CustomTooltip
          title={<ValCheck item={data?.materialNumber} id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "valcheck_5")} />}
          placement="bottom-end"
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "tooltip_5")}
        >
          <Typography
            variant="subtitle2"
            display="block"
            data-testid="materialNumber"
            className={classes.typography}
            id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography_8")}
          >
            <ValCheck item={data?.materialNumber} id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "valcheck_6")}/>
          </Typography> 
        </CustomTooltip>       
      </div>
      <div>
      <Typography
          data-testid="locationLabel"
          variant="caption"
          display="block"
          style={{ color: mainTheme.indicator.unknown }}
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography_9")}
        >
          {DATA_MODEL_TABLE.location.value}
        </Typography>
        <CustomTooltip
          title={<ValCheck item={data?.location} />}
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "tooltip_6")}
          placement="bottom-end"
        >
          <Typography
            variant="subtitle2"
            display="block"
            data-testid="location"
            gutterBottom
            className={classes.typography}
            id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography_10")}
          >
            <ValCheck item={data?.location} id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "valcheck_7")}/>
          </Typography>
        </CustomTooltip>
        <Typography
          data-testid="equipmentIdLabel"
          variant="caption"
          display="block"
          style={{ color: mainTheme.indicator.unknown }}
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography_11")}
        >
          {DATA_MODEL_TABLE.equipmentId.value}
        </Typography>
        <CustomTooltip
          title={<ValCheck item={data?.equipmentId} id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "valcheck_8")} />}
          placement="bottom-end"
          id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "tooltip_7")}
        >
          <Typography
            variant="subtitle2"
            display="block"
            data-testid="equipmentId"
            className={classes.typography}
            id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "typography_12")}
          >
            <ValCheck item={data?.equipmentId} id={generateID.UUID(Module.MonitoringName, `devicecardheader`, "valcheck_9")}/>
          </Typography>
        </CustomTooltip>
      </div>
    </BasicPartialCardHeaderLayout>
  );
};

export const ValCheck = ({ item }) => {
  if (
    item !== null &&
    item !== "" &&
    typeof item === "string"
  ) {
    return item === "null" ? "-" : item;
  }
  else {
    return "-";
  }
};

export default DeviceCardHeader;
