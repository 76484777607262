import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { CircularProgress } from "@material-ui/core";
import { CenterStyled } from "./DeviceCards";
import {
  selectDeviceToDisplaySelector,
  getInstrumentsSelector,
  getDevicesWithInfo,
  getMonitor,
  getInstruments
} from "../../../../selectors";
import { Error } from "../../../../components/shared";
import WelcomeScreen from "../../../../components/WelcomeScreen";
import { findSelectedItem } from "../../../../utils/helpers/array";
import { compose } from "redux";
import DevicesViewSelector from "./DevicesViewSelector";
import { DeviceStatusesContext } from "./DeviceStatusesWrapper";
import { fetchChunkData as fetchDeviceStatuses } from "./fetchingDeviceStatuses";
import { useParams, useHistory } from "react-router-dom";
import { changeFormFilters as changeFormFiltersAction } from "../../instrumentsFilter/redux/actions";
import { equipmentLabel, Module } from "../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const DevicesLoaderList = ({
  client,
  selected,
  monitor,
  instruments,
  alldevices,
  controls,
  changeFormFilters
}) => {
  const { InventoryId } = useParams();
  const [inventoryId, setInventoryId] = useState(
    InventoryId ? [{ inventoryId: InventoryId }] : null
  );
  const [loadTrigger, setLoadTrigger] = useState(false);
  const navigation = useHistory();
  let filtered_devices = monitor.map((e) => {
    const foundDevice = alldevices.find(
      (element) => element.inventoryId === e.inventoryId
    );
    return foundDevice;
  });
  filtered_devices = filtered_devices.filter((item) => item !== undefined);
  const { fetching, items, error, dispatchAction } = useContext(
    DeviceStatusesContext
  );
  useEffect(() => {
    const loadData = async () => {
      dispatchAction({
        type: "fetching",
        payload: true
      });
      const { items: itemsResult, error: errorResult } =
        await fetchDeviceStatuses({
          client,
          devicesToMonitor: filtered_devices
        });
      if (errorResult) {
        dispatchAction({
          type: "error",
          payload: errorResult?.error
        });
      } else {
        dispatchAction({
          type: "data",
          payload: itemsResult
        });
      }
    };
    if (monitor.length) {
      loadData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitor.length]);

  useEffect(() => {
    if (
      inventoryId &&
      loadTrigger &&
      (controls.name !== "" || controls.type !== "" || controls.location !== "")
    ) {
      setInventoryId(null);
      setLoadTrigger(!loadTrigger);

      navigation.push("/");
    }
    setLoadTrigger(!loadTrigger);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls]);

  if (Array.isArray(selected) && !selected.length && !inventoryId)
    return (
      <WelcomeScreen
        id={generateID.UUID(Module.MonitoringName, `loader`, "welcome")}
        text={`Please filter ${equipmentLabel.lowerCase} that you would like to view and monitor.`}
      />
    );
  if (fetching && !inventoryId) {
    return (
      <CenterStyled
        data-testid="loader"
        id={generateID.UUID(Module.MonitoringName, `loader`, "center_style")}
      >
        <CircularProgress
          size={60}
          id={generateID.UUID(Module.MonitoringName, `loader`, "circle")}
        />
      </CenterStyled>
    );
  }

  if (error) {
    return (
      <Error
        data-testid="error"
        error={error}
        id={generateID.UUID(Module.MonitoringName, `loader`, "error")}
      />
    );
  }
  const findItem = findSelectedItem(inventoryId ? inventoryId : selected);
  const devicesWithInfo = inventoryId
    ? getDevicesWithInfo(inventoryId, instruments)
    : getDevicesWithInfo(items, instruments);

  const filteredDevices = inventoryId
    ? [devicesWithInfo[0]].filter(findItem)
    : devicesWithInfo.filter(findItem);
  return <DevicesViewSelector devices={filteredDevices} />;
};

const mapStateToProps = (state) => ({
  selected: selectDeviceToDisplaySelector(state),
  monitor: getMonitor(state),
  instruments: getInstrumentsSelector(state),
  alldevices: getInstruments(state),
  controls: state.devices.controls
});

export default compose(
  withApollo,
  connect(mapStateToProps, { changeFormFilters: changeFormFiltersAction })
)(DevicesLoaderList);
