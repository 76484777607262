import { useState } from "react";
import styled, { css } from "styled-components";

const openStyles = css`
  max-height: 500px;
`;

const DeviceExpansionPanelStyled = styled.div`
  width: 400px;
  max-width: 100%;
  box-sizing: border-box;
  & > .header {
    cursor: pointer;
    & > div > h6 {
      flex: 0 1 150px;
    }
  }
  & > .content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease-in;
    ${(props) => props.open && openStyles};
  }
`;

const DeviceExpansionPanel = ({ children, renderHeader }) => {
  const [open, setOpen] = useState(false);
  return (
    <DeviceExpansionPanelStyled data-testid="deviceExpansionPanel" open={open}>
      <div
        className="header"
        data-testid="deviceExpansionPanelButton"
        onClick={() => setOpen(!open)}
      >
        {typeof renderHeader === "function" ? renderHeader({ open }) : ""}
      </div>
      <div className="content">{children}</div>
    </DeviceExpansionPanelStyled>
  );
};

export default DeviceExpansionPanel;
