import React from "react";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import NotificationsOffOutlinedIcon from "@material-ui/icons/NotificationsOffOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import { Mutation, withApollo } from "react-apollo";
import { compose } from "redux";
import { setMonitoringDevicesNotification as setMonitoringDevicesNotificationAction } from "../../redux/actions";
import { UPDATE_DIGITAL_LAB_DEVICE_USER } from "../../../../../gql/devices/mutation";
import { intersectionWith } from "lodash";
import styled, { css } from "styled-components";
import {
  getNotificationSubscriptionsSelector,
  selectDeviceToDisplaySelector
} from "../../../../../selectors";
import { useMobile } from "../../../../../utils/hooks";
import { updateDigitalLabDeviceUserNotification } from "../../devicesViews/shared/mutations";
import { DeviceComparator } from "../../../instrumentsFilter/redux/reducer";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../../constants";

const NotificationCustomLabelStyled = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  & > .label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }
`;

const mobileStylesForSwitcher = css`
  margin-top: 0.5rem;
`;
const NotificationAllSwitcherStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background-color: ${(props) => props.theme.card.backgroundColor};
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 180px;
  padding: 4px 12px 4px 10px;
  border: ${(props) => props.theme.card.border};
  & > .switcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${(props) => props.isMobile && mobileStylesForSwitcher}
  &>div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const NotificationAllStates = {
  UNCHECKED: "UNCHECKED",
  CHECKED: "CHECKED",
  INTERMEDIATE: "INTERMEDIATE"
};

export const getNotificationAllState = (
  selected,
  notificationSubscriptions
) => {
  if (!Array.isArray(selected) || !Array.isArray(notificationSubscriptions))
    return null;
  const checkedAll = intersectionWith(
    selected,
    notificationSubscriptions,
    DeviceComparator
  );
  if (checkedAll.length === selected.length)
    return NotificationAllStates.CHECKED;
  if (checkedAll.length) return NotificationAllStates.INTERMEDIATE;
  return NotificationAllStates.UNCHECKED;
};

const getCheckboxProps = (state) => {
  switch (state) {
    case NotificationAllStates.CHECKED:
      return { checked: true };
    case NotificationAllStates.UNCHECKED:
      return { checked: false };
    default:
      return {
        indeterminate: true,
        checked: true,
        color: "primary",
        inputProps: { "aria-label": "indeterminate checkbox" }
      };
  }
};

const getIcon = (state) => {
  switch (state) {
    case NotificationAllStates.CHECKED:
      return (
        <NotificationsActiveOutlinedIcon
          color="primary"
          data-testid="checked-icon"
          id={generateID.UUID(Module.MonitoringName, `get_icon`, "notification_check")}
        />
      );
    case NotificationAllStates.UNCHECKED:
      return <NotificationsOffOutlinedIcon data-testid="unchecked-icon"  id={generateID.UUID(Module.MonitoringName, `get_icon`, "notification_uncheck")}/>;
    default:
      return <NotificationsNoneIcon data-testid="intermediate-icon" id={generateID.UUID(Module.MonitoringName, `get_icon`, "notification_intermediate")} />;
  }
};

export const NotificationAllSwitcher = ({
  selected,
  setMonitoringDevicesNotification,
  id,
  email,
  notificationSubscriptions
}) => {
  const isMobile = useMobile();
  const state = getNotificationAllState(selected, notificationSubscriptions);
  const checkBoxProps = getCheckboxProps(state);
  if (!state) return null;
  return (
    <NotificationAllSwitcherStyled
      isMobile={isMobile}
      data-testid="notificationAllSwitcher"
      id={generateID.UUID(Module.MonitoringName, `notification_all`, "style")}
    >
      <Mutation id={generateID.UUID(Module.MonitoringName, `notification_all`, "mutation")} mutation={UPDATE_DIGITAL_LAB_DEVICE_USER}>
        {(mutation) => (
          <FormControlLabel
            control={
              <Checkbox
               id={generateID.UUID(Module.MonitoringName, `notificationall`, "checkout")}
                color="primary"
                {...checkBoxProps}
                onChange={() =>
                  updateDigitalLabDeviceUserNotification({
                    mutation,
                    updateAction: setMonitoringDevicesNotification,
                    payload: {
                      id,
                      email,
                      notificationSubscriptions,
                      ids: selected,
                      isOn: state === NotificationAllStates.CHECKED
                    }
                  })
                }
              />
            }
            id={generateID.UUID(Module.MonitoringName, `notificationall`, "form_control_label")}
            data-testid="notification-form-label"
            label={
              <NotificationCustomLabelStyled id={generateID.UUID(Module.MonitoringName, `notificationall`, "style_label")}>
                <div className="label" id={generateID.UUID(Module.MonitoringName, `notification_all`, "label")}>
                  <span id={generateID.UUID(Module.MonitoringName, `notification_all`, "span_onoff")}>Turn ON/OFF</span>
                  <span id={generateID.UUID(Module.MonitoringName, `notification_all`, "span_all")} >all notifications</span>
                </div>
              </NotificationCustomLabelStyled>
            }
          />
        )}
      </Mutation>
      <div id={generateID.UUID(Module.MonitoringName, `notification_all`, "div_icon")}>{getIcon(state)}</div>
    </NotificationAllSwitcherStyled>
  );
};

const mapStateToProps = (state) => ({
  selected: selectDeviceToDisplaySelector(state),
  id: state.userMonitorDevices.id,
  email: state.userMonitorDevices.email,
  notificationSubscriptions: getNotificationSubscriptionsSelector(state)
});

export default compose(
  connect(mapStateToProps, {
    setMonitoringDevicesNotification: setMonitoringDevicesNotificationAction
  }),
  withApollo
)(NotificationAllSwitcher);
