import React from "react";
import { BasicPartialCardLayout } from "../../shared/statues";
import DeviceCardNotification from "./DeviceCardNotification";
import DeviceCardInstrumentStatus from "./DeviceCardInstrumentStatus";
import { Module } from "../../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const DeviceCardContent = ({ data }) => {
  return (
    <BasicPartialCardLayout
      data-testid={`deviceCardContent-${data?.inventoryId}`}
      slim
      id={generateID.UUID(Module.MonitoringName, `devicecard_content`, "layout")}
    >
      <DeviceCardInstrumentStatus 
      id={generateID.UUID(Module.MonitoringName, `devicecard_content`, "status")}
      detailedStatus={data?.detailedStatus} />
      <DeviceCardNotification
        id={generateID.UUID(Module.MonitoringName, `devicecard_content`, "notification")}
        inventoryId={data?.inventoryId}
      />
    </BasicPartialCardLayout>
  );
};

export default DeviceCardContent;
