import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ListIcon from "@material-ui/icons/List";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { connect } from "react-redux";
import { setInstrumentsView as setInstrumentsViewAction } from "../../redux/actions";
import { makeStyles } from "@material-ui/core";
import { Module } from "../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const useStyles = makeStyles({
  buttonGroup: {
    height: 48
  }
});

const InstrumentsViewChanger = ({ setInstrumentsView, instrumentsView }) => {
  const handleChange = (view) => () => {
    setInstrumentsView(view);
  };
  const classes = useStyles();

  const getStyles = (evals) =>
    evals ? { color: "primary", variant: "contained" } : {};
  return (
    <ButtonGroup
      classes={{
        root: classes.buttonGroup
      }}
      variant="outlined"
      color="default"
      size="small"
      aria-label="change instruments view"
      id={generateID.UUID(Module.MonitoringName, `viewchanger`, "button_group")}
    >
      <Button
        onClick={handleChange("cards")}
        data-testid="cards-instruments-view-changer"
        {...getStyles(instrumentsView === "cards")}
        id={generateID.UUID(Module.MonitoringName, `viewchanger`, "button")}
      >
        <ViewModuleIcon id={generateID.UUID(Module.MonitoringName, `viewchanger`, "module_icon")} />
      </Button>
      <Button
        onClick={handleChange("list")}
        data-testid="list-instruments-view-changer"
        {...getStyles(instrumentsView === "list")}
        id={generateID.UUID(Module.MonitoringName, `viewchanger`, "button_list")}
      >
        <ListIcon id={generateID.UUID(Module.MonitoringName, `viewchanger`, "icon_list")}/>
      </Button>
    </ButtonGroup>
  );
};

const mapStateToProps = (state) => ({
  instrumentsView: state.userMonitorDevices.instrumentsView
});

export default connect(mapStateToProps, {
  setInstrumentsView: setInstrumentsViewAction
})(InstrumentsViewChanger);
