import React from "react";
import { connect } from "react-redux";
import DeviceCards from "./DeviceCards";
import DeviceList from "./DeviceList";
import { Switch, Route } from "react-router-dom";
import { TitleTVMode } from "../../../../components/shared";
import { useQuery } from "../../../../utils/hooks";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

const DevicesViewSelector = ({ devices, instrumentsView }) => {
  return (
    <Switch>
      <Route path="/tv/:query">
        <DevicesWithTitle 
        id={generateID.UUID(Module.MonitoringList, `list`, "title")}
        devices={devices} instrumentsView={instrumentsView} />
      </Route>
      <Route exact path="/">
        {instrumentsView === "cards" ? (
          <DeviceCards devices={devices} />
        ) : (
          <DeviceList devices={devices} />
        )}
      </Route>
      <Route exact path="/equipmentList/:InventoryId">
        {instrumentsView === "cards" ? (
          <DeviceCards devices={devices} />
        ) : (
          <DeviceList devices={devices} />
        )}
      </Route>
    </Switch>
  );
};

const DevicesWithTitle = ({ devices, instrumentsView }) => {
  const { label = "" } = useQuery();
  return (
    <>
      {label && <TitleTVMode id={generateID.UUID(Module.MonitoringList, `list`, "title_mode")}>{label}</TitleTVMode>}
      {instrumentsView === "cards" ? (
        <DeviceCards devices={devices} />
      ) : (
        <DeviceList devices={devices} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  instrumentsView: state.userMonitorDevices.instrumentsView
});

export default connect(mapStateToProps)(DevicesViewSelector);
