import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { getEnv } from "../../../utils/helpers/text";

const currentEnv = getEnv() ? "42px" : 0;
const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  flex-shrink: 0;
  // margin-top: ${currentEnv};
`;

const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
`;
const useStyles = makeStyles((theme) => ({
  closeButton: {
    color: theme.palette.primary,
    margin: 0,
    padding: 0
  }
}));

const HistoryTopHeader = ({ onClick }) => {
  const classes = useStyles();

  return (
    <HeaderTop>
      <HeaderTitle data-testid="header-title">Recent run statuses</HeaderTitle>
      <IconButton
        data-testid="header-close-button"
        aria-label="close"
        color="primary"
        classes={{ root: classes.closeButton }}
        onClick={onClick}
      >
        <CloseIcon data-testid="header-close-button-icon" />
      </IconButton>
    </HeaderTop>
  );
};
export default HistoryTopHeader;
