import * as types from "./actionTypes";
import initialState from "./initialState";

const setMonitoringNotification = (state, payload) => {
  const newValues = Array.isArray(payload) ? payload : [payload];
  return { ...state, notificationSubscriptions: [...newValues] };
};

export default function reducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case types.LOAD_MONITORING_DEVICES_USER_INFO:
      return { ...state, ...payload };
    case types.SET_MONITORING_DEVICES_NOTIFICATION:
      return setMonitoringNotification(state, payload);
    case types.SET_VISIBILITY_DEVICES:
      return { ...state, visibilityFilter: payload };
    case types.SET_INSTRUMENTS_VIEW:
      return { ...state, instrumentsView: payload };
    case types.SET_SITE_NAME:
      return { ...state, siteName: payload };
    default:
      return state;
  }
}
