import * as R from "ramda";

export const filterText =
  (key = "", value = "") =>
  (arr = []) =>
    R.compose(
      R.filter(R.compose(R.includes(R.toLower(value)), R.toLower, R.prop(key)))
    )(arr);

export const filterTextAny =
  (filters = []) =>
  (arr = []) =>
    R.compose(
      R.filter(
        R.anyPass(
          filters.map((filter) =>
            R.compose(
              R.includes(R.toLower(filter.value)),
              R.toLower,
              R.prop(filter.key)
            )
          )
        )
      )
    )(arr);

export const selectValuesByKey =
  (key) =>
  (arr = []) =>
    R.compose(
      R.sortBy((x) => x),
      R.uniq,
      R.filter((x) => !!x),
      R.map(R.compose(R.toLower, R.prop(key)))
    )(arr);
