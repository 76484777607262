import React from "react";
import { withApollo } from "react-apollo";
import { subscribeToFilterChange } from "./signout";

class SubscriptionFilter extends React.Component {
  componentDidMount() {
    if (this.props.client) subscribeToFilterChange(this.props.client);
  }
  render() {
    return this.props.children;
  }
}

export default withApollo(SubscriptionFilter);
