import React from "react";
import styled, { css } from "styled-components";
import { useMobile } from "../utils/hooks";
import { equipmentLabel, Module } from "../constants";
import { generateID } from "@digitallab/grid-common-components";

const welcomeScreenMobile = css`
  height: auto;
  transform: none;
`;
const welcomeScreenH2Mobile = css`
  font-size: 36px;
`;
const welcomeScreenH5Mobile = css`
  font-size: 12px;
`;

const WelcomeScreenStyled = styled.div`
  margin-top: 38px;
  font-family: Imago, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(65vh - 58px);
  transform: translateY(-40px);
  ${(props) => props.isMobile && welcomeScreenMobile}

  color: #0066cc;
  & > h2 {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 0;
    ${(props) => props.isMobile && welcomeScreenH2Mobile}
    & > span {
      font-weight: 400;
    }
  }
  & > h5 {
    font-size: 18px;
    ${(props) => props.isMobile && welcomeScreenH5Mobile}
    color: #333;
  }
`;

const WelcomeScreen = ({
  text = `You must login to view ${equipmentLabel.lowerCase}.`
}) => {
  const isMobile = useMobile();
  return (
    <WelcomeScreenStyled
      isMobile={isMobile}
      data-testid="main-page-not-authenticated-with-no-error-by-backend"
      id={generateID.UUID(Module.MonitoringName, `welcome`, "style")}
    >
      <h2 id={generateID.UUID(Module.MonitoringName, `welcome`, "h2")}>
        {equipmentLabel.pascalCase} Monitoring
        <br /> <span id={generateID.UUID(Module.MonitoringName, `welcome`, "span")}>Welcome</span>
      </h2>
      <h5 id={generateID.UUID(Module.MonitoringName, `welcome`, "h5")}>{text}</h5>
    </WelcomeScreenStyled>
  );
};

export default WelcomeScreen;
