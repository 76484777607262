import React from "react";
import { DeviceHistoryOpenButton } from "../../../instrumentsFilter/DeviceFilterButtons";
import DeviceItemNotification from "../deviceListItem/DeviceItemNotification";
import styled from "styled-components";
import BookingLink from "../deviceCard/deviceCardElements/BookingLink";

const DeviceActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const DeviceActions = (props) => {
  return (
    <DeviceActionsStyled data-testid="device-actions">
      <BookingLink {...props} type="button" />
      <DeviceHistoryOpenButton {...props} />
      <DeviceItemNotification {...props} />
    </DeviceActionsStyled>
  );
};

export default DeviceActions;
