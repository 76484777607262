const StartTime = (props) => (
  <svg
    width="17"
    height="23"
    viewBox="0 0 17 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.153 18.361H2V16.526H0V22.189H2V20.361H12.153V22.361L16.143 19.361L12.153 16.361V18.361Z"
      fill="#737373"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="17"
      height="23"
    >
      <rect width="16.142" height="22.36" fill="black" />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.883 15.7668C12.23 15.7668 15.767 12.2298 15.767 7.88282C15.767 3.53682 12.23 -0.000183105 7.883 -0.000183105C3.536 -0.000183105 0 3.53682 0 7.88282C0 12.2298 3.536 15.7668 7.883 15.7668ZM7.883 1.99982C11.127 1.99982 13.767 4.63882 13.767 7.88282C13.767 11.1268 11.127 13.7668 7.883 13.7668C4.639 13.7668 2 11.1268 2 7.88282C2 4.63882 4.639 1.99982 7.883 1.99982Z"
        fill="#737373"
      />
      <path
        d="M11.2821 10.0648L8.63306 7.55982V4.99982H7.13306V8.20682L10.2521 11.1548L11.2821 10.0648Z"
        fill="#737373"
      />
    </g>
  </svg>
);
export default StartTime;
