import { ToastContainer, toast } from "react-toastify";
import styled, { css } from "styled-components";
import { equipmentLabel } from "../../constants"; 

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: "toast-container",
  toastClassName: "toast",
  bodyClassName: "body",
  progressClassName: "progress"
})`
  height: 72px;
  width: 400px;
  border-radius: 4px;

  .toast {
    top: 56px;
    padding: 0;
    box-sizing: border-box;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.34902);
  }

  button[aria-label="close"] {
    display: none;
  }

  .body {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .progress {
    background: transparent;
  }
`;

const successStyles = css`
  color: ${(props) => props.theme.notification.success.color};
  background: ${(props) => props.theme.notification.success.backgroundColor};
  border: ${(props) => props.theme.notification.success.border};
  & > .icon {
    background: ${(props) =>
      props.theme.notification.success.backgroundIconColor};
    color: ${(props) => props.theme.notification.success.backgroundColor};
  }
`;
const waringStyles = css`
  color: ${(props) => props.theme.notification.warning.color};
  background: ${(props) => props.theme.notification.warning.backgroundColor};
  border: ${(props) => props.theme.notification.warning.border};
  & > .icon {
    background: ${(props) =>
      props.theme.notification.warning.backgroundIconColor};
    color: ${(props) => props.theme.notification.warning.backgroundColor};
  }
`;
const defaultStyles = css``;
export const CustomNotificationStyled = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  height: 100%;
  min-height: 72px;
  color: black;
  background: white;
  border: 2px solid black;
  & > .text,
  & > .icon {
    word-wrap: break-all;
    box-sizing: border-box;
    transform: scale(1.03);
    & > i {
      font-size: 38px;
    }
  }
  & > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 72px;
    height: auto;
    background: black;
    color: white;
  }
  & > .text {
    font-family: "Roboto", sans-serif;
    flex: 1 1 auto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    padding: 16px 24px 15px 19px;
  }
  ${(props) => {
    switch (props.type) {
      case "success": {
        return successStyles;
      }

      case "warning": {
        return waringStyles;
      }
      default: {
        return defaultStyles;
      }
    }
  }}
`;

export const CustomNotification = ({ type, icon, text, appName }) => (
  <CustomNotificationStyled data-testid="notify" type={type}>
    <div className="icon" data-testid="notify-icon">
      <i className="one-icons">{icon}</i>
    </div>
    <div className="text" data-testid="notify-text">
      <strong data-testid="notify-name-text">{appName}</strong> {text}
    </div>
  </CustomNotificationStyled>
);

export const Notify = ({
  icon = "caution",
  text = "",
  appName = `${equipmentLabel.pascalCase} name`,
  type = "warning"
}) => toast(<CustomNotification {...{ icon, text, appName, type }} />);

export default Notify;
