import React from "react";
import NoConnectionIcon from "../icons/NoConnectionIcon";
import { useMobile } from "../utils/hooks/useMobile";
import { ErrorScreenStyled, ErrorbuttonStyled } from "./NotFound";

const NoConnectionScreen = ({
  text = "We are having difficulty connecting to the server. Check your connection or try again later.",
  title = "No connection",
  onCallback
}) => {
  const isMobile = useMobile();
  return (
    <ErrorScreenStyled
      isMobile={isMobile}
      data-testid="main-page-not-authenticated-with-error-conection"
    >
      <NoConnectionIcon />
      <h2>{title}</h2>
      <h5>{text}</h5>
      <ErrorbuttonStyled
        data-testid="main-page-action-button-error-conection"
        variant="contained"
        color="primary"
        onClick={onCallback}
      >
        Retry
      </ErrorbuttonStyled>
    </ErrorScreenStyled>
  );
};

export default NoConnectionScreen;
