import React, { useContext } from "react";
import styled from "styled-components";
import { getEnv, getEnvLebel } from "../../utils/helpers/text";
import { BackendInfoContext } from "../backend-info-context/context";

const HeaderWrapper = styled.div`
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  box-sizing: border-box;
  width: 100%;
  z-index: 1500;
  position: fixed;
  background: ${(props) => {
    switch (props.environment) {
      case "test":
        return "#0066CC";
      case "stg":
        return "#CC0033";
      case "hotfix":
        return "#8B0E8E";
      case "dev":
        return "#00875A";
      default:
        return "#ffffff";
    }
  }};
`;

const LabelStyle = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

const HeaderSection = () => {
  const currentEnv = getEnv();
  const { rightLabel } = useContext(BackendInfoContext);
  const leftLable = getEnvLebel(currentEnv);
  return (
    <>
      {currentEnv &&
        (currentEnv === "hotfix" ||
          currentEnv === "test" ||
          currentEnv === "stg" ||
          currentEnv === "dev") && (
          <HeaderWrapper environment={currentEnv}>
            <LabelStyle> {leftLable} </LabelStyle>
            <LabelStyle> {rightLabel}</LabelStyle>
          </HeaderWrapper>
        )}
    </>
  );
};

export default HeaderSection;
