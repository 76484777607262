import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { Auth } from "aws-amplify";
import {
  changeFormFilters as changeFormFiltersAction,
  setSelectedInstruments as setSelectedInstrumentsAction,
  loadInstrumentsSuccess as loadInstrumentsSuccessAction,
  selectInstrumentsToMonitor as selectInstrumentsToMonitorAction
} from "../devices/instrumentsFilter/redux/actions";
import {
  loadMonitoringUserInfo as loadMonitoringUserInfoAction,
  setInstrumentsView as setInstrumentsViewAction,
  setSiteName as setSiteNameAction
} from "../devices/monitoringDevices/redux/actions";
import {
  GET_LOGGED_USER_DETAILS,
  GET_DIGITAL_LAB_DEVICE_USER,
  DEVICES_LAB_FILTER
} from "../../gql/devices/queries";
import {
  CREATE_DIGITAL_LAB_DEVICE_USER,
  UPDATE_DIGITAL_LAB_DEVICE_USER
} from "../../gql/devices/mutation";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SubscriptionFilter from "../../utils/SubscriptionFilter";
import { signOut } from "../../utils/signout";
import { FullScreenCentered } from "../../components/shared";
import {
  ID_TOKEN_PATH,
  DEFAULT_SITE,
  REQUIRED_APP_ACCESS_GROUP,
  SYNC_PROFILE_FIELDS
} from "../../constants";
import { isEqual, pick } from "underscore";
import { omit } from "lodash";
import { getAllData } from "../../utils/helpers/fetching";
import Notify from "../notifications/Notify";

class SiteError extends Error {
  constructor(message) {
    super(message);
    this.name = "SiteError";
  }
}

const stripPhoneNumber = (phoneNumber) =>
  phoneNumber?.replace(/[^+\d]/gi, "") ?? "";

export const getSyncFields = (
  syncFieldsValue,
  syncProfileFieldsMap = SYNC_PROFILE_FIELDS
) => {
  const syncKeys = Object.keys(syncProfileFieldsMap);
  return syncKeys.reduce(
    (acc, currKey) => ({
      ...acc,
      [currKey]: syncFieldsValue?.[syncProfileFieldsMap?.[currKey]] ?? ""
    }),
    {}
  );
};

export const shouldUpdateProfile = ({
  user,
  profileObj,
  syncKeys = Object.keys(SYNC_PROFILE_FIELDS)
}) => !isEqual(pick(user, syncKeys), profileObj);

const checkRoleAndGetUserEmail = async (
  required_group = REQUIRED_APP_ACCESS_GROUP,
  syncProfileFieldsMap = SYNC_PROFILE_FIELDS
) => {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
  const access_groups = get(
    currentAuthenticatedUser,
    "signInUserSession.accessToken.payload.cognito:groups"
  );
  if (!Array.isArray(access_groups) || !access_groups.includes(required_group))
    throw new Error("You do not have permission to access.");
  const syncFields = pick(
    get(currentAuthenticatedUser, ID_TOKEN_PATH),
    Object.values(syncProfileFieldsMap)
  );
  if (syncFields?.[SYNC_PROFILE_FIELDS.phone]) {
    syncFields[SYNC_PROFILE_FIELDS.phone] = stripPhoneNumber(
      syncFields?.[SYNC_PROFILE_FIELDS.phone]
    );
  }
  const email = get(currentAuthenticatedUser, "attributes.email");
  return { email, ...syncFields };
};

export const updateProfileFields = async ({
  client,
  email,
  setSiteName,
  user,
  ...syncFields
}) => {
  const profileObj = getSyncFields(syncFields);
  if (!user) return profileObj;
  const shouldUpdate = shouldUpdateProfile({
    user,
    profileObj: {
      ...profileObj,
      siteName: profileObj?.siteName || user?.siteName
    }
  });
  user = {
    ...user,
    siteName: profileObj?.siteName || user?.siteName
  };
  setSiteName(profileObj?.siteName || user?.siteName);
  if (!shouldUpdate) return user;
  const {
    data: { updateDigitalLabDeviceUser: updatedUser }
  } = await client.mutate({
    mutation: UPDATE_DIGITAL_LAB_DEVICE_USER,
    fetchPolicy: "no-cache",
    variables: {
      id: user?.id,
      notificationSubscriptions: (user?.notificationSubscriptions || [])?.map(
        (item) => omit(item, "__typename")
      ),
      email,
      ...profileObj,
      siteName: profileObj?.siteName || user?.siteName
    }
  });
  if (!updatedUser?.siteName)
    updatedUser.siteName = profileObj?.siteName || user?.siteName;

  return updatedUser;
};

export const getUserDetails = async ({
  client,
  email,
  setSiteName,
  ...syncFields
}) => {
  const userDeviceLab = await getLabUser({
    client,
    email,
    query_email: GET_LOGGED_USER_DETAILS
  });
  if (!syncFields?.[SYNC_PROFILE_FIELDS.siteName]) {
    throw new SiteError(
      "You do not have site location assigned. Go to the Landing Page to configure this."
    );
  }
  if (!syncFields?.[SYNC_PROFILE_FIELDS.siteName]) {
    Notify({
      type: "warning",
      icon: "caution",
      appName: "",
      text: "Your location has been changed. Please check your settings at the Landing Page."
    });
  }

  const profileFieldsOrUpdatedUser = await updateProfileFields({
    client,
    user: userDeviceLab,
    setSiteName,
    email,
    ...syncFields
  });
  const { items } = await getAllData({
    client,
    query: DEVICES_LAB_FILTER,
    drillData: true,
    fetchPolicy: "no-cache",
    variables: {
      siteName: profileFieldsOrUpdatedUser?.siteName || DEFAULT_SITE,
      filter: { status: { eq: "ACTIVE" } },
      limit: 500,
      nextToken: null
    },
    dataPath: ["data", "deviceBySiteAndLocation"]
  });
  const instruments = items?.map((device) => omit(device, "__typename"));
  if (profileFieldsOrUpdatedUser?.id) {
    return { userDeviceLab: profileFieldsOrUpdatedUser, instruments };
  }
  const user = await createDeviceLabUser({
    client,
    email,
    create_lab_user_query: CREATE_DIGITAL_LAB_DEVICE_USER,
    ...profileFieldsOrUpdatedUser
  });

  return {
    userDeviceLab: { ...user, email },
    instruments
  };
};

export const getLabUser = async ({
  client,
  email,
  query_email = GET_LOGGED_USER_DETAILS
}) => {
  const digitalLabDeviceUser = await client.query({
    query: query_email,
    fetchPolicy: "no-cache"
  });
  return get(digitalLabDeviceUser, "data.getLoggedInUserDetails.items[0]");
};

export const getLabUserDetails = async ({
  client,
  email,
  id,
  query = GET_DIGITAL_LAB_DEVICE_USER
}) => {
  const labUserDetails = await client.query({
    query,
    variables: { id, email },
    fetchPolicy: "no-cache"
  });
  return get(labUserDetails, "data.getDigitalLabDeviceUser");
};

const createDeviceLabUser = async ({
  client,
  email,
  create_lab_user_query,
  ...rest
}) => {
  const newUserDeviceLab = await client.mutate({
    mutation: create_lab_user_query,
    variables: {
      email,
      ...rest
    }
  });
  return get(newUserDeviceLab, "data.createDigitalLabDeviceUser");
};

const loadUserFilter = async ({ client, email, id }) => {
  const deviceUser = await getLabUserDetails({
    client,
    email,
    id
  });
  const {
    deviceName = "",
    deviceType = "",
    deviceLocation = ""
  } = get(deviceUser, "deviceUseState.processingStatusFilter") || {};
  const {
    processingStatusSelected = [],
    processingStatusSortKey = "location",
    instrumentsView = "cards"
  } = pick(get(deviceUser, "deviceUseState".split(".")), [
    "processingStatusSelected",
    "processingStatusSortKey",
    "instrumentsView"
  ]);
  return {
    deviceName,
    deviceType,
    deviceLocation,
    processingStatusSelected,
    processingStatusSortKey,
    instrumentsView
  };
};

const loadDataFactory = ({
  setLoading,
  setError,
  client,
  loadInstruments,
  changeFormFilters,
  setSelectedInstruments,
  selectInstrumentsToMonitor,
  setInstrumentsView,
  setSiteName,
  loadMonitoringUserInfo
}) => {
  return async () => {
    setLoading(true);
    setError(null);
    try {
      const { email, ...syncFields } = await checkRoleAndGetUserEmail();
      const { instruments, userDeviceLab } = await getUserDetails({
        client,
        email,
        setSiteName,
        ...syncFields
      });
      loadInstruments(instruments);
      const { id, notificationSubscriptions } = userDeviceLab;
      const _notificationSubscriptions = Array.isArray(
        notificationSubscriptions
      )
        ? notificationSubscriptions?.map((subs) => omit(subs, "__typename"))
        : [];
      const {
        deviceName,
        deviceType,
        deviceLocation,
        processingStatusSelected,
        instrumentsView
      } = await loadUserFilter({
        id,
        email,
        client
      });
      changeFormFilters({
        name: deviceName,
        type: deviceType,
        location: deviceLocation
      });
      setSelectedInstruments(processingStatusSelected);
      selectInstrumentsToMonitor(_notificationSubscriptions);
      setInstrumentsView(instrumentsView);
      loadMonitoringUserInfo({
        ...userDeviceLab,
        notificationSubscriptions: _notificationSubscriptions
      });
    } catch (err) {
      console.warn(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };
};

const LabDeviceUser = ({
  children,
  client,
  loadMonitoringUserInfo,
  changeFormFilters,
  setSelectedInstruments,
  setSortKey,
  loadInstruments,
  selectInstrumentsToMonitor,
  setInstrumentsView,
  setSiteName
}) => {
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(0);
  const [error, setError] = useState(null);
  useEffect(() => {
    const loadData = loadDataFactory({
      setLoading,
      setError,
      client,
      loadInstruments,
      changeFormFilters,
      setSelectedInstruments,
      selectInstrumentsToMonitor,
      setInstrumentsView,
      loadMonitoringUserInfo,
      setSiteName
    });
    loadData();
  }, [
    loadMonitoringUserInfo,
    client,
    changeFormFilters,
    setSelectedInstruments,
    loadInstruments,
    selectInstrumentsToMonitor,
    setInstrumentsView,
    setSiteName,
    refetch
  ]);
  if (loading)
    return (
      <FullScreenCentered>
        <CircularProgress size={80} />
      </FullScreenCentered>
    );
  if (error)
    return (
      <FullScreenCentered>
        <>
          <Typography variant="h6" data-testid="message-error" gutterBottom>
            {error?.message ? error?.message : "Unexpected error has occurred"}
          </Typography>
          {error?.name !== "SiteError" && (
            <Button
              variant="contained"
              onClick={() => setRefetch(refetch + 1)}
              color="secondary"
              startIcon={<ErrorIcon />}
              data-testid="try-again-button"
            >
              Try again
            </Button>
          )}
          <Button
            data-testid="signout-button"
            variant="contained"
            style={{ marginTop: ".7rem" }}
            onClick={() => signOut()}
            startIcon={<ExitToAppIcon />}
          >
            Sign out
          </Button>
        </>
      </FullScreenCentered>
    );
  return <SubscriptionFilter>{children}</SubscriptionFilter>;
};

export default compose(
  connect(null, {
    loadMonitoringUserInfo: loadMonitoringUserInfoAction,
    setInstrumentsView: setInstrumentsViewAction,
    setSiteName: setSiteNameAction,
    changeFormFilters: changeFormFiltersAction,
    setSelectedInstruments: setSelectedInstrumentsAction,
    selectInstrumentsToMonitor: selectInstrumentsToMonitorAction,
    loadInstruments: loadInstrumentsSuccessAction
  }),
  withApollo
)(LabDeviceUser);
