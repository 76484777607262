import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { HistoryTogglerContext } from "../contexts";
import { useMobile } from "../utils/hooks";
import { Dialog, Slide } from "@material-ui/core";
import { getEnv } from "../utils/helpers/text";
import { Module } from "../constants";
import { generateID } from "@digitallab/grid-common-components";


export const drawerWidth = 320;
const currentEnv = getEnv();
const dynamicHeight = currentEnv ? 42 : 0;
const dynamicHeightAppDrawer = currentEnv ? "62px" : "30px";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 1,
    whiteSpace: "nowrap",
    zIndex: 0
  },
  drawerOpen: {
    width: drawerWidth,
    maxWidth: "100%",
    bottom: 70,
    position: "fixed",
    height: `calc(100vh - ${dynamicHeightAppDrawer})`,
    top: dynamicHeight,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 0,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide id={generateID.UUID(Module.HistoryDrawer, `drawer`, "slide")} direction="up" ref={ref} {...props} />;
});
const getClassName = (historyDevice, classes) => {
  return historyDevice?.materialNumber ? classes.drawer : "";
};
const AppHistoryDrawer = ({ children }) => {
  const isMobile = useMobile();
  const classes = useStyles();
  const { historyDevice, historyClose } = useContext(HistoryTogglerContext);
  return isMobile ? (
    <Dialog
      id={generateID.UUID(Module.HistoryDrawer, `drawer`, "dialog")}
      data-testid="app-history-drawer-dialog"
      fullScreen
      keepMounted
      open={!!historyDevice?.materialNumber}
      onClose={historyClose}
      TransitionComponent={Transition}
    >
      <>
        <div id={generateID.UUID(Module.HistoryDrawer, `drawer`, "div_dialog")} className={classes.toolbar} />
        {children}
      </>
    </Dialog>
  ) : (
    <Drawer
      id={generateID.UUID(Module.HistoryDrawer, `drawer`, "div")}
      data-testid="app-history-drawer"
      className={getClassName(historyDevice, classes)}
      variant={"persistent"}
      open={!!historyDevice?.materialNumber}
      onClose={historyClose}
      ModalProps={{
        keepMounted: true
      }}
      anchor="left"
      classes={{
        paper: clsx({
          [classes.drawerOpen]: historyDevice?.materialNumber
        })
      }}
    >
      <div className={classes.toolbar} />
      {children}
    </Drawer>
  );
};

export default AppHistoryDrawer;
