import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { setSelectedInstruments } from "./redux/actions";
import { filterText, filterTextAny } from "../../../selectors";
import { groupByKey } from "../../../utils/helpers/array";
import DeviceCheckbox from "./DeviceCheckbox";
import List from "@material-ui/core/List";
import DeviceCheckboxGroup from "./DeviceCheckboxGroup";
import { AutoScroll } from "../../../components/shared";
import { makeStyles } from "@material-ui/core/styles";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";

export const useStyles = makeStyles(() => ({
  list: {
    paddingTop: 0
  }
}));

const DevicesList = ({ filtered, sortKey }) => {
  const classes = useStyles();
  const groupedByLocation = groupByKey(sortKey, filtered);

  if (Array.isArray(filtered) && !filtered.length)
    return <p id={generateID.UUID(Module.FilterName, `filter_list`, "no_data")} data-testid="no-devices">No devices found</p>;

  return (
    <AutoScroll spaceTop={200} id={generateID.UUID(Module.FilterName, `filter_list`, "auto_scroll")}>
      <List
        id={generateID.UUID(Module.FilterName, `filter_list`, "li")}
        data-testid="devices-list"
        classes={{
          root: classes.list
        }}
      >
        {Object.keys(groupedByLocation).map((key,index) => (
          <div key={key} id={generateID.UUID(Module.FilterName, `filter_list_${index}`, "div")} data-testid="device-group">
            <DeviceCheckboxGroup
             id={generateID.UUID(Module.FilterName, `filter_list_${index}`, "checkout")}
              key={key}
              name={key}
              deviceIds={groupedByLocation[key].map((d) => ({
                inventoryId: d?.inventoryId
              }))}
            />
            {groupedByLocation[key].map((device, index) => (
              <DeviceCheckbox
                id={generateID.UUID(Module.FilterName, `filter_list_${index}`, "checkout")}
                key={`${key}-${device.inventoryId}`}
                device={device}
              />
            ))}
          </div>
        ))}
      </List>
    </AutoScroll>
  );
};

const getFilteredInstruments = (instruments, filters) => {
  const { location, type, name } = filters;
  return compose(
    filterText("equipmentModel", type ?? ""),
    filterTextAny([
      { key: "serialNumber", value: name ?? "" },
      { key: "equipmentNickName", value: name ?? "" },
      { key: "equipmentId", value: name ?? "" }
    ]),
    filterText("location", location ?? "")
  )(instruments);
};

const mapStateToProps = (state) => ({
  filtered: getFilteredInstruments(
    state.devices.instruments,
    state.devices.controls
  ),
  sortKey: state.devices.sortKey
});

export default connect(mapStateToProps, {
  selectInstruments: setSelectedInstruments
})(DevicesList);
