import { getAllData } from "../../../../utils/helpers/fetching";
import { pick } from "lodash";
import {
  LIST_SELECTED_DIGITAL_LAB_DEVICE_STATUS,
  DEVICES_LAB_FILTER
} from "../../../../gql/devices/queries";
import {
  getDeviceIdentity,
  keyIds
} from "../../instrumentsFilter/redux/reducer";
import { find, filter } from "lodash";
import { DEFAULT_DEVICE_STATUS } from "../../../../constants";

const divideIntoChunks = (arr, count) => {
  const res = [];
  let index = 0;
  while (index < arr.length) {
    res.push(arr.slice(index, index + count));
    index += count;
  }
  return res;
};

export const fetchChunkData = async ({
  client,
  devicesToMonitor,
  query = LIST_SELECTED_DIGITAL_LAB_DEVICE_STATUS,
  variables = {},
  path = "listDigitalLabDeviceStatuss"
}) => {
  const devicesWithSNMA = filter(devicesToMonitor, (device) => {
    return (
      device?.serialNumber &&
      device?.serialNumber !== "null" &&
      device?.materialNumber &&
      device?.materialNumber !== "null"
    );
  });

  const devicesWithoutSNorMA = filter(devicesToMonitor, (device) => {
    return (
      !device.serialNumber ||
      device.serialNumber === "null" ||
      !device.materialNumber ||
      device.materialNumber === "null"
    );
  });

  const SNMNDevices = devicesWithSNMA?.map((device) => {
    return {
      serialNumber: { eq: device?.serialNumber },
      materialNumber: { eq: device?.materialNumber }
    };
  });

  const queryVariables = {
    limit: 1000,
    nextToken: null,
    ...variables
  };
  let results = {};

  if (SNMNDevices?.length === 0) {
    results = await getAllData({
      client,
      query,
      drillData: true,
      fetchPolicy: "no-cache",
      variables: queryVariables,
      dataPath: ["data", path]
    });
  } else {
    const chuckDevices = divideIntoChunks(SNMNDevices, 20);
    let promises = [];

    chuckDevices.forEach((values) => {
      const qVariable = {
        ...queryVariables,
        filter: { or: values }
      };
      const response = getAllData({
        client,
        query,
        drillData: true,
        fetchPolicy: "no-cache",
        variables: qVariable,
        dataPath: ["data", path]
      });

      promises.push(response);
    });
    results = {
      items: [],
      error: {},
      nextToken: null
    };
    await Promise.all(promises).then((values) => {
      values.forEach((response) => {
        results = {
          items: [...results?.items, ...response?.items],
          error: response?.error || null,
          nextToken: response?.nextToken || null
        };
      });
    });
  }

  let updatedResultesWithInventoryId = devicesWithSNMA?.map((equipment) => {
    const itemObj = find(results?.items, {
      materialNumber: equipment?.materialNumber,
      serialNumber: equipment?.serialNumber
    });
    return { ...equipment, ...itemObj };
  });

  let resultWithDefault = [];
  devicesWithoutSNorMA.forEach((device) => {
    resultWithDefault.push({
      ...DEFAULT_DEVICE_STATUS,
      serialNumber: device?.serialNumber,
      materialNumber: device?.materialNumber,
      inventoryId: device?.inventoryId
    });
  });

  const updatedResult = [
    {
      items: [...(updatedResultesWithInventoryId || []), ...resultWithDefault],
      nextToken: results?.nextToken || null,
      error: results?.error || null
    }
  ];

  const _error = updatedResult.find((r) => r?.error);
  const _items = updatedResult?.reduce((acc, r) => [...acc, ...r?.items], []);
  return {
    error: _error,
    items: _items
  };
};

export const mapDeviceStatus = (item) =>
  pick(item, [...keyIds, "detailedStatus"]);
export const mapDevice = (item) =>
  pick(item, [...keyIds, "equipmentNickName", "location"]);

export const mergeResults = ({
  devices = [],
  statuses = [],
  getIdentity = getDeviceIdentity
}) => {
  const toMap = (arr) =>
    arr?.reduce((acc, cur) => {
      const id = getIdentity(cur);
      return id in acc ? acc : { ...acc, [id]: cur };
    }, {});
  const statusesMap = toMap(statuses);
  const devicesMap = toMap(devices);
  return Object.keys(statusesMap).map((statusDevice) => ({
    ...statusesMap[statusDevice],
    ...devicesMap[statusDevice]
  }));
};

export const fetchDevices = async ({ client, selectedDevices, siteName }) => {
  const { errorStatus, items: devicesInfos } = await fetchChunkData({
    client,
    devicesToMonitor: selectedDevices,
    path: "listDigitalLabDeviceStatuss",
    query: LIST_SELECTED_DIGITAL_LAB_DEVICE_STATUS
  });

  const { errorDevice, items: devices } = await fetchChunkData({
    client,
    devicesToMonitor: selectedDevices,
    path: "deviceBySiteAndLocation",
    query: DEVICES_LAB_FILTER,
    variables: {
      siteName,
      filter: { status: { eq: "ACTIVE" } }
    }
  });

  const mergedDevices = devices.map((device) => {
    const detailedStatus = find(devicesInfos, {
      materialNumber: device.materialNumber,
      serialNumber: device.serialNumber
    });
    return {
      ...device,
      detailedStatus: detailedStatus?.detailedStatus || ""
    };
  });

  return {
    items: mergedDevices,
    error: errorStatus || errorDevice
  };
};
