import HistoryCardOfLog from "./HistoryCardOfLog";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { CircularProgress } from "@material-ui/core";
import { BY_STATUS_UPDATE_TIME } from "../../../gql/devices/queries";
import { CenterStyled } from "../monitoringDevices/devicesViews/DeviceCards";
import { Error } from "../../../components/shared";
import { getAllData } from "../../../utils/helpers/fetching";
import { useEffect, useState } from "react";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";

const HistoryList = ({ client, serialNumber, materialNumber }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const loadHistory = async () => {
      const { items, errors } = await getAllData({
        client,
        query: BY_STATUS_UPDATE_TIME,
        drillData: true,
        fetchPolicy: "no-cache",
        variables: {
          limit: 1000,
          nextToken: null,
          serialNumber: serialNumber || "null",
          materialNumber: materialNumber || "null"
        },
        dataPath: ["data", "byStatusUpdateTime"]
      });

      setData(items);
      if (errors) setError(error);
      setLoading(false);
    };

    loadHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumber, materialNumber]);

  if (loading)
    return (
      <CenterStyled data-testid="loader" id={generateID.UUID(Module.HistoryInstrument, `history`, "center_style")}>
        <CircularProgress size={60} id={generateID.UUID(Module.HistoryInstrument, `history`, "circular")}/>
      </CenterStyled>
    );

  if (error) return <Error error={error} id={generateID.UUID(Module.HistoryInstrument, `history`, "error")}/>;

  if (data.length === 0)
    return (
      <>
        <h6 id={generateID.UUID(Module.HistoryInstrument, `history`, "h6_no_recent")}>No recent run status</h6>
      </>
    );

  return data?.map((item,  index) => {
    return (
      <HistoryCardOfLog 
      id={generateID.UUID(Module.HistoryInstrument, `history_${index}`, "card_log")}
      key={item.id} historyData={item}></HistoryCardOfLog>
    );
  });
};

export default compose(connect(null, {}), withApollo)(HistoryList);
