import React from "react";
import Typography from "@material-ui/core/Typography";
import { BasicHistoryHeaderLayout } from "../monitoringDevices/devicesViews/shared/statues";
import { mainTheme } from "../../../utils/theme/main";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import styled from "styled-components";
import { Tooltip, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";

const useStyles = makeStyles(() => ({
  typography: {
    textOverflow: "ellipsis",
    maxWidth: "100px",
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
}));

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    padding: 15,
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: theme.typography.pxToRem(16),
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.301961)"
  }
}))(Tooltip);

const DeviceID = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 5px;
`;

const HistoryContentHeader = ({ historyDevice = {} }) => {
  const classes = useStyles();
  return (
    <>
    <BasicHistoryHeaderLayout
      data-testid={`deviceCardHeader-${historyDevice?.inventoryId}`}
      id={generateID.UUID(Module.HistoryInstrument, `historytop`, "layout")}
    >
      <div>
        <Typography
          data-testid="serialNumberLabel"
          variant="caption"
          display="block"
          style={{ color: mainTheme.indicator.unknown }}
          id={generateID.UUID(Module.HistoryInstrument, `historytop`, "typography")}
        >
          {DATA_MODEL_TABLE.serialNumber.value}
        </Typography>
        <CustomTooltip
          title={<ValCheck item={historyDevice?.serialNumber}  id={generateID.UUID(Module.HistoryInstrument, `historytop`, "valcheck")}/>}
          placement="bottom-end"
          id={generateID.UUID(Module.HistoryInstrument, `historytop`, "tooltip")}
        >
          <Typography
            variant="subtitle2"
            display="block"
            data-testid="serialNumber"
            className={classes.typography}
            id={generateID.UUID(Module.HistoryInstrument, `historytop`, "typography_1")}
          >
            <ValCheck item={historyDevice?.serialNumber} id={generateID.UUID(Module.HistoryInstrument, `historytop`, "valcheck_1")}/>
          </Typography>
        </CustomTooltip>
      </div>
      <div>
        <Typography
          data-testid="materialNumberLabel"
          variant="caption"
          display="block"
          style={{ color: mainTheme.indicator.unknown }}
          id={generateID.UUID(Module.HistoryInstrument, `historytop`, "typography_2")}
        >
          {DATA_MODEL_TABLE.materialNumber.value}
        </Typography>
        <CustomTooltip
          id={generateID.UUID(Module.HistoryInstrument, `historytop`, "tooltip_1")}
          title={<ValCheck item={historyDevice?.materialNumber} id={generateID.UUID(Module.HistoryInstrument, `historytop`, "valcheck_2")} />}
          placement="bottom-end"
        >
          <Typography
            variant="subtitle2"
            display="block"
            data-testid="materialNumber"
            className={classes.typography}
            id={generateID.UUID(Module.HistoryInstrument, `historytop`, "typography_3")}
          >
            <ValCheck item={historyDevice?.materialNumber} id={generateID.UUID(Module.HistoryInstrument, `historytop`, "valcheck_3")}/>
          </Typography>   
        </CustomTooltip>     
      </div>
      <div>
        <Typography
          data-testid="equipmentIdLabel"
          variant="caption"
          display="block"
          style={{ color: mainTheme.indicator.unknown }}
          id={generateID.UUID(Module.HistoryInstrument, `historytop`, "typography_4")}
        >
          {DATA_MODEL_TABLE.equipmentId.value}
        </Typography>
        <CustomTooltip
          id={generateID.UUID(Module.HistoryInstrument, `historytop`, "tooltip_2")}
          title={<ValCheck item={historyDevice?.equipmentId} id={generateID.UUID(Module.HistoryInstrument, `historytop`, "valcheck_4")}/>}
          placement="bottom-end"
        >
          <Typography
            variant="subtitle2"
            display="block"
            data-testid="equipmentId"
            className={classes.typography}
            id={generateID.UUID(Module.HistoryInstrument, `historytop`, "typography_5")}
          >
            <ValCheck item={historyDevice?.equipmentId} id={generateID.UUID(Module.HistoryInstrument, `historytop`, "valcheck_5")}/>
          </Typography>
        </CustomTooltip>
      </div>
    </BasicHistoryHeaderLayout>
    <DeviceID 
    id={generateID.UUID(Module.HistoryInstrument, `historytop`, "deviceid")}
    data-testid="history-header-content-device-id">
      {historyDevice?.shortIdentifier}
    </DeviceID>
    </>
  );
};

export const ValCheck = ({ item }) => {
  if (
    item !== null &&
    item !== "" &&
    typeof item === "string"
  ) {
    return item === "null" ? "-" : item;
  }
  else {
    return "-";
  }
};

export default HistoryContentHeader;
