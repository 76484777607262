import React from "react";
import Typography from "@material-ui/core/Typography";
import { DeviceCardBookingLayout } from "../../shared/statues";
import BookingLink from "./BookingLink";
import { Module } from "../../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";
const DeviceCardBookingLink = ({ data }) => {
  return (
    <DeviceCardBookingLayout
      data-testid={`deviceCardBookingLink-${data?.inventoryId}`}
      id={generateID.UUID(Module.MonitoringName, `devicecard_booking_${data?.inventoryId}`, "layout")}
    >
      <div 
      id={generateID.UUID(Module.MonitoringName, `devicecard_booking_${data?.inventoryId}`, "div")}
      data-testid={`deviceCardBookingLink-${data?.inventoryId}`}>
        <Typography
          variant="h6"
          display="block"
          data-testid="bookingLink"
          id={generateID.UUID(Module.MonitoringName, `devicecard_booking_${data?.inventoryId}`, "typography")}
        >
          <BookingLink
            inventoryId={data?.inventoryId}
            type="link"
            id={generateID.UUID(Module.MonitoringName, `devicecard_booking_${data?.inventoryId}`, "link")}
          />
        </Typography>
      </div>
    </DeviceCardBookingLayout>
  );
};

export default DeviceCardBookingLink;
