import * as types from "./actionTypes";

export const loadMonitoringUserInfo = (userInfo) => ({
  type: types.LOAD_MONITORING_DEVICES_USER_INFO,
  payload: userInfo
});

export const setMonitoringDevicesNotification = (notifications) => ({
  type: types.SET_MONITORING_DEVICES_NOTIFICATION,
  payload: notifications
});

export const setVisibilityFilter = (payload) => ({
  type: types.SET_VISIBILITY_DEVICES,
  payload
});

export const setInstrumentsView = (payload) => ({
  type: types.SET_INSTRUMENTS_VIEW,
  payload
});

export const setSiteName = (payload) => ({
  type: types.SET_SITE_NAME,
  payload
});
