import React, { useEffect, useState } from "react";
import withWidth from "@material-ui/core/withWidth";
import "react-toastify/dist/ReactToastify.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import MainPage from "./views/MainPage";
import TogglerContextWrapperSidebar from "./components/shared/TogglerContextWrapper";
import LabDeviceUser from "./features/labDeviceUser/LabDeviceUser";
import "./App.scss";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { federated } from "./aws-exports";
import MaterialTheme from "./components/MaterialTheme";
import HistoryTogglerContextWrapper from "./components/shared/HistoryTogglerContextWrapper";
import { StyledToastContainer } from "./features/notifications/Notify";
import StyledThemeProvider from "./components/StyledTheme";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { LIST_BACKEND_HEALTH_CHECKS } from "../src/gql/devices/queries";
import { API } from "aws-amplify";
// import { awsRum } from "./index";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("recordingError: " + error);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <button
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Clear Error
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

const App = (props) => {
  const [enableVal, setEnableVal] = useState(false);
  const getBackendAuthChecks = async () => {
    try {
      const backendBuildInfo = await API.graphql({
        query: LIST_BACKEND_HEALTH_CHECKS
      });
      const items = backendBuildInfo?.data?.listBackendHealthChecks?.items;
      const trackDisable = items.map((item) => item?.enableAnalyticsTracking);
      setEnableVal(trackDisable[0]);
    } catch (error) {
      console.log("Matomo api error..", error);
    }
  };
  useEffect(() => {
    getBackendAuthChecks();
  }, []);

  // Matomo code added for analytics
  //urlBase: optional, default value: `${urlBase}matomo.php`
  // siteId : optional, default value: `${urlBase}matomo.js`
  // disabled :optional, false by default. Makes all tracking calls no-ops if set to true.
  // linkTracking :optional, default value: true
  // active: optional, default value: true
  // seconds: optional, default value: `15
  const instance = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_BASE_URL,
    siteId: process.env.REACT_APP_SITE_ID,
    trackerUrl: process.env.REACT_APP_MATOMO_BASE_URL + "matomo.php",
    srcUrl: process.env.REACT_APP_MATOMO_BASE_URL + "matomo.js",
    disabled: enableVal,
    linkTracking: true,
    trackPageView: true,
    heartBeat: {
      active: true,
      seconds: 10
    }
  });
  const { width } = props;
  const isMobile = ["xs", "sm"].includes(width);
  return (
    <ErrorBoundary>
      <MatomoProvider value={instance}>
        <MaterialTheme>
          <StyledThemeProvider>
            <CssBaseline />
            <LabDeviceUser>
              <TogglerContextWrapperSidebar initialState={!isMobile}>
                <HistoryTogglerContextWrapper>
                  <MainPage />
                  <StyledToastContainer />
                </HistoryTogglerContextWrapper>
              </TogglerContextWrapperSidebar>
            </LabDeviceUser>
          </StyledThemeProvider>
        </MaterialTheme>
      </MatomoProvider>
    </ErrorBoundary>
  );
};

export default withWidth()(App);

export const AuthenticatedApp = withAuthenticator(App, {
  federated: federated
});
