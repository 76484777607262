import { DAYS, MONTHS } from "../../../constants";

export const parseText = (text) =>
  typeof text === "string" ? `${text}`.trim().toLowerCase() : "";
export const emailToName = (email) => email?.split("@")[0].replace(/\./g, " ");

export const changeDateFormat = (inputDate, format = null) => {
  try {
    if (typeof inputDate === "string") {
      if (format === null) {
        const dateObj = new Date(inputDate).toUTCString();
        const splitDateFormat = dateObj?.split(" ");
        const currentDate =
          splitDateFormat[1] +
          "-" +
          splitDateFormat[2] +
          "-" +
          splitDateFormat[3];
        const FormattedDateObj = new Date(currentDate);
        return FormattedDateObj;
      }
      if (format === "dd-MMM-yyyy" || format === "DD-MMM-YYYY") {
        const dateObj = new Date(inputDate).toUTCString();
        const splitDateFormat = dateObj?.split(" ");
        const currentDate =
          splitDateFormat[1] +
          "-" +
          splitDateFormat[2] +
          "-" +
          splitDateFormat[3];
        return currentDate;
      }
      if (format === "mm/dd/yyyy h:mm:ss") {
        const dateObjString = new Date(inputDate).toUTCString();
        let dateArray = inputDate.split("T");
        dateArray = dateArray[0].split("-");
        const splitDateFormat = dateObjString?.split(" ");
        const currentDate =
          parseInt(dateArray[1]) +
          "/" +
          dateArray[2] +
          "/" +
          splitDateFormat[3];
        const timeVal = inputDate.split("T");
        const time = timeVal[1].split(":");
        let hours = parseInt(time[0]);
        const seconds = time[2].slice(0, 2);
        let mid = " AM";
        if (hours === 12) {
          mid = " PM";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " PM";
          if (hours === 0) {
            mid = " AM";
          }
        }
        if (hours < 10) {
          hours = "0" + hours;
        }
        return currentDate + ", " + hours + ":" + time[1] + ":" + seconds + mid;
      }

      if (format === "h:mm") {
        let timeVal = inputDate.split("T");
        let time = timeVal[1].split(":");
        let hours = parseInt(time[0]);

        let mid = " am";
        if (hours === 12) {
          mid = " pm";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " pm";
          if (hours === 0) {
            mid = " am";
          }
        }
        return hours + ":" + time[1] + mid;
      }
      if (format === "ISOToDate") {
        const timeVal = inputDate.split("T");
        const date = timeVal[0].split("-");
        const month = parseInt(date[1]) - 1;
        const time = timeVal[1].split(":");
        const hours = parseInt(time[0]);
        const dateObj = new Date(date[2] + "-" + MONTHS[month] + "-" + date[0]);
        dateObj.setTime(dateObj.getTime() + hours * 60 * 60 * 1000);
        return dateObj;
      }
    } else {
      if (format === "EEEE, dd-MMM-yyyy") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const weekDay = inputDate.getDay();
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        return (
          DAYS[weekDay] +
          ", " +
          day +
          "-" +
          MONTHS[monthVal] +
          "-" +
          year +
          " ."
        );
      }
      if (format === "DD-MMM-YYYY" || format === "dd-MMM-yyyy") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        return day + "-" + MONTHS[monthVal] + "-" + year;
      }
      if (format === "yyyy-MM-dd") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        var month = splitDateFormat[1];
        let year = splitDateFormat[2];

        return year + "-" + month + "-" + day;
      }
      if (format === "ISO") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        let isoVal = inputDate.toTimeString();
        isoVal = isoVal.split(" ");
        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let month = splitDateFormat[1];
        let year = splitDateFormat[2];

        const isoString =
          year + "-" + month + "-" + day + "T" + isoVal[0] + ".000Z";
        return isoString;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const envs = ['dev', 'test', 'staging', 'stage', 'hotfix'];

export const getEnv = () => {
  if (envs?.includes(process.env?.REACT_APP_ENV)) {
    if (
      process.env?.REACT_APP_ENV === "staging" ||
      process.env?.REACT_APP_ENV === "stage"
    ) {
      return "stg";
    } else {
      return process.env?.REACT_APP_ENV;
    }
  } else {
    return null;
  }
};

export const getEnvLebel = (currentEnv) => {
  switch (currentEnv) {
    case "hotfix":
      return "Test Hotfix Environment";
    case "test":
      return "Test Environment";
    case "stg":
      return "Staging Environment";
    case "dev":
      return "Development Environment";
    default:
      return "";
  }
};

export const checkEnvReturnValue = (currentEnv, returnVal) => {
  if (
    currentEnv === "hotfix" ||
    currentEnv === "test" ||
    currentEnv === "stg" ||
    currentEnv === "dev"
  ) {
    return returnVal[0];
  } else {
    return returnVal[1];
  }
};

export const getHashCommitValue = (url) => {
  const fragments = url.split("/");
  return fragments[fragments.length - 1].toUpperCase();
};

export const scrollAvailability = () => {
  const currLocation = window.location.href.split("/");
  const scrollAvailability =
    currLocation[currLocation.length - 1] === "info" ? "auto" : "hidden";
  return scrollAvailability;
};
