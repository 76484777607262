import React from "react";
import { connect } from "react-redux";
import { Mutation } from "react-apollo";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import NotificationsOffOutlinedIcon from "@material-ui/icons/NotificationsOffOutlined";
import { setMonitoringDevicesNotification as setMonitoringDevicesNotificationAction } from "../../redux/actions";
import { UPDATE_DIGITAL_LAB_DEVICE_USER } from "../../../../../gql/devices/mutation";
import { updateDigitalLabDeviceUserNotification } from "../shared/mutations";
import { findSelectedItem } from "../../../../../utils/helpers/array";
import { getNotificationSubscriptionsSelector } from "../../../../../selectors/devices";

const DeviceItemNotification = ({
  notificationSubscriptions,
  inventoryId,
  id,
  email,
  setMonitoringDevicesNotification
}) => {
  const isOn =
    Array.isArray(notificationSubscriptions) &&
    findSelectedItem(notificationSubscriptions)({
      inventoryId
    });
  return (
    <Mutation mutation={UPDATE_DIGITAL_LAB_DEVICE_USER}>
      {(mutation) => (
        <Tooltip
          title={`Turn ${isOn ? "off" : "on"} notification`}
          placement="left"
          arrow
        >
          <IconButton
            data-testid="deviceItemNotification-button"
            onClick={() =>
              updateDigitalLabDeviceUserNotification({
                mutation,
                updateAction: setMonitoringDevicesNotification,
                payload: {
                  id,
                  email,
                  notificationSubscriptions,
                  isOn,
                  ids: [{ inventoryId }]
                }
              })
            }
          >
            {isOn ? (
              <NotificationsActiveOutlinedIcon
                data-testid="deviceItemNotification-active-icon"
                color="primary"
              />
            ) : (
              <NotificationsOffOutlinedIcon data-testid="deviceItemNotification-off-icon" />
            )}
          </IconButton>
        </Tooltip>
      )}
    </Mutation>
  );
};

const mapStateToProps = (state) => ({
  id: state.userMonitorDevices.id,
  email: state.userMonitorDevices.email,
  notificationSubscriptions: getNotificationSubscriptionsSelector(state)
});

export default connect(mapStateToProps, {
  setMonitoringDevicesNotification: setMonitoringDevicesNotificationAction
})(DeviceItemNotification);
