import React from "react";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/PersonOutline";
import EndTimeIcon from "../../../../../../icons/EndTime";
import StartTimeIcon from "../../../../../../icons/StartTime";
import IDIcon from "../../../../../../icons/ID";
import { iconStyle, StatusStyled } from "../../shared/statues";
import { parseText } from "../../../../../../utils/helpers/text";

const getIcon = (type) => {
  switch (parseText(type)) {
    case "operator":
      return (
        <PersonIcon
          data-testid="operator-icon"
          className="gray"
          style={iconStyle}
        />
      );
    case "runstarttime":
      return (
        <StartTimeIcon
          data-testid="runstarttime-icon"
          className="gray"
          style={iconStyle}
        />
      );
    case "runendtime":
      return (
        <EndTimeIcon
          data-testid="runendtime-icon"
          className="gray"
          style={iconStyle}
        />
      );
    case "runid":
      return (
        <IDIcon data-testid="runid-icon" className="gray" style={iconStyle} />
      );
    default:
      return (
        <PersonIcon
          data-testid="default-icon"
          className="gray"
          style={iconStyle}
        />
      );
  }
};

const DeviceCardIconLabel = ({ icon = "", label = "", subtext = "" }) => {
  return (
    <StatusStyled data-testid="deviceCardIconLabel">
      {getIcon(icon)}
      <div className="iconlabel">
        <Typography
          variant="caption"
          className="maintext"
          display="block"
          data-testid="deviceCardIconText"
        >
          {label ? label : "-"}
        </Typography>
        {subtext && (
          <Typography
            variant="caption"
            className="subtext"
            display="block"
            data-testid="deviceCardIconSubText"
          >
            {subtext}
          </Typography>
        )}
      </div>
    </StatusStyled>
  );
};

export default DeviceCardIconLabel;
