import React from "react";
import ReactDOM from "react-dom";
import { AppNormal, AppTVMode } from "./features/appMode/AppMode";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import NotFound from "./components/NotFound";

import { getEnv } from "./utils/helpers/text";
import { AwsRum } from "aws-rum-web";

const currentEnv = getEnv();

/**
 * Code for AWS cloud watch RUM
 */
export let awsRum = null; //very important otherwise variable is local to try/catch block and will be garbage collected, and possible even removed during compilation (production optimized build)
try {
  let app_telemetries =
    currentEnv === "dev" || currentEnv === "test"
      ? ["performance", "errors", "http"]
      : ["errors"];
  // help for config: https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
  awsRum = new AwsRum(
    process.env.REACT_APP_AWS_RUM_APPLICATION_ID,
    process.env.REACT_APP_AWS_RUM_APPLICATION_VERSION,
    process.env.REACT_APP_AWS_RUM_APPLICATION_REGION,
    {
      sessionSampleRate: 1, //record 100% of sessions
      sessionEventLimit: 0, //no limit on number of events per session
      guestRoleArn: process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
      identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
      endpoint: process.env.REACT_APP_AWS_RUM_ENDPOINT,
      telemetries: app_telemetries,
      // in development, record all telemetries
      // telemetries: ["errors"], // for production/staging, only errors
      allowCookies: true,
      enableXRay: false
    }
  );
  // console.log(awsRum);
  //https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM-custom-events.html
  //Caution if you send empty data, you get a strange error:  1 validation error detected: Value null at 'rumEvents.3.member.details' failed to satisfy constraint: Member must not be null
  // Note: custom events must be enabled in the cloudwatch console, otherwise they are simply ignore.
  awsRum.recordEvent("my_custom_event", { value_1: "App Loaded" }); //record custom event (remove later)
} catch (error) {
  console.log(error);
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
// Code for AWS cloud watch RUM ENDS
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path="/tv/:query">
          <AppTVMode />
        </Route>
        <Route exact path={["/", "/info", "/equipmentList/:InventoryId"]}>
          <AppNormal />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
