import React, { useContext, useEffect } from "react";
import DevicesLoader from "./devicesViews/DevicesLoader";
import MonitoringDevicesMenuBar from "./monitoringDevicesMenuBar/MonitoringDevicesMenuBar";
import { drawerWidth } from "../../../views/AppDrawer";
import { drawerWidth as historyDrawerWidth } from "../../../views/AppHistoryDrawer";
import { HistoryTogglerContext, TogglerContext } from "../../../contexts";
import styled, { css } from "styled-components";
import { useMobile } from "../../../utils/hooks";
import DeviceStatusesWrapper from "./devicesViews/DeviceStatusesWrapper";
import DevicesSubscription from "../../devices/monitoringDevices/devicesViews/DevicesSubscription";
import { getEnv } from "../../../utils/helpers/text";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";
import { changeFormFilters as changeFormFiltersAction } from "../instrumentsFilter/redux/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const currentEnv = getEnv() ? "42px" : 0;
const dynamicHeightMainContainer = currentEnv ? "136px" : "91px";

const openStyles = css`
  margin-right: ${drawerWidth}px;
`;
const notOpenStyles = css`
  margin-right: 0px;
`;
const leftOpenStyles = css`
  margin-left: ${historyDrawerWidth}px;
`;
const leftNotOpenStyles = css`
  margin-left: 0px;
`;
const mobileStyles = css`
  padding: 32px 16px 0 16px;
`;

export const ContentWithDrawerStyled = styled.div`
  height: calc(100vh - ${dynamicHeightMainContainer});
  min-width: 300px;
  margin: 0;
  padding: 0 2rem;
  margin-top: ${currentEnv};
  overflow: auto;
  box-sizing: border-box;

  ${(props) => (props.isOpen ? openStyles : notOpenStyles)}
  ${(props) => (props.isLeftOpen ? leftOpenStyles : leftNotOpenStyles)}
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  ${(props) => props.isMobile && mobileStyles}
`;

const MainMonitoringView = ({ changeFormFilters }) => {
  const isMobile = useMobile();
  const { value: isOpen } = useContext(TogglerContext);
  const { historyDevice } = useContext(HistoryTogglerContext);
  const { InventoryId } = useParams();

  useEffect(() => {
    if (InventoryId) {
      changeFormFilters({
        name: "",
        type: "",
        location: ""
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ContentWithDrawerStyled
      isLeftOpen={historyDevice && !isMobile}
      isOpen={isOpen && !isMobile}
      isMobile={isMobile}
      data-testid="main-monitoring-view"
      id={generateID.UUID(Module.MonitoringName, `view`, "main_style")}
    >
      <MonitoringDevicesMenuBar />
      <DeviceStatusesWrapper>
        <DevicesLoader />
        <DevicesSubscription />
      </DeviceStatusesWrapper>
    </ContentWithDrawerStyled>
  );
};

export default compose(
  connect(null, { changeFormFilters: changeFormFiltersAction })
)(MainMonitoringView);
