import { Link, Tooltip } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Module } from "../../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const BookingLink = ({ inventoryId, type = "button" }) => {
  if (type === "button") {
    return (
      <Tooltip
        id={generateID.UUID(
          Module.MonitoringName,
          `devicecard_bookinglink`,
          "link_tooltip"
        )}
        title="Booking"
        placement="left"
        arrow
      >
        <Link
          type="button"
          href={`${process.env.REACT_APP_BOOKINGS_INSTRUMENTS_URL}equipmentList/${inventoryId}`}
          target="_blank"
          rel="noreferrer"
          data-testid="booking-button"
          style={{
            textDecoration: "none",
            color: "rgba(0, 0, 0, 0.54)",
            marginTop: 6
          }}
          id={generateID.UUID(
            Module.MonitoringName,
            `devicecard_bookinglink`,
            "link_button"
          )}
        >
          <OpenInNewIcon
            id={generateID.UUID(
              Module.MonitoringName,
              `devicecard_bookinglink`,
              "newicon_open"
            )}
          />
        </Link>
      </Tooltip>
    );
  }
  return (
    <Link
      type="button"
      href={`${process.env.REACT_APP_BOOKINGS_INSTRUMENTS_URL}equipmentList/${inventoryId}`}
      target="_blank"
      rel="noreferrer"
      data-testid="booking-link"
      style={{
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        marginTop: "8px"
      }}
      id={generateID.UUID(
        Module.MonitoringName,
        `devicecard_bookinglink`,
        "li_link"
      )}
    >
      <span
        style={{ marginRight: "3px" }}
        id={generateID.UUID(
          Module.MonitoringName,
          `devicecard_bookinglink`,
          "span_more"
        )}
      >
        More details
      </span>
      <OpenInNewIcon
        id={generateID.UUID(
          Module.MonitoringName,
          `devicecard_bookinglink`,
          "li_link_openin"
        )}
      />
    </Link>
  );
};

export default BookingLink;
