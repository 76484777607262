import { makeStyles } from "@material-ui/core/styles";
import { getEnv } from "../../../../utils/helpers/text";
import { getContainerHeightPx } from "../../../../utils/helpers/fetching";
const currentEnv = getEnv();

const currentHeight = getContainerHeightPx(currentEnv);
export const useControlStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
    marginBottom: theme.spacing(2.5),
    flexShrink: 1
  },
  paper: {
    height: `${currentHeight}px`
  }
}));
