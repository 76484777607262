const initialState = {
  controls: {
    location: "",
    type: "",
    name: ""
  },
  selected: [],
  monitor: [],
  sortKey: "location",
  instruments: []
};

export default initialState;
