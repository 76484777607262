import React from "react";
import { connect } from "react-redux";
import { changeFormFilters } from "../redux/actions";
import { selectValuesByKey } from "../../../../selectors";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import { useControlStyles } from "./styles";
import TextField from "@material-ui/core/TextField";
import { Module } from "../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const TypeControl = ({ type, types, changeFilter }) => {
  const classes = useControlStyles();

  let allListItem = Array.isArray(types) && types.map((t) => ({ t }));
  let finalArray = allListItem.map(function (obj) {
    return obj.t;
  });

  return (
    <GridAutocomplete
      id={generateID.UUID(Module.FilterName, `type_control`, "auto_complete")}
      className={classes.margin}
      variant="outlined"
      optionsList={finalArray}
      value={type || null}
      key={type}
      onAutoCompleteChange={(evt) => {
        const value = evt;
        changeFilter({
          key: "type",
          value: value
        });
      }}
      autoHighlight
      renderInput={(params) => (
        <TextField 
        id={generateID.UUID(Module.FilterName, `type_control`, "textfield")}
        {...params} variant="outlined" label="Model"></TextField>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  types: selectValuesByKey("equipmentModel")(state.devices.instruments),
  type: state.devices.controls?.type
});

export default connect(mapStateToProps, {
  changeFilter: changeFormFilters
})(TypeControl);
