import React from "react";
import styled, { css } from "styled-components";
import clx from "classnames";
import NotificationAllSwitcher from "./elements/NotificationAllDevicesSwitcher";
import NotificationVisibility from "./elements/NotificationVisibility";
import InstrumentsViewChanger from "./elements/InstrumentsViewChanger";
import DeviceFilterAppbarTitle from "./elements/DeviceFilterAppbarTitle";
import DeviceFilterOpenButton, {
  MobileFilterOpenButton
} from "../../instrumentsFilter/DeviceFilterButtons";
import { useMobile } from "../../../../utils/hooks";
import { Module } from "../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const devicesListMenuStylesForMobile = css`
  align-items: flex-start;
  flex-direction: column;
  padding: 0;
  & .switch {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 31px;
    margin-bottom: 10px;
  }
`;
const rightMobileStyles = css`
  &:last-child {
    margin-right: 0;
  }
`;

const DevicesListMenuStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.3rem 2rem 0.5rem;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  top: 10px;
  & > .left,
  & > .right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    & > * {
      margin-right: 0.8rem;

      ${(props) => props.isMobile && rightMobileStyles}
    }
  }
  ${(props) => props.isMobile && devicesListMenuStylesForMobile}
`;

const MonitoringDevicesMenuBar = () => {
  const isMobile = useMobile();
  return !isMobile ? (
    <DevicesListMenuStyled id={generateID.UUID(Module.MonitoringName, `mobile`, "menu_style")} data-testid="devicesListMenu">
      <div className="left" id={generateID.UUID(Module.MonitoringName, `mobile`, "div_left")}>
        <DeviceFilterAppbarTitle />
        <InstrumentsViewChanger />
        <NotificationAllSwitcher />
      </div>
      <div className="right" id={generateID.UUID(Module.MonitoringName, `mobile`, "div_right")}>
        <NotificationVisibility />
        <DeviceFilterOpenButton />
      </div>
      <MobileFilterOpenButton />
    </DevicesListMenuStyled>
  ) : (
    <DevicesListMenuStyled
      isMobile={isMobile}
      data-testid="devicesListMenu-mobile"
      id={generateID.UUID(Module.MonitoringName, `menubar`, "mobile")}
    >
      <DeviceFilterAppbarTitle />
      <div className={clx("right", "switch")} id={generateID.UUID(Module.MonitoringName, `menubar`, "switch")}>
        <InstrumentsViewChanger />
        <NotificationVisibility />
      </div>
      <MobileFilterOpenButton />
    </DevicesListMenuStyled>
  );
};

export default MonitoringDevicesMenuBar;
