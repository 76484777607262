import React from "react";
import styled from "styled-components";
import HistoryInstrumentMainView from "../features/devices/history/HistoryInstrumentMainView";
import InstrumentsFilterMainView from "../features/devices/instrumentsFilter/InstrumentsFilterMainView";
import MainMonitoringView from "../features/devices/monitoringDevices/MonitoringDevicesMainView";
import { getEnv } from "../utils/helpers/text";
import AppBar from "./AppBar";
import AppDrawer from "./AppDrawer";
import AppHistoryDrawer from "./AppHistoryDrawer";
import AppMenu from "./AppMenu";

const currentEnv = getEnv();
const AdaptarWrapperStyle = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto 0 auto;
  box-sizing: border-box;
`;
const AppVal = styled.div`
  .MuiAppBar-positionAbsolute {
    top: 42px;
  }
`;

const MainPage = () => {
  return (
    <>
      {currentEnv ? (
        <AppVal>
          <AppBar />
        </AppVal>
      ) : (
        <AppBar />
      )}
      <AdaptarWrapperStyle>
        <AppHistoryDrawer>
          <HistoryInstrumentMainView />
        </AppHistoryDrawer>
        <MainMonitoringView />
        <AppDrawer>
          <InstrumentsFilterMainView />
        </AppDrawer>
        <AppMenu />
      </AdaptarWrapperStyle>
    </>
  );
};

export default MainPage;
