import React from "react";
import { Typography, Button } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { FullScreenCentered } from "./styled";
import { Switch, Route } from "react-router-dom";

export const Error = ({ error }) => {
  return (
    <FullScreenCentered>
      <>
        <Switch>
          <Route path="/tv">
            <Typography variant="h6" gutterBottom data-testid="error-on-tv">
              Provided API key is expired or invalid
            </Typography>
          </Route>
        </Switch>
        <Route exact path="/">
          <Typography
            variant="h6"
            gutterBottom
            data-testid="error-on-normal-mode"
          >
            {error?.message ? error?.message : "Unexpected error has occurred"}
          </Typography>
        </Route>
        <Button
          data-testid="error-button-reload"
          variant="contained"
          onClick={() => window.location.reload()}
          color="secondary"
          startIcon={<ErrorIcon />}
        >
          Try again
        </Button>
      </>
    </FullScreenCentered>
  );
};
