const EndTime = (props) => (
  <svg
    width="17"
    height="23"
    viewBox="0 0 17 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.011 18.361H0V20.361H10.011V22.361L14.001 19.361L10.011 16.361V18.361Z"
      fill="#737373"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="17"
      height="23"
    >
      <rect width="16.664" height="22.36" fill="black" />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.38501 15.7668C12.732 15.7668 16.269 12.2298 16.269 7.88282C16.269 3.53682 12.732 -0.000183105 8.38501 -0.000183105C4.03801 -0.000183105 0.502014 3.53682 0.502014 7.88282C0.502014 12.2298 4.03801 15.7668 8.38501 15.7668ZM8.38501 1.99982C11.629 1.99982 14.269 4.63882 14.269 7.88282C14.269 11.1268 11.629 13.7668 8.38501 13.7668C5.14101 13.7668 2.50201 11.1268 2.50201 7.88282C2.50201 4.63882 5.14101 1.99982 8.38501 1.99982Z"
        fill="#737373"
      />
      <path
        d="M11.784 10.0648L9.13501 7.55982V4.99982H7.63501V8.20682L10.754 11.1548L11.784 10.0648Z"
        fill="#737373"
      />
      <rect x="14.664" y="16.525" width="2" height="5.663" fill="#737373" />
    </g>
  </svg>
);
export default EndTime;
