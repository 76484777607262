const DATA_MODEL_TABLE = {
    equipmentNickName: {
      key: "equipmentNickName",
      value: "Nickname"
    },
    equipmentId: {
      key: "equipmentId",
      value: "Equipment ID"
    },
    materialNumber: {
      key: "materialNumber",
      value: "Material number"
    },
    serialNumber: {
      key: "serialNumber",
      value: "Serial number"
    },
    equipmentCategory: {
      key: "equipmentCategory",
      value: "Category"
    },
    manufacture: {
      key: "manufacture",
      value: "Manufacturer"
    },
    equipmentModel: {
      key: "equipmentModel",
      value: "Model"
    },
    variant: {
      key: "variant",
      value: "Variant"
    },
    gtin: {
      key: "instrumentGTIN",
      value: "GTIN"
    },
    rudi: {
      key: "instrumentRUDI",
      value: "RUDI"
    },
    inventoryNumber: {
      key: "inventoryNumber",
      value: "Inventory number"
    },
    belongingToGroup: {
      key: "belongingToGroup",
      value: "Group"
    },
    siteName: {
      key: "siteName",
      value: "Site"
    },
    buildingLocation: {
      key: "buildingLocation",
      value: "Building"
    },
    floor: {
      key: "floor",
      value: "Floor"
    },
    room: {
      key: "room",
      value: "Room"
    },
    functionalLocation: {
      key: "functionalLocation",
      value: "Functional location"
    },
    systemOwner: {
      key: "systemOwner",
      value: "System owner"
    },
    responsiblePerson: {
      key: "responsiblePerson",
      value: "Responsible person"
    },
    responsibleProxy: {
      key: "responsibleProxy",
      value: "Responsible proxy"
    },
    systemStatus: {
      key: "systemStatus",
      value: "System status"
    },
    gxpRevelent: {
      key: "gxpRevelent",
      value: "GxP relevant"
    },
    qualificationStatus: {
      key: "qualificationStatus",
      value: "Qualification status"
    },
    qualificationDocuments: {
      key: "qualificationDocuments",
      value: "Qualification documents"
    },
    softwareVersion: {
      key: "softwareVersion",
      value: "SW version"
    },
    configurationBaseline: {
      key: "configurationBaseline",
      value: "Configuration baseline"
    },
    comment: {
      key: "comment",
      value: "Comment"
    },
    sop: {
      key: "sop",
      value: "SOP"
    },
    installedTests: {
      key: "installedTests",
      value: "Installed tests"
    },
    dateOfLastMaintanance: {
      key: "dateOfLastMaintanance",
      value: "Date of executed maintenance"
    },
    dateOfNextMaintenance: {
      key: "dateOfNextMaintenance",
      value: "Next maintenance or calibration"
    },
    dateOfNextPeriodicReview: {
      key: "dateOfNextPeriodicReview",
      value: "Date of periodic review"
    },
    maintenancePlan: {
      key: "maintenancePlan",
      value: "Maintenance or calibration plan"
    },
    electronicRecord: {
      key: "electronicRecord",
      value: "Electronic records"
    },
    isBookable: {
      key: "isBookable",
      value: "Bookable"
    },
    csv: {
      key: "csv",
      value: "CSV relevant"
    },
    electronicSignatures: {
      key: "electronicSignatures",
      value: "Electronic signatures"
    },
    isVisualized: {
      key: "isVisualized",
      value: "Visualized"
    },
    cluster: {
      key: "cluster",
      value: "Cluster"
    },
    equipmentAdministrator: {
      key: "equipmentAdministrator",
      value: "Test equipment admin"
    },
    costCenter: {
      key: "costCenter",
      value: "Cost center"
    },
    location: {
      key: "location",
      value: "Location"
    }
  };
  
  export default DATA_MODEL_TABLE;
  