import { pick, find } from "lodash";
import { createSelector } from "reselect";
import { keyIds } from "../features/devices/instrumentsFilter/redux/reducer";
export const getMonitor = (state) => state.devices.monitor;
export const getSelectedApps = (state) => state.devices.selected;
export const getNotificationSubscriptions = (state) =>
  state.userMonitorDevices.notificationSubscriptions;
export const getVisibilityFilter = (state) =>
  state.userMonitorDevices.visibilityFilter;

export const getNotificationSubscriptionsSelector = createSelector(
  [getNotificationSubscriptions],
  (notifications) => notifications.map((item) => pick(item, keyIds))
);

export const getSelectedAppsSelector = createSelector(
  [getSelectedApps],
  (selected) => selected.map((item) => pick(item, keyIds))
);

export const getInstruments = (state) => state.devices.instruments;

export const getDevicesWithInfo = (devices, instruments) => {
  if (!Array.isArray(devices)) return [];
  return devices
    .filter((d) => {
      return !!instruments[`${d?.inventoryId}`];
    })
    .map((d) => {
      return {
        ...d,
        ...instruments[`${d?.inventoryId}`]
      };
    });
};

export const selectDeviceToDisplaySelector = createSelector(
  [
    getSelectedAppsSelector,
    getNotificationSubscriptionsSelector,
    getVisibilityFilter,
    getInstruments
  ],
  (selected, notificationSubscriptions, filter, instruments) => {
    let filteredInstruments = [];
    selected.forEach((e) => {
      const foundDevice = find(instruments, { inventoryId: e.inventoryId });
      if (foundDevice) filteredInstruments.push(foundDevice);
    });

    const mappedDevice =
      filteredInstruments?.length === 0
        ? []
        : filteredInstruments.map((instrument) => {
            return { inventoryId: instrument.inventoryId };
          });

    if (filter !== "selected") return notificationSubscriptions;
    return mappedDevice;
  }
);

export const getInstrumentsSelector = createSelector(
  [getInstruments],
  (instruments) => {
    if (!Array.isArray(instruments)) return {};
    return instruments.reduce(
      (acc, d) => ({
        ...acc,
        [`${d?.inventoryId}`]: { ...d }
      }),
      {}
    );
  }
);
