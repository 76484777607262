import { Module } from "../../../constants";
import HistoryContentHeader from "./HistoryContentHeader";
import HistoryTopHeader from "./HistoryTopHeader";
import { generateID } from "@digitallab/grid-common-components";

const HistoryHeader = ({ historyDevice, historyClose }) => {
  return (
    <>
      <HistoryTopHeader 
      id={generateID.UUID(Module.HistoryInstrument, `historytop`, "header")}
      onClick={historyClose}></HistoryTopHeader>
      <HistoryContentHeader
        id={generateID.UUID(Module.HistoryInstrument, `historytop`, "content")}
        historyDevice={historyDevice}
      ></HistoryContentHeader>
    </>
  );
};

export default HistoryHeader;
