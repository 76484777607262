import React from "react";
import pick from "lodash/pick";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Tooltip, withStyles } from "@material-ui/core";
import { Module } from "../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    padding: 15,
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: theme.typography.pxToRem(16),
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.301961)"
  }
}))(Tooltip);

const DeviceRow = ({ device, deviceElements }) => {
  const data = pick(device, [
    "materialNumber",
    "serialNumber",
    "equipmentNickName",
    "detailedStatus",
    "location",
    "runStatus",
    "operator",
    "runStartTime",
    "runEndTime",
    "equipmentModel",
    "equipmentId",
    "inventoryId"
  ]);
  return (
    <TableRow data-testid={`${data?.inventoryId}-row`} id={generateID.UUID(Module.MonitoringList, `list_${data?.inventoryId}`, "table_row")}>
      {deviceElements.map((element, id) => {
        const Component = element?.component ? element?.component : null;
        if (!Component)
          return (
            <CustomTooltip
              title={<ValCheck item={data[element.key]} id={generateID.UUID(Module.MonitoringList, `list_${data?.inventoryId}`, "table_valcheck")}/>}
              placement="bottom-end"
              id={generateID.UUID(Module.MonitoringList, `list_${data?.inventoryId}`, "table_tooltip")}
            >
              <TableCell
                align="left"
                id={generateID.UUID(Module.MonitoringList, `list_${data?.inventoryId}`, "table_cell")}
                key={`${element.key ?? id}-${data?.inventoryId
                }`}
                data-testid={`${element.key ?? id}-${data?.inventoryId
                }`}
                style={{
                  fontWeight: 500,
                  lineHeight: "18px",
                  textOverflow: "ellipsis",
                  maxWidth: "70px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  paddingRight: 10
                }}
              >
                <ValCheck item={data[element.key]} id={generateID.UUID(Module.MonitoringList, `list_${data?.inventoryId}`, "table_cell_valcheck")}/>
              </TableCell>
            </CustomTooltip>
          );
        return (
          <TableCell
            align="left"
            key={`${element.key ?? id}-${data?.inventoryId
            }`}
            data-testid={`${element.key ?? id}-${data?.inventoryId
            }`}
            id={generateID.UUID(Module.MonitoringList, `list_${data?.inventoryId}`, "table_cell")}
          >
            <Component
              device={device}
              {...data}
              {...(element?.prop ?? {})}
              params={{ runId: data?.runId }}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export const ValCheck = ({ item }) => {
  if (
    item !== null &&
    item !== "" &&
    typeof item === "string"
  ) {
    return item === "null" ? "-" : item;
  }
  else {
    return "-";
  }
};

export default DeviceRow;
