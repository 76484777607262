const initialState = {
  id: "",
  email: "",
  notificationSubscriptions: [],
  visibilityFilter: "selected",
  instrumentsView: "cards",
  siteName: ""
};

export default initialState;
