import React from "react";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import { Mutation } from "react-apollo";
import { setMonitoringDevicesNotification as setMonitoringDevicesNotificationAction } from "../../../redux/actions";
import { UPDATE_DIGITAL_LAB_DEVICE_USER } from "../../../../../../gql/devices/mutation";
import { updateDigitalLabDeviceUserNotification } from "../../shared/mutations";
import { findSelectedItem } from "../../../../../../utils/helpers/array";
import { getNotificationSubscriptionsSelector } from "../../../../../../selectors/devices";
import { Module } from "../../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const DeviceCardNotification = ({
  notificationSubscriptions,
  inventoryId,
  id,
  email,
  setMonitoringDevicesNotification
}) => {
  const isOn =
    Array.isArray(notificationSubscriptions) &&
    findSelectedItem(notificationSubscriptions)({
      inventoryId
    });
  return (
    <div>
      <Typography
        variant="caption"
        display="block"
        data-testid="notification-titles"
        gutterBottom
        id={generateID.UUID(Module.MonitoringName, `devicecard_notification`, "typography")}
      >
        Notification
      </Typography>
      <Mutation mutation={UPDATE_DIGITAL_LAB_DEVICE_USER} id={generateID.UUID(Module.MonitoringName, `devicecard_notification`, "mutation")}>
        {(mutation) => (
          <FormControlLabel
            id={generateID.UUID(Module.MonitoringName, `devicecard_notification`, "form_control_label")}
            control={
              <Checkbox
                id={generateID.UUID(Module.MonitoringName, `devicecard_notification`, "checkout")}
                color="primary"
                checked={isOn}
                onChange={() =>
                  updateDigitalLabDeviceUserNotification({
                    mutation,
                    updateAction: setMonitoringDevicesNotification,
                    payload: {
                      id,
                      email,
                      notificationSubscriptions,
                      isOn,
                      ids: [{ inventoryId }]
                    }
                  })
                }
              />
            }
            data-testid="notification-label"
            label={isOn ? "ON" : "OFF"}
          />
        )}
      </Mutation>
    </div>
  );
};

const mapStateToProps = (state) => ({
  id: state.userMonitorDevices.id,
  email: state.userMonitorDevices.email,
  notificationSubscriptions: getNotificationSubscriptionsSelector(state)
});

export default connect(mapStateToProps, {
  setMonitoringDevicesNotification: setMonitoringDevicesNotificationAction
})(DeviceCardNotification);
