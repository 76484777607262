import React from "react";
import styled, { css } from "styled-components";
import { RocheCircularProgress, RocheButton } from "@one/react-kit";

const stackStyles = css`
  flex-direction: column;
  align-items: center;
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem;
  ${(props) => props.stack && stackStyles}
`;

export const withLoaderHandler =
  (Component) =>
  ({ loading, error, refetch, ...rest }) => {
    if (loading)
      return (
        <Centered>
          <RocheCircularProgress size={40} />
        </Centered>
      );
    if (error !== undefined)
      return (
        <div>
          <p>{`Error! ${error?.message}`}</p>
          <RocheButton onClick={() => refetch()}>Try again</RocheButton>
        </div>
      );
    return <Component {...rest} />;
  };
