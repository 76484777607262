import { useParams } from "react-router-dom";
import queryString from "query-string";
export function useQuery() {
  const params = useParams();
  try {
    return queryString.parse(atob(params?.query));
  } catch (err) {
    throw new Error("Requested URL is not valid.");
  }
}
