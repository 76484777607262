import React from "react";
import styled, { css } from "styled-components";
import { Module } from "../../../../constants";
import { useMobile } from "../../../../utils/hooks";
import DeviceCard from "./deviceCard/DeviceCard";
import { generateID } from "@digitallab/grid-common-components";

const mobileStyles = css`
  margin: 10px 0;
`;

export const CenterStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > .deviceCard {
    margin: 1rem;
    ${(props) => props.isMobile && mobileStyles}
  }
`;
const DeviceCards = ({ devices }) => {
  const isMobile = useMobile("xs");
  if (!Array.isArray(devices)) return null;
  if (!devices.length) return null;
  return (
    <CenterStyled isMobile={isMobile} data-testid="deviceCards" id={generateID.UUID(Module.MonitoringName, `devicecard`, "style")}>
      {devices.map((device, index) => (
        <DeviceCard id={generateID.UUID(Module.MonitoringName, `devicecard_${index}`, "card")} key={`${device?.inventoryId}`} device={device} />
      ))}
    </CenterStyled>
  );
};

export default DeviceCards;
