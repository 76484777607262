import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import DevicesLoader from "../monitoringDevices/devicesViews/DevicesLoader";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../../utils/theme/main";
import { setSelectedInstruments as setSelectedInstrumentsAction } from "../../devices/instrumentsFilter/redux/actions";
import { setInstrumentsView as setInstrumentsViewAction } from "../../devices/monitoringDevices/redux/actions";
import { useQuery } from "../../../utils/hooks";
import DeviceStatusesWrapper from "../monitoringDevices/devicesViews/DeviceStatusesWrapper";
import DevicesSubscription from "../../devices/monitoringDevices/devicesViews/DevicesSubscription";

const BigScreenMode = ({ setSelectedInstruments, setInstrumentsView }) => {
  const { selected = [], instrumentsView = "" } = useQuery();
  useEffect(() => {
    setSelectedInstruments(selected);
  }, [selected, setSelectedInstruments]);

  useEffect(() => {
    setInstrumentsView(instrumentsView);
  }, [instrumentsView, setInstrumentsView]);
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <DeviceStatusesWrapper>
        <DevicesLoader />
        <DevicesSubscription />
      </DeviceStatusesWrapper>
    </ThemeProvider>
  );
};

export default compose(
  connect(null, {
    setSelectedInstruments: setSelectedInstrumentsAction,
    setInstrumentsView: setInstrumentsViewAction
  })
)(BigScreenMode);
