export const LOAD_MONITORING_DEVICES_USER_INFO =
  "[MONITORING_DEVICES] LOAD_MONITORING_DEVICES_USER_INFO";
export const SET_MONITORING_DEVICES_NOTIFICATION =
  "[MONITORING_DEVICES] SET_MONITORING_DEVICES_NOTIFICATION";

export const SET_VISIBILITY_DEVICES =
  "[MONITORING_DEVICES] SET_VISIBILITY_DEVICES";

export const SET_INSTRUMENTS_VIEW = "[MONITORING_DEVICES] SET_INSTRUMENTS_VIEW";
export const SET_SITE_NAME = "[MONITORING_DEVICES] SET_SITE_NAME";
