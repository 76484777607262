import React from "react";
import pick from "lodash/pick";
import { Divider, makeStyles } from "@material-ui/core";
import DeviceCardHeader from "./deviceCardElements/DeviceCardHeader";
import DeviceCardContent from "./deviceCardElements/DeviceCardContent";
import DeviceCardFooter from "./deviceCardElements/DeviceCardFooter";
import DeviceCardStatusIndicator from "./deviceCardElements/DeviceCardStatusIndicator";
import styled from "styled-components";
import DeviceCardBookingLink from "./deviceCardElements/DeviceCardBookingLink";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../../constants";

const Card = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  width: 380px;
  padding: 0;
  border-radius: ${(props) => props.theme.card.borderRadius};
  background-color: ${(props) => props.theme.card.backgroundColor};
  border: ${(props) => props.theme.card.border};
`;
const useStyles = makeStyles(() => ({
  divider: {
    margin: "0 13px"
  }
}));
const DeviceCard = ({ device }) => {
  const classes = useStyles();
  const dataHeader = pick(device, [
    "location",
    "equipmentModel",
    "equipmentNickName",
    "materialNumber",
    "serialNumber",
    "equipmentId",
    "inventoryId"
  ]);
  const dataContent = pick(device, [
    "equipmentId",
    "detailedStatus",
    "materialNumber",
    "serialNumber",
    "inventoryId"
  ]);
  const dataFooter = pick(device, [
    "equipmentId",
    "inventoryId",
    "shortIdentifier",
    "materialNumber",
    "runStatus",
    "operator",
    "runStartTime",
    "runEndTime",
    "runId",
    "serialNumber",
    "location",
    "equipmentModel"
  ]);
  return (
    <Card
      data-cy={`DeviceCard-${dataContent?.inventoryId}`}
      data-testid={`DeviceCard-${dataContent?.inventoryId}`}
      className="deviceCard"
      id={generateID.UUID(Module.MonitoringName, `devicecard`, "card")}
    >
      <DeviceCardStatusIndicator
        detailedStatus={device?.detailedStatus}
        runStatus={device?.runStatus}
      />
      <DeviceCardHeader data={dataHeader} />
      <Divider
        classes={{
          root: classes.divider
        }}
      />
      <DeviceCardContent data={dataContent} />
      <Divider
        classes={{
          root: classes.divider
        }}
      />
      <DeviceCardFooter data={dataFooter} />
      <Divider
        classes={{
          root: classes.divider
        }}
      />
      <DeviceCardBookingLink data={dataHeader} />
    </Card>
  );
};

export default DeviceCard;
