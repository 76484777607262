import React, { useContext } from "react";
import { RocheTopBar, RocheTypography } from "@one/react-kit";
import styled from "styled-components";
import { IconWithText } from "../components/shared";
import { useMobile } from "../utils/hooks";
import UserTopMenuCard from "./UserTopMenuCard/UserTopMenuCard";
import { TogglerContext } from "../contexts";
import { equipmentLabel } from "../constants";
import { getEnv } from "../utils/helpers/text";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core";
import IconForApp from "../icons/IconForApp";
export const useStyles = makeStyles(() => ({
  iconButtonRoot: {
    padding: "0px"
  }
}));

const AppBarInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px;
`;

const AppbarStyled = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 1201;
`;

const currentEnv = getEnv();
const Appbar = () => {
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView({
      documentTitle: "IPSV",
      href: `${window.location?.origin}/ipsv`
    });
    // eslint-disable-next-line
  }, []);
  const isMobile = useMobile();
  const { mobileToggle } = useContext(TogglerContext);
  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const classes = useStyles();
  return !isMobile ? (
    <AppbarStyled
      data-testid="app-bar"
      style={currentEnv ? { top: "42px" } : {}}
    >
      <RocheTopBar
        title={
          <IconWithText
            iconComponent={IconForApp}
            iconStyle={{ marginRight: ".6rem" }}
          >
            <RocheTypography variant="button" style={{ textTransform: "none" }}>
              {equipmentLabel.pascalCase} Monitoring
            </RocheTypography>{" "}
          </IconWithText>
        }
        position="relative"
        rightIcons={[
          <UserTopMenuCard />,
          <AppBarInfo>
            <IconButton
              data-testid="info-button"
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              classes={{ root: classes.iconButtonRoot }}
            >
              <i className="one-icons">info</i>
            </IconButton>
          </AppBarInfo>
        ]}
      />
    </AppbarStyled>
  ) : (
    <AppbarStyled
      data-testid="app-bar-mobile"
      style={currentEnv ? { top: "42px" } : {}}
    >
      <RocheTopBar
        title={
          <IconWithText
            onClick={mobileToggle}
            iconComponent={IconForApp}
            iconStyle={{ marginRight: ".6rem" }}
          >
            <RocheTypography variant="button" style={{ textTransform: "none" }}>
              {equipmentLabel.pascalCase} Monitoring
            </RocheTypography>{" "}
          </IconWithText>
        }
        position="relative"
        rightIcons={[]}
      />
    </AppbarStyled>
  );
};

export default Appbar;
