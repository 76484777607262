import React from "react";
import pick from "lodash/pick";
import { Divider } from "@material-ui/core";
import DeviceCardContent from "../deviceCard/deviceCardElements/DeviceCardContent";
import DeviceCardFooter from "../deviceCard//deviceCardElements/DeviceCardFooter";
import DeviceListItemHeader from "./DeviceListItemHeader";
import styled from "styled-components";
import DeviceExpansionPanel from "./DeviceExpansionPanel";
import { Module } from "../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const Card = styled.div`
  padding: 0;
  margin: 0;
  background-color: ${(props) => props.theme.card.backgroundColor};
`;

const DeviceListItemMobile = ({ device }) => {
  const dataHeader = pick(device, [
    "location",
    "equipmentModel",
    "equipmentNickName",
    "materialNumber",
    "serialNumber",
    "equipmentId",
    "inventoryId"
  ]);
  const dataContent = pick(device, [
    "detailedStatus",
    "materialNumber",
    "serialNumber",
    "inventoryId"
  ]);
  const dataFooter = pick(device, [
    "materialNumber",
    "runStatus",
    "operator",
    "runStartTime",
    "runEndTime",
    "runId",
    "serialNumber",
    "location",
    "equipmentModel"
  ]);
  return (
    <DeviceExpansionPanel
      data={dataHeader}
      renderHeader={({ open }) => (
        <DeviceListItemHeader
          open={open}
          {...dataHeader}
          detailedStatus={dataContent?.detailedStatus}
          runStatus={dataFooter?.runStatus}
        />
      )}
      id={generateID.UUID(Module.MonitoringList, `expandsionpanel`, "header")}
    >
      <Card id={generateID.UUID(Module.MonitoringList, `expandsionpanel`, "card")}>
        <DeviceCardContent data={dataContent} />
        <Divider />
        <DeviceCardFooter data={dataFooter} />
      </Card>
    </DeviceExpansionPanel>
  );
};

export default DeviceListItemMobile;
