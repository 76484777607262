import React from "react";
import styled from "styled-components";
import { DeviceFilterHeaderButton } from "./DeviceFilterButtons";
import { equipmentLabel, Module } from "../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const DeviceFilterHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 8px;
`;

const DeviceFilterHeader = () => {
  return (
    <DeviceFilterHeaderStyled>
      <DeviceFilterHeaderButton />
      <h3 style={{ marginLeft: ".5rem" }} id={generateID.UUID(Module.FilterName, `filter_header`, "h3")} data-testid="device-filter-header">
        Filter {equipmentLabel.lowerCase}
      </h3>
    </DeviceFilterHeaderStyled>
  );
};

export default DeviceFilterHeader;
