import styled, { css } from "styled-components";

export const FullScreenCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100vw;
  height: 100vh;
`;

const calHeight = css`
  height: calc(100vh - ${(props) => props.spaceTop}px);
`;
export const AutoScroll = styled.div`
  overflow-y: auto;
  ${(props) => props.spaceTop && calHeight}
`;

export const TitleTVMode = styled.h1`
  font-size: 3rem;
  margin: 1rem;
  text-align: center;
  font-weight: 300;
  color: #666;
  text-transform: uppercase;
`;
