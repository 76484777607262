import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { setVisibilityFilter as setVisibilityFilterAction } from "../../redux/actions";
import { selectInstrumentsToMonitor as selectInstrumentsToMonitorAction } from "../../../instrumentsFilter/redux/actions";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import styled from "styled-components";
import { useMobile } from "../../../../../utils/hooks";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { getNotificationSubscriptionsSelector } from "../../../../../selectors";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../../constants";

const NotificationVisibilityStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  flex-grow: 1;
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: theme.spacing(2),
    minWidth: 188
  },
  formControlMobile: {
    marginLeft: theme.spacing(2),
    minWidth: 188,
    flexGrow: 1
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  select: {
    padding: "10px 0 10px 16px",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "16px",
    color: "#333333",
    minHeight: "16px"
  },
  selectMobile: {
    padding: "15px 0 14px 16px",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "19px",
    color: "#333333"
  },
  menuItem: {
    padding: "16px 0 16px 16px",
    fontSize: 14,

    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0px",

    "&$selected": {
      backgroundColor: "#D2E9FF",
      borderLeft: "4px solid #0066cc",
      color: "#0066cc",
      paddingLeft: 12,
      "&:hover": {
        backgroundColor: "#A5D2FF"
      }
    },
    "&:hover": {
      backgroundColor: "#EFEFEF"
    }
  },
  selected: {}
}));

const NotificationVisibility = ({
  setVisibilityFilter,
  notificationSubscriptions,
  selectInstrumentsToMonitor,
  visibilityFilter
}) => {
  const classes = useStyles();
  const isMobile = useMobile();
  const handleVisibilityChange = (evt) => {
    const val = evt.target.value;
    setVisibilityFilter(val);
    selectInstrumentsToMonitor(notificationSubscriptions);
  };
  return (
    <NotificationVisibilityStyled id={generateID.UUID(Module.MonitoringName, `notificationvisibility`, "style")} data-testid="notification-visibility">
      <FormControl
        variant="outlined"
        className={!isMobile ? classes.formControl : classes.formControlMobile}
        id={generateID.UUID(Module.MonitoringName, `notificationvisibility`, "form_control")}
      >
        <InputLabel id="demo-simple-select-outlined-label" >Show</InputLabel>
        <Select
          id={generateID.UUID(Module.MonitoringName, `notificationvisibility`, "select")}
          data-testid="notification-visibility-select"
          classes={{
            root: isMobile ? classes.selectMobile : classes.select
          }}
          name="notify"
          inputProps={{ "aria-label": "notify" }}
          onChange={handleVisibilityChange}
          label="Show"
          value={visibilityFilter}
        >
          <MenuItem
            classes={{
              root: classes.menuItem,
              selected: classes.selected
            }}
            value="selected"
            data-testid="notification-visibility-select-item-selected"
            id={generateID.UUID(Module.MonitoringName, `notificationvisibility`, "menu_item")}
          >
            All monitored
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.menuItem,
              selected: classes.selected
            }}
            value="notifications"
            data-testid="notification-visibility-select-item-notifications"
            id={generateID.UUID(Module.MonitoringName, `notificationvisibility`, "menu_item_select")}
          >
            Notifications
          </MenuItem>
        </Select>
      </FormControl>
    </NotificationVisibilityStyled>
  );
};

const mapStateToProps = (state) => ({
  notificationSubscriptions: getNotificationSubscriptionsSelector(state),
  visibilityFilter: state.userMonitorDevices.visibilityFilter
});

export default compose(
  connect(mapStateToProps, {
    setVisibilityFilter: setVisibilityFilterAction,
    selectInstrumentsToMonitor: selectInstrumentsToMonitorAction
  })
)(NotificationVisibility);
