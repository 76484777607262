import { Drawer, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { TogglerContext } from "../contexts";
import AppMenuList from "./AppMenuList";
import { withApollo } from "react-apollo";
import { useMobile } from "../utils/hooks";
import { displayNameSelector } from "../selectors/user";

const drawerWidth = 312;

const useStyles = makeStyles((theme) => {
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      zIndex: 0
    },
    drawerPaper: {
      boxShadow: "0px 6px 6px 0px rgba(57, 69, 80, 0.349)"
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerRightLine: {
      borderRight: "none"
    }
  };
});

const AppMenu = ({ client, name }) => {
  const classes = useStyles();
  const isMobile = useMobile();
  const { mobileToggle, mobileOpen } = useContext(TogglerContext);

  return (
    <Drawer
      variant="temporary"
      data-cy="drawer"
      anchor="left"
      open={mobileOpen && isMobile}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      onClose={mobileToggle}
      classes={{
        root: classes.drawer,
        paper: classes.drawerOpen,
        paperAnchorDockedLeft: classes.drawerRightLine
      }}
    >
      <AppMenuList
        name={name}
        client={client}
        mobileOpen={mobileOpen}
      ></AppMenuList>
    </Drawer>
  );
};
const mapStateToProps = (store) => ({
  name: displayNameSelector(store),
  email: store.userMonitorDevices.email
});

export default compose(connect(mapStateToProps), withApollo)(AppMenu);
