import gql from "graphql-tag";

export const ON_UPDATE_DIGITAL_LAB_DEVICE_STATUS = gql`
  subscription onUpdateDigitalLabDeviceStatus {
    onUpdateDigitalLabDeviceStatus {
      materialNumber
      serialNumber
      deviceStatus
      detailedStatus
      runId
      operator
      runStatus
      runStartTime
      runEndTime
    }
  }
`;
