import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { displayNameSelector } from "../../selectors/user";

const UserInfoTopCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  max-width: 100%;
  & > .userLogo {
    border-radius: 100%;
    background-color: ${(props) => props.theme.userLogo.backgroundColor};
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-bottom: 12px;
  }
  & > .userName {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    color: #333333;
    margin-bottom: 4px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > .userEmail {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    color: #737373;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const UserInfoTopCard = ({ email = "", name }) => {
  return (
    <UserInfoTopCardStyled data-testid="user-info-top-card">
      <div className="userLogo" data-testid="user-info-top-card-userLogo">
        {email?.[0]?.toUpperCase()}
      </div>
      <div className="userName" data-testid="user-info-top-card-username">
        {name}
      </div>
      <div className="userEmail" data-testid="user-info-top-card-email">
        {email}
      </div>
    </UserInfoTopCardStyled>
  );
};

const mapStateToProps = (store) => ({
  name: displayNameSelector(store),
  email: store.userMonitorDevices.email
});

export default connect(mapStateToProps)(UserInfoTopCard);
