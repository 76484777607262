import React from "react";
import { RocheTopBar, RocheTypography } from "@one/react-kit";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { Auth } from "aws-amplify";
import { IconWithText } from "../components/shared";
import { useMobile } from "../utils/hooks";
import { equipmentLabel } from "../constants";
import { getEnv } from "../utils/helpers/text";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "./AppBar";
import IconForApp from "../icons/IconForApp";

const AppBarInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px;
`;

const AppbarStyled = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 1201;
`;

const currentEnv = getEnv();
const CustomLink = ({ children, disabled, ...props }) => (
  <Link
    onClick={() => {
      if (!disabled) {
        if (
          process.env.REACT_APP_COGNITO_LOGIN_ENABLED?.trim()?.toUpperCase() !==
          "TRUE"
        ) {
          Auth.federatedSignIn({
            provider: process.env.REACT_APP_COGNITO_PROVIDER_ID
          });
        } else {
          Auth.federatedSignIn();
        }
      }
    }}
    style={{
      textDecoration: "none"
    }}
    {...props}
  >
    {children}
  </Link>
);

const AppbarNotAuth = ({ disabled = false }) => {
  const isMobile = useMobile();
  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const classes = useStyles();
  return !isMobile ? (
    <AppbarStyled
      data-testid="app-bar-not-auth-main-page"
      style={currentEnv ? { top: "42px" } : {}}
    >
      <RocheTopBar
        title={
          <IconWithText
            iconComponent={IconForApp}
            iconStyle={{ marginRight: ".6rem" }}
          >
            <RocheTypography variant="button" style={{ textTransform: "none" }}>
              {equipmentLabel.pascalCase} Monitoring
            </RocheTypography>{" "}
          </IconWithText>
        }
        position="relative"
        rightIcons={[
          <CustomLink
            disabled={disabled}
            data-testid="link-to-sign-in-on-button"
          >
            <Button
              data-testid="sign-in-button"
              disabled={disabled}
              color="primary"
              variant="contained"
              style={{ minWidth: "120px", backgroundColor: "#0066CC" }}
            >
              Sign in
            </Button>
          </CustomLink>,
          <AppBarInfo>
            <IconButton
              data-testid="info-button"
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              classes={{ root: classes.iconButtonRoot }}
            >
              <i className="one-icons">info</i>
            </IconButton>
          </AppBarInfo>
        ]}
      />
    </AppbarStyled>
  ) : (
    <AppbarStyled data-testid="app-bar-not-auth-main-page-mobile">
      <RocheTopBar
        title={<i className="one-icons">home</i>}
        position="relative"
        rightIcons={[
          <CustomLink
            disabled={disabled}
            data-testid="link-to-sign-in-on-button-mobile"
          >
            <AppBarInfo>
              <Button
                disabled={disabled}
                color="primary"
                variant="contained"
                data-testid="sign-in-button-mobile"
                style={{ backgroundColor: "#0066CC" }}
              >
                Sign in
              </Button>
            </AppBarInfo>
          </CustomLink>,
          <AppBarInfo>
            <IconButton
              data-testid="info-button"
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
            >
              <i className="one-icons">info</i>
            </IconButton>
          </AppBarInfo>
        ]}
      />
    </AppbarStyled>
  );
};

export default AppbarNotAuth;
