import * as types from "./actionTypes";

export const loadInstrumentsSuccess = (instruments) => ({
  type: types.LOAD_INSTRUMENTS_SUCCESS,
  payload: instruments
});

export const setSelectedInstruments = (instruments) => ({
  type: types.SELECT_INSTRUMENTS,
  payload: instruments
});

export const selectGroupInstruments = (instruments) => ({
  type: types.SELECT_GROUP_INSTRUMENTS,
  payload: instruments
});

export const selectInstrumentsToMonitor = (instruments) => ({
  type: types.SELECT_INSTRUMENTS_TO_MONITOR,
  payload: instruments
});

export const changeFormFilters = (option) => ({
  type: types.SET_FORM_FILTERS,
  payload: option
});

export const setSortKey = (key) => ({
  type: types.SET_SORT_KEY,
  payload: key
});
