import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { HistoryTogglerContext, TogglerContext } from "../../../contexts";
import { useMobile } from "../../../utils/hooks";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";

const MobileFilterOpenButtonStyled = styled.button`
  position: absolute;
  right: 0px;
  border-radius: 4px 0 0 4px;
  border-color: transparent;
  border-right: none;
  border-width: 1px;
  padding: 4px 8px;
  background-color: white;
  box-shadow: 0 2px 6px #bbb;
  color: #737373;
  outline: none;
  cursor: pointer;
`;

export const MobileFilterOpenButton = () => {
  const isMobile = useMobile();
  const { toggle } = useContext(TogglerContext);
  return (
    isMobile && (
      <MobileFilterOpenButtonStyled
        data-testid="mobile-filter-open-button"
        onClick={toggle}
        id={generateID.UUID(Module.FilterName, `filter_mobile`, "button_style")}
      >
        <i className="one-icons" id={generateID.UUID(Module.FilterName, `filter_mobile`, "button_icon")}>filter</i>
      </MobileFilterOpenButtonStyled>
    )
  );
};

const DeviceFilterOpenButton = () => {
  const isMobile = useMobile();
  const { value: open, toggle } = useContext(TogglerContext);
  return (
    !isMobile &&
    !open && (
      <Button
        id={generateID.UUID(Module.FilterName, `filter_open`, "button_open")}
        data-testid="device-filter-open-button"
        variant="outlined"
        color="primary"
        onClick={toggle}
        startIcon={<i className="one-icons" id={generateID.UUID(Module.FilterName, `filter_open`, "i_open")}>filter</i>}
      >
        Open filters
      </Button>
    )
  );
};
export const DeviceHistoryOpenButton = ({ device, list = false }) => {
  const { historyToggle } = useContext(HistoryTogglerContext);
  return !list ? (
    <Button
      id={generateID.UUID(Module.FilterName, `filter_history`, "button_logs")}
      data-testid="device-history-open-button"
      color="primary"
      onClick={() => historyToggle(device)}
      startIcon={<i className="one-icons" id={generateID.UUID(Module.FilterName, `filter_history`, "i_logs")}>history</i>}
    >
      See logs
    </Button>
  ) : (
    <Tooltip
      id={generateID.UUID(Module.FilterName, `filter_history`, "tooltip")}
      data-testid="device-history-open-button-tooltip"
      title="RUNS LOGS"
      placement="left"
      arrow
    >
      <IconButton
        onClick={() => historyToggle(device)}
        data-testid="device-history-open-button-just-icon"
        id={generateID.UUID(Module.FilterName, `filter_history`, "icon_button")}
      >
        <i className="one-icons" id={generateID.UUID(Module.FilterName, `filter_history`, "i_icons")}>history</i>
      </IconButton>
    </Tooltip>
  );
};

export const DeviceFilterHeaderButton = ({ name = "" }) => {
  const { value: open, toggle } = useContext(TogglerContext);
  return (
    open && (
      <IconButton
        id={generateID.UUID(Module.FilterName, `filter_header`, "button_very_last")}
        data-testid="device-filter-header-button"
        key={`close-drawer-${name}`}
        color="inherit"
        onClick={toggle}
        style={{ marginLeft: "-10px", color: "#0066CC" }}
      >
        <i className="one-icons" id={generateID.UUID(Module.FilterName, `filter_header`, "i_very_last")}>to_the_very_last</i>
      </IconButton>
    )
  );
};

export default DeviceFilterOpenButton;
