import React from "react";
import styled from "styled-components";

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
`;

const returnIcon = ({ icon, IconComponent, iconStyle, onClick }) => {
  if (icon) {
    return (
      <i
        className="one-icons"
        data-testid="app-icon-with-text-icon"
        onClick={onClick}
        style={iconStyle}
      >
        {icon}
      </i>
    );
  } else if (IconComponent) {
    return (
      <div
        data-testid="app-icon-with-text-icon"
        onClick={onClick}
        style={{ marginRight: ".6rem", display: "flex" }}
      >
        <IconComponent />
      </div>
    );
  } else {
    return null;
  }
};

export const IconWithText = ({
  icon,
  iconStyle = {},
  iconComponent: IconComponent,
  children,
  onClick
}) => {
  return (
    <StyledIcon>
      {returnIcon({ icon, IconComponent, iconStyle, onClick })}
      {children}
    </StyledIcon>
  );
};
