import { Button, Typography } from "@material-ui/core";
import React from "react";
import { Query } from "react-apollo";
import styled from "styled-components";
import { Module } from "../../constants";
import ConnectSubscription from "./ConnectSubscription";
import { generateID } from "@digitallab/grid-common-components";

const ErrorStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

export const Error = ({ refetch, title = "Cannot connect to endpoint" }) => {
  return (
    <ErrorStyled data-testid="bookingNotificationStatus-error" id={generateID.UUID(Module.DeviceSubscription, `subscription`, "error_style")}>
      <Typography variant="h6" 
        id={generateID.UUID(Module.DeviceSubscription, `subscription`, "typography")}
        gutterBottom>
        {title}
      </Typography>
      <Button
        id={generateID.UUID(Module.DeviceSubscription, `subscription`, "button_retry")}
        data-testid="bookingNotificationStatus-error-retry"
        variant="contained"
        color="secondary"
        onClick={() => refetch()}
      >
        Retry
      </Button>
    </ErrorStyled>
  );
};

const GenericSubscription = ({ query, subscription, onUpdateCallback }) => {
  return (
    <Query query={query} fetchPolicy="no-cache" id={generateID.UUID(Module.DeviceSubscription, `subscription`, "query")}>
      {({ loading, error, subscribeToMore, refetch }) => {
        if (loading) return null;
        if (error !== undefined) return <Error refetch={refetch} />;
        return (
          <ConnectSubscription
            subscribeToMore={subscribeToMore}
            documentSubscription={subscription}
            dataCallback={onUpdateCallback}
            variables={{ limit: 1 }}
            refetch={refetch}
            id={generateID.UUID(Module.DeviceSubscription, `subscription`, "connect")}
          />
        );
      }}
    </Query>
  );
};

export default GenericSubscription;
