import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { TogglerContext } from "../contexts";
import { useMobile } from "../utils/hooks";
import { getEnv } from "../utils/helpers/text";

export const drawerWidth = 304;
const currentEnv = getEnv();
const dynamicHeight = currentEnv ? 42 : 0;
const dynamicHeightAppDrawer = currentEnv ? "62px" : "30px";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 1,
    whiteSpace: "nowrap",
    zIndex: 0
  },
  drawerOpen: {
    width: drawerWidth,
    maxWidth: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    top: dynamicHeight,
    height: `calc(100vh - ${dynamicHeightAppDrawer})`,
    position: "fixed",
    [theme.breakpoints.down("sm")]: {
      right: 0
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 0,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

const AppDrawer = ({ children }) => {
  const isMobile = useMobile();
  const classes = useStyles();
  const { value: isOpen, toggle } = useContext(TogglerContext);
  return (
    <Drawer
      data-testid="app-drawer"
      className={isOpen ? classes.drawer : ""}
      variant={isMobile ? "temporary" : "persistent"}
      open={isOpen}
      onClose={toggle}
      ModalProps={{
        keepMounted: true
      }}
      anchor="right"
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen
        })
      }}
    >
      <div className={classes.toolbar} />
      {children}
    </Drawer>
  );
};

export default AppDrawer;
