import React from "react";
import styled from "styled-components";
import { Module } from "../../../../../../constants";
import { STATUSES, getStatus } from "../../shared/statues";
import { generateID } from "@digitallab/grid-common-components";

const DeviceCardStatusIndicatorStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 4px 4px 0px 0px;
  background: ${(props) => {
    switch (props.status) {
      case STATUSES.NORMAL:
        return props.theme.indicator.normal;
      case STATUSES.ERROR:
        return props.theme.indicator.error;
      case STATUSES.SERVICE:
        return props.theme.indicator.service;
      default:
        return props.theme.indicator.unknown;
    }
  }};
`;

const DeviceCardStatusIndicator = ({
  detailedStatus = "",
  renderIndicator
}) => {
  const status = getStatus([detailedStatus]);
  return typeof renderIndicator === "function" ? (
    renderIndicator({ status })
  ) : (
    <DeviceCardStatusIndicatorStyled
      data-testid="deviceCardStatusIndicator"
      id={generateID.UUID(Module.MonitoringName, `devicecard`, "status")}
      status={status}
    />
  );
};

export default DeviceCardStatusIndicator;
