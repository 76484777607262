const ID = (props) => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.94629 13H0.30957V0.203125H2.94629V13ZM5.39844 13V0.203125H9.33594C10.4609 0.203125 11.4658 0.458008 12.3506 0.967773C13.2412 1.47168 13.9355 2.19238 14.4336 3.12988C14.9316 4.06152 15.1807 5.12207 15.1807 6.31152V6.90039C15.1807 8.08984 14.9346 9.14746 14.4424 10.0732C13.9561 10.999 13.2676 11.7168 12.377 12.2266C11.4863 12.7363 10.4814 12.9941 9.3623 13H5.39844ZM8.03516 2.33887V10.8818H9.30957C10.3408 10.8818 11.1289 10.5449 11.6738 9.87109C12.2188 9.19727 12.4971 8.2334 12.5088 6.97949V6.30273C12.5088 5.00195 12.2393 4.01758 11.7002 3.34961C11.1611 2.67578 10.373 2.33887 9.33594 2.33887H8.03516Z"
      fill="#737373"
    />
  </svg>
);
export default ID;
