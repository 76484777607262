import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { FullScreenCentered } from "./shared";

// It's just for the sonar to fix major issue
const TheChildren = ({ children }) => <>{children}</>;
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, title: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <FullScreenCentered>
          <>
            <Typography variant="h6" gutterBottom>
              {this.state.error?.message ||
                this.props.title ||
                "Unexpected error has occurred."}
            </Typography>
            <Button
              variant="contained"
              onClick={() => window.location.reload()}
              color="secondary"
              startIcon={<ErrorIcon />}
            >
              Try again
            </Button>
          </>
        </FullScreenCentered>
      );
    }

    return <TheChildren>{this.props.children}</TheChildren>;
  }
}

export default withRouter(ErrorBoundary);
