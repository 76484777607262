import React from "react";
import { ThemeProvider } from "styled-components";
import { mainTheme as defaultTheme } from "../utils/theme/main";
export const mainTheme = {
  ...defaultTheme,
  table: {
    borderColor: "#D3D3D3",
    color: "#333333",
    backgroundColor: "#FFFFFF",
    header: {
      backgroundColor: "#efefef",
    },
    cell: {
      backgroundColor: "#FFFFFF",
    },
  },
  notification: {
    warning: {
      color: "#333333",
      backgroundColor: "#ffffff",
      border: "2px solid #cc0033",
      backgroundIconColor: "#cc0033",
    },
    success: {
      color: "#333333",
      backgroundColor: "#ffffff",
      border: "2px solid #00875A",
      backgroundIconColor: "#00875A",
    },
  },
};

const StyledThemeProvider = ({ children }) => {
  return <ThemeProvider theme={mainTheme}>{children}</ThemeProvider>;
};

export default StyledThemeProvider;
