import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    background: {
      default: "#F2F7FC"
    },
    primary: {
      main: "#0066CC"
    },
    secondary: {
      main: "#CC0033"
    },
    error: {
      main: "#CC0033"
    }
  }
});
const MaterialTheme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
export default MaterialTheme;
