import get from "lodash/get";
import { parseText } from "../../utils/helpers/text";

export const getApiKeyAndFilters = (key = "bigscreen") => {
  let api_key = "";
  if (!parseText(key)) return { api_key };
  try {
    const bigscreenSettings = JSON.parse(localStorage.getItem(key) || {});
    api_key = get(bigscreenSettings, "api_key");
    return { api_key };
  } catch (err) {
    return { api_key };
  }
};

export const setApiKeyAndFilters = ({ key = "bigscreen", api_key = "" }) => {
  if (!parseText(key)) return false;
  if (!parseText(api_key)) return false;
  localStorage.setItem(
    key,
    JSON.stringify({
      api_key
    })
  );
  return true;
};
