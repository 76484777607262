import React from "react";
import { BasicPartialCardLayout } from "../../shared/statues";
import DeviceCardInstrumentRunStatus from "./DeviceCardInstrumentRunStatus";
import DeviceCardIconLabel from "./DeviceCardIconLabel";
import moment from "moment";
import { Module } from "../../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const DeviceCardFooter = ({ data }) => {
  return (
    <BasicPartialCardLayout
      footer
      data-testid={`deviceCardFooter-${data?.inventoryId}`}
      id={generateID.UUID(Module.MonitoringName, `devicecard_footer`, "layout")}
    >
      <DeviceCardInstrumentRunStatus id={generateID.UUID(Module.MonitoringName, `devicecard_footer`, "status")} data={data} />
      <DeviceCardIconLabel icon="runId" label={data?.runId} id={generateID.UUID(Module.MonitoringName, `devicecard_footer`, "label")}/>
      <DeviceCardIconLabel icon="operator" label={data?.operator} id={generateID.UUID(Module.FilterName, `devicecard_footer`, "opetator")}/>
      {data.runStartTime ? (
        <DeviceCardIconLabel
          icon="runStartTime"
          label={moment(data?.runStartTime).format("DD-MMM-YYYY")}
          subtext={moment(data?.runStartTime).format("h:mm a")}
          id={generateID.UUID(Module.MonitoringName, `devicecard_footer`, "icon_label")}
        />
      ) : (
        <DeviceCardIconLabel icon="runStartTime" id={generateID.UUID(Module.MonitoringName, `devicecard_footer`, "label_icon")}/>
      )}
      {data.runEndTime ? (
        <DeviceCardIconLabel
          icon="runEndTime"
          label={moment(data?.runEndTime).format("DD-MMM-YYYY")}
          subtext={moment(data?.runEndTime).format("h:mm a")}
          id={generateID.UUID(Module.MonitoringName, `devicecard_footer`, "icon_runtime")}
        />
      ) : (
        <DeviceCardIconLabel icon="runEndTime" label={"Ongoing"} id={generateID.UUID(Module.MonitoringName, `devicecard_footer`, "card_icon_label")}/>
      )}
    </BasicPartialCardLayout>
  );
};

export default DeviceCardFooter;
