import React, { useContext } from "react";
import styled from "styled-components";
import { BackendInfoContext } from "../backend-info-context/context";

const WrapperDivFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  box-sizing: border-box;
  background: #0066cc;
  position: absolute;
  bottom: 0px;
  z-index: 1500;
  & a {
    text-decoration: none;
  }
`;

const LabelStyle = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

const FooterSection = () => {
  const { FooterLeftLabel } = useContext(BackendInfoContext);
  return (
    <>
      <WrapperDivFooter className="WrapperDivFooter">
        <a href="/info">
          <LabelStyle className="lebelFooter">{FooterLeftLabel}</LabelStyle>
        </a>
      </WrapperDivFooter>
    </>
  );
};
export default FooterSection;
