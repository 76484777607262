import React from "react";
import { StatusStyled } from "../../shared/statues";
import styled from "styled-components";
import { DeviceHistoryOpenButton } from "../../../../instrumentsFilter/DeviceFilterButtons";
import { Module } from "../../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const RunStatusInfoHeaderStyled = styled.h4`
  font-size: 16px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  font-style: normal;
  text-transform: capitalize;
  margin: 4px;
`;

const DeviceCardInstrumentRunStatus = ({ data }) => {
  return (
    <StatusStyled
      className="full"
      between
      data-testid={`run-status-${data?.inventoryId}`}
      id={generateID.UUID(Module.FilterName, `devicecard_runstatus_${data?.inventoryId}`, "status_style")}
    >
      <RunStatusInfoHeaderStyled id={generateID.UUID(Module.FilterName, `devicecard_runstatus_${data?.inventoryId}`, "status_header")} data-testid="run-status-header">
        {data?.runStatus?.toLowerCase() ?? "unknown"}
      </RunStatusInfoHeaderStyled>
      <DeviceHistoryOpenButton device={data}></DeviceHistoryOpenButton>
    </StatusStyled>
  );
};

export default DeviceCardInstrumentRunStatus;
