import { get, omit } from "underscore";

const checkObjectInvalidity = (obj) =>
  typeof obj !== "object" || obj === null || Array.isArray(obj);

export const createGQLFilterObj = (obj) => {
  if (checkObjectInvalidity(obj)) {
    return null;
  }
  const objKeys = Object.keys(obj);
  if (!objKeys.length) {
    return null;
  }
  const arrayFilters = objKeys.reduce(
    (acc, curr) =>
      Array.isArray(obj[curr]) ? { ...acc, [curr]: obj[curr] } : acc,
    {}
  );
  const arrayFilterKeys = Object.keys(arrayFilters);
  if (arrayFilterKeys.length) {
    const OrFilter = getOrFilter(arrayFilterKeys, arrayFilters);
    if (!OrFilter.length) {
      return getGQLFilterObj(omit(obj, arrayFilterKeys));
    }
    const simpleFilter = getGQLFilterObj(omit(obj, arrayFilterKeys));
    if (!simpleFilter) {
      return {
        and: [...OrFilter?.map((arr) => ({ or: arr }))]
      };
    }
    return {
      and: [...OrFilter?.map((arr) => ({ or: arr })), simpleFilter]
    };
  }
  return getGQLFilterObj(obj);
};

export const getGQLFilterObj = (obj) => {
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    return null;
  }
  const result = Object.keys(obj).reduce((acc, cur) => {
    if (obj?.[cur] === null || obj?.[cur] === undefined) {
      return acc;
    }
    if (
      typeof obj?.[cur] === "string" &&
      (obj?.[cur]?.trim() === "" || obj?.[cur]?.trim() === "all")
    ) {
      return acc;
    }
    return { ...acc, [cur]: { eq: obj[cur] } };
  }, {});
  return Object.keys(result).length === 0 ? null : result;
};

const checkAllDataParams = ({ client, query, variables, dataPath }) => {
  if (!client || !query || !variables) {
    return false;
  }
  if (!Array.isArray(dataPath)) {
    return false;
  }
  if (dataPath.length === 0) {
    return false;
  }
  return true;
};

export const getAllData = async ({
  client,
  query,
  fetchPolicy = "network-only",
  variables = {},
  dataPath,
  drillData = false
}) => {
  const emptyResponse = {
    items: [],
    nextToken: null,
    error: null
  };
  if (
    !checkAllDataParams({
      client,
      query,
      variables,
      dataPath
    })
  ) {
    return emptyResponse;
  }
  try {
    let items = [];
    let nextToken = variables?.nextToken ?? null;
    let shouldKeepFetching = true;
    const { limit = 10 } = variables;
    do {
      const result = await client.query({
        query,
        fetchPolicy,
        variables: {
          ...variables,
          nextToken
        }
      });
      const page = get(result, [...dataPath, "items"]) ?? [];
      items = Array.isArray(page) ? [...items, ...page] : items;
      const _nextToken = get(result, [...dataPath, "nextToken"]);
      nextToken =
        typeof _nextToken === "string" && _nextToken ? _nextToken : null;
      shouldKeepFetching = drillData ? drillData : items.length < limit;
    } while (nextToken !== null && shouldKeepFetching);
    return {
      items,
      nextToken,
      error: null
    };
  } catch (err) {
    return { ...emptyResponse, error: err };
  }
};
function getOrFilter(arrayFilterKeys, arrayFilters) {
  return arrayFilterKeys
    ?.reduce(
      (acc, key) =>
        arrayFilters[key].length
          ? [
              ...acc,
              arrayFilters[key]?.reduce(
                (acc_i, val) =>
                  typeof val === "string"
                    ? [...acc_i, getGQLFilterObj({ [key]: val })]
                    : acc_i,
                []
              )
            ]
          : acc,
      []
    )
    .filter((arr) => arr.length);
}
export const getContainerHeightPercentage = (currentEnv) => {
  const containerHeight =
    ((window.innerHeight - (currentEnv ? 145 : 93)) / window.innerHeight) * 100;
  return containerHeight;
};

export const getContainerHeightPx = (currentEnv) => {
  const containerHeight = window.innerHeight - (currentEnv ? 400 : 400);
  return containerHeight;
};
