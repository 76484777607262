import React, { useContext } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeviceRow from "./deviceListItem/DeviceRow";
import styled from "styled-components";
import { HistoryTogglerContext } from "../../../../contexts";
import InstrumentStatus from "./deviceCard/deviceCardElements/InstrumentStatus";
import DeviceListItemMobile from "./deviceListItemMobile/DeviceListItemMobile";
import {
  DeviceListItemHeaderTitle,
  RunEndTimeFormat,
  RunStartTimeFormat
} from "./deviceListItemMobile/DeviceListItemHeader";
import { useMobile } from "../../../../utils/hooks";
import DeviceActions from "./deviceListItem/DeviceActions";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import { TogglerContext } from "../../../../contexts";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

const TableStyled = styled.div`
  margin-top: 1rem;
  padding: 0;
  background-color: ${(props) => props.theme.card.backgroundColor};
  & thead {
    background-color: ${(props) => props.theme.list.headerBackgroundColor};
  }
  & td,
  & th {
    line-height: 20px;
    padding: 12px 10px 12px 0px;
    font-weight: 400;
  }
  & th:first-child {
    padding-left: 16px;
  }
  & th:last-child {
    padding-right: 16px;
  }
  & tr > td {
    background-color: ${(props) => props.theme.card.backgroundColor};
  }
  & tr > td:first-child {
    padding-left: 16px;
  }
`;

export const CenterStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  & > * {
    margin: 0;
  }
`;

export const DEVICE_ELEMENTS = [
  { key: "equipmentNickName", text: DATA_MODEL_TABLE.equipmentNickName.value },
  { key: "equipmentModel", text: DATA_MODEL_TABLE.equipmentModel.value },
  { key: "serialNumber", text: DATA_MODEL_TABLE.serialNumber.value },
  { key: "materialNumber", text: DATA_MODEL_TABLE.materialNumber.value },
  { key: "equipmentId", text: DATA_MODEL_TABLE.equipmentId.value },
  { key: "location", text: DATA_MODEL_TABLE.location.value },
  {
    key: "detailedStatus",
    text: "Status",
    component: InstrumentStatus
  },
  { key: "runStatus", text: "Recent run" },
  { key: "operator", text: "Run operator" },
  { key: "runStartTime", text: "Run started", component: RunStartTimeFormat },
  { key: "runEndTime", text: "Run ended", component: RunEndTimeFormat },
  {
    key: "actions",
    text: "Actions",
    component: DeviceActions,
    prop: { type: "list", list: true, align: "right" }
  }
];

const getDeviceElements = (isHistoryOpen) => {
  if (isHistoryOpen)
    return DEVICE_ELEMENTS.filter(
      (x) =>
        !["runStatus", "operator", "runStartTime", "runEndTime"].includes(x.key)
    );
  return DEVICE_ELEMENTS;
};

const DeviceList = ({ devices }) => {
  const isMobile = useMobile();
  const { historyDevice } = useContext(HistoryTogglerContext);
  const { value } = useContext(TogglerContext);
  if (!Array.isArray(devices)) return null;
  if (!devices.length) return null;
  const deviceElements = getDeviceElements(historyDevice || value);
  return isMobile ? (
    <DeviceListMobile devices={devices} />
  ) : (
    <TableStyled data-testid="deviceList" id={generateID.UUID(Module.MonitoringList, `list`, "table_style")}>
      <Table aria-label="instruments table" id={generateID.UUID(Module.MonitoringList, `list`, "table")}>
        <TableHead id={generateID.UUID(Module.MonitoringList, `list`, "table_head")}>
          <TableRow id={generateID.UUID(Module.MonitoringList, `list`, "table_row")}>
            {deviceElements.map((element, index) => (
              <TableCell
                data-testid={`table-head-${element.key}`}
                key={element.key}
                align={element?.prop?.align ?? "left"}
                id={generateID.UUID(Module.MonitoringList, `list`, "table_cell")}
              >
                {element.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody id={generateID.UUID(Module.MonitoringList, `list`, "table_tbody")}>
          {devices.map((device) => (
            <DeviceRow
              key={`${device?.inventoryId}`}
              device={device}
              deviceElements={deviceElements}
              id={generateID.UUID(Module.MonitoringList, `list`, "table_devicerow")}
            />
          ))}
        </TableBody>
      </Table>
    </TableStyled>
  );
};

const DeviceListMobile = ({ devices }) => {
  return (
    <CenterStyled id={generateID.UUID(Module.MonitoringList, `list`, "li_listmobile")} data-testid="deviceListMobile">
      <DeviceListItemHeaderTitle
        headers={[
          DATA_MODEL_TABLE.equipmentNickName.value,
          DATA_MODEL_TABLE.equipmentModel.value
        ]}
      />
      {devices.map((device, index) => (
        <DeviceListItemMobile 
        id={generateID.UUID(Module.MonitoringList, `list_${index}`, "li_mobile")}
        key={`${device?.inventoryId}`} device={device} />
      ))}
    </CenterStyled>
  );
};

export default DeviceList;
