import gql from "graphql-tag";

export const DEVICES_LAB_FILTER = gql`
  query deviceBySiteAndLocation(
    $siteName: String
    $nextToken: String
    $limit: Int
    $filter: ModelDigitalLabDeviceInfoFilterInput
  ) {
    deviceBySiteAndLocation(
      siteName: $siteName
      nextToken: $nextToken
      limit: $limit
      filter: $filter
    ) {
      items {
        linkedInstance
        secondaryIDMapping
        location
        equipmentModel
        materialNumber
        equipmentNickName
        serialNumber
        equipmentId
        inventoryId
        shortIdentifier
      }
      nextToken
    }
  }
`;

export const GET_DIGITAL_LAB_DEVICE_USER = gql`
  query getDigitalLabDeviceUser($id: ID!, $email: String!) {
    getDigitalLabDeviceUser(id: $id, email: $email) {
      id
      email
      familyName
      givenName
      name
      phoneNumber
      notificationSubscriptions {
        inventoryId
      }
      deviceUseState {
        processingStatusFilter {
          deviceName
          deviceType
          deviceLocation
        }
        processingStatusSelected {
          inventoryId
        }
        processingStatusSortKey
        instrumentsView
      }
      createdAt
      updatedAt
    }
  }
`;

export const LIST_SELECTED_DIGITAL_LAB_DEVICE_STATUS = gql`
  query listSelectedDigitalLabDeviceInfos(
    $filter: ModelDigitalLabDeviceStatusFilterInput
    $nextToken: String
    $limit: Int
  ) {
    listDigitalLabDeviceStatuss(
      nextToken: $nextToken
      limit: $limit
      filter: $filter
    ) {
      items {
        deviceStatus
        detailedStatus
        runStatus
        runId
        operator
        runStartTime
        runEndTime
        materialNumber
        serialNumber
      }
      nextToken
    }
  }
`;

export const BY_STATUS_UPDATE_TIME = gql`
  query byStatusUpdateTime(
    $materialNumber: String!
    $serialNumber: String!
    $nextToken: String
    $limit: Int
  ) {
    byStatusUpdateTime(
      limit: $limit
      nextToken: $nextToken
      materialNumber: $materialNumber
      sortDirection: DESC
      filter: { serialNumber: { eq: $serialNumber } }
    ) {
      items {
        materialNumber
        serialNumber
        id
        operator
        runEndTime
        runId
        runStartTime
        runStatus
      }
      nextToken
    }
  }
`;

export const LIST_BACKEND_HEALTH_CHECKS = gql`
  query listBackendHealthChecks {
    listBackendHealthChecks {
      items {
        ciJobId
        commitHash
        createdAt
        id
        moduleName
        enableAnalyticsTracking
        moduleVersion
        updatedAt
      }
    }
  }
`;

export const GET_LOGGED_USER_DETAILS = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        email
        id
        notificationSubscriptions {
          inventoryId
        }
        familyName
        givenName
        name
        phoneNumber
      }
    }
  }
`;
