import { Divider } from "@material-ui/core";
import styled from "styled-components";
import HistoryHeader from "./HistoryHeader";
import HistoryList from "./HistoryList";
import { makeStyles } from "@material-ui/core/styles";
import { HistoryTogglerContext } from "../../../contexts";
import React, { useContext } from "react";
import { Module } from "../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const HistoryBarStyled = styled.div`
  box-sizing: border-box;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 10px;
`;
const useStyles = makeStyles(() => ({
  divider: {
    margin: "14px 0 0 0",
    flexShrink: 0
  }
}));

const HistoryInstrumentMainView = () => {
  const { historyDevice, historyClose } = useContext(HistoryTogglerContext);
  const classes = useStyles();
  return (
    <>
      <HistoryBarStyled 
      id={generateID.UUID(Module.HistoryInstrument, `history`, "style")}
      data-testid="controls-view">
        <HistoryHeader
          historyDevice={historyDevice}
          historyClose={historyClose}
          id={generateID.UUID(Module.HistoryInstrument, `history`, "header")}
        ></HistoryHeader>
        <Divider
          classes={{
            root: classes.divider
          }}
        ></Divider>
        {historyDevice && (
          <HistoryList
            materialNumber={historyDevice?.materialNumber}
            serialNumber={historyDevice?.serialNumber}
            id={generateID.UUID(Module.HistoryInstrument, `history`, "list")}
          ></HistoryList>
        )}
      </HistoryBarStyled>
    </>
  );
};

export default HistoryInstrumentMainView;
