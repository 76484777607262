import { createContext, useReducer } from "react";
import { DeviceComparator } from "../../instrumentsFilter/redux/reducer";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";

export const DeviceStatusesContext = createContext({});

export const initialState = {
  fetching: true,
  items: [],
  error: null
};

export function reducer(
  state = initialState,
  action = { type: "", payload: "" }
) {
  switch (action.type) {
    case "fetching":
      return {
        ...state,
        error: null,
        fetching: action.payload
      };
    case "data":
      return {
        ...state,
        fetching: false,
        items: action.payload
      };
    case "update": {
      return {
        ...state,
        items: state.items.map((item) =>
          DeviceComparator(item, action.payload, [
            DATA_MODEL_TABLE.serialNumber.key,
            DATA_MODEL_TABLE.materialNumber.key
          ])
            ? { ...item, ...action.payload }
            : item
        )
      };
    }
    case "error":
      return {
        ...state,
        fetching: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export const DeviceStatusesWrapper = ({ children, initialValues = null }) => {
  const [state, dispatchAction] = useReducer(reducer, {
    ...initialState,
    ...initialValues
  });
  return (
    <DeviceStatusesContext.Provider
      value={{
        ...state,
        dispatchAction
      }}
    >
      {children}
    </DeviceStatusesContext.Provider>
  );
};

export default DeviceStatusesWrapper;
