import xorBy from "lodash/xorBy";
import unionBy from "lodash/unionBy";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import intersectionWith from "lodash/intersectionWith";
import differenceBy from "lodash/differenceBy";
import * as types from "./actionTypes";
import initialState from "./initialState";
import { isEqual, pick } from "lodash";

export const keyIds = ["inventoryId"];

export const DeviceComparator = (x, y, keys = keyIds) =>
  isEqual(pick(x, keys), pick(y, keys));

export const getDeviceIdentity = (x) => `${x?.inventoryId}`;
const setSelectedInstruments = (state, payload) => {
  const newValues = Array.isArray(payload) ? payload : [payload];
  const newSelected = sortBy(
    xorBy(state.selected, newValues, getDeviceIdentity),
    keyIds
  );
  const monitor = sortBy(
    [...uniqBy([...state.monitor, ...newValues], getDeviceIdentity)],
    keyIds
  );
  return { ...state, selected: [...newSelected], monitor: [...monitor] };
};

const selectGroupInstruments = (state, payload) => {
  const isSelected =
    intersectionWith(payload, state.selected, DeviceComparator).length ===
    payload.length;
  if (isSelected)
    return {
      ...state,
      selected: [...differenceBy(state.selected, payload, getDeviceIdentity)]
    };
  const monitor = sortBy(
    [...uniqBy([...state.monitor, ...payload], getDeviceIdentity)],
    keyIds
  );
  return {
    ...state,
    selected: [...unionBy(state.selected, payload, getDeviceIdentity)],
    monitor
  };
};

const setFilters = (state, payload) => {
  if (Object.keys(payload).length === 3)
    return {
      ...state,
      controls: { ...payload }
    };
  return {
    ...state,
    controls: { ...state.controls, [payload.key]: payload.value }
  };
};

const setMonitor = (state, payload) => {
  const monitor = [
    ...uniqBy([...state.monitor, ...payload], getDeviceIdentity)
  ].sort();
  return { ...state, monitor: [...monitor] };
};

export default function instrumentsReducer(
  state = initialState,
  { type, payload } = {}
) {
  switch (type) {
    case types.LOAD_INSTRUMENTS_SUCCESS:
      return { ...state, loading: false, instruments: payload };
    case types.SELECT_INSTRUMENTS:
      return setSelectedInstruments(state, payload);
    case types.SELECT_GROUP_INSTRUMENTS:
      return selectGroupInstruments(state, payload);
    case types.SELECT_INSTRUMENTS_TO_MONITOR:
      return setMonitor(state, payload);
    case types.SET_SORT_KEY:
      return { ...state, sortKey: payload };
    case types.SET_FORM_FILTERS:
      return setFilters(state, payload);
    default:
      return state;
  }
}
