const IconForApp = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_866_19680)">
      <path
        d="M3.2225 31.1875H44.7775"
        stroke="#1482FA"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M42 5.73499H6C4.34315 5.73499 3 7.07813 3 8.73498V32.735C3 34.3918 4.34315 35.735 6 35.735H42C43.6569 35.735 45 34.3918 45 32.735V8.73498C45 7.07813 43.6569 5.73499 42 5.73499Z"
        stroke="#0B41CD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.1525 41.3V35.985M27.8475 35.985V41.3M11.955 42.265C11.9185 42.265 11.8836 42.2505 11.8578 42.2247C11.832 42.1989 11.8175 42.164 11.8175 42.1275C11.8175 42.091 11.832 42.056 11.8578 42.0303C11.8836 42.0045 11.9185 41.99 11.955 41.99H36.045C36.0815 41.99 36.1164 42.0045 36.1422 42.0303C36.168 42.056 36.1825 42.091 36.1825 42.1275C36.1825 42.164 36.168 42.1989 36.1422 42.2247C36.1164 42.2505 36.0815 42.265 36.045 42.265H11.955Z"
        stroke="#0B41CD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.955 42.265C11.9185 42.265 11.8836 42.2505 11.8578 42.2247C11.832 42.1989 11.8175 42.164 11.8175 42.1275C11.8175 42.091 11.832 42.056 11.8578 42.0303C11.8836 42.0045 11.9185 41.99 11.955 41.99H36.045C36.0815 41.99 36.1164 42.0045 36.1422 42.0303C36.168 42.056 36.1825 42.091 36.1825 42.1275C36.1825 42.164 36.168 42.1989 36.1422 42.2247C36.1164 42.2505 36.0815 42.265 36.045 42.265H11.955Z"
        stroke="#0B41CD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.955 42.265C11.9185 42.265 11.8836 42.2505 11.8578 42.2247C11.832 42.1989 11.8175 42.164 11.8175 42.1275C11.8175 42.091 11.832 42.056 11.8578 42.0303C11.8836 42.0045 11.9185 41.99 11.955 41.99H36.045C36.0815 41.99 36.1164 42.0045 36.1422 42.0303C36.168 42.056 36.1825 42.091 36.1825 42.1275C36.1825 42.164 36.168 42.1989 36.1422 42.2247C36.1164 42.2505 36.0815 42.265 36.045 42.265H11.955Z"
        stroke="#0B41CD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.955 42.265C11.9185 42.265 11.8836 42.2505 11.8578 42.2247C11.832 42.1989 11.8175 42.164 11.8175 42.1275C11.8175 42.091 11.832 42.056 11.8578 42.0303C11.8836 42.0045 11.9185 41.99 11.955 41.99H36.045C36.0815 41.99 36.1164 42.0045 36.1422 42.0303C36.168 42.056 36.1825 42.091 36.1825 42.1275C36.1825 42.164 36.168 42.1989 36.1422 42.2247C36.1164 42.2505 36.0815 42.265 36.045 42.265H11.955Z"
        stroke="#0B41CD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.955 42.265C11.9185 42.265 11.8836 42.2505 11.8578 42.2247C11.832 42.1989 11.8175 42.164 11.8175 42.1275C11.8175 42.091 11.832 42.056 11.8578 42.0303C11.8836 42.0045 11.9185 41.99 11.955 41.99H36.045C36.0815 41.99 36.1164 42.0045 36.1422 42.0303C36.168 42.056 36.1825 42.091 36.1825 42.1275C36.1825 42.164 36.168 42.1989 36.1422 42.2247C36.1164 42.2505 36.0815 42.265 36.045 42.265H11.955Z"
        stroke="#0B41CD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.955 42.265C11.9185 42.265 11.8836 42.2505 11.8578 42.2247C11.832 42.1989 11.8175 42.164 11.8175 42.1275C11.8175 42.091 11.832 42.056 11.8578 42.0303C11.8836 42.0045 11.9185 41.99 11.955 41.99H36.045C36.0815 41.99 36.1164 42.0045 36.1422 42.0303C36.168 42.056 36.1825 42.091 36.1825 42.1275C36.1825 42.164 36.168 42.1989 36.1422 42.2247C36.1164 42.2505 36.0815 42.265 36.045 42.265H11.955Z"
        stroke="#0B41CD"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M16 20H19V28H16V20Z" fill="#1482FA" />
      <path d="M28 10H31V28H28V10Z" fill="#1482FA" />
      <path d="M22 22H25V28H22V22Z" fill="#1482FA" />
      <path d="M22 16H25V20H22V16Z" fill="#1482FA" />
    </g>
    <defs>
      <clipPath id="clip0_866_19680">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconForApp;
