import styled, { css } from "styled-components";
import intersection from "lodash/intersection";
import * as R from "ramda";

const slimStyles = css`
  padding: 0.8rem 1.2rem 0;
`;

const footerStyles = css`
  padding: 0.2rem 1.2rem 1.5rem 1.2rem;
`;
export const BasicPartialCardLayout = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem 1.2rem 0.8rem;
  word-break: break-all;
  ${(props) => props.slim && slimStyles}
  & > div {
    flex: 0 0 50%;
  }
  & > .full {
    flex: 1 0 100%;
  }
  & > .right {
    text-align: right;
  }
  & [data-testid="deviceCardInstrumentStatusText"] {
    margin-left: 5px;
  }
  ${(props) => props.footer && footerStyles}
  & [data-testid="equipmentNickName"] {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word; /* Important for long words! */
  }
`;

export const DeviceCardBookingLayout = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0.5rem 1.2rem;
`;

export const BasicPartialCardHeaderLayout = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1.2rem 0.8rem;
  word-break: break-all;
  ${(props) => props.slim && slimStyles}
  & > div {
    flex: 0 0 33.33%;
  }
`;

export const BasicHistoryHeaderLayout = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  word-break: break-all;
  ${(props) => props.slim && slimStyles}
  & > div {
    flex: 0 0 33.33%;
  }
`;

const justifyContentBetweenStyles = css`
  justify-content: space-between;
`;

export const StatusStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  justify-content: flex-start;
  & > svg.gray {
    color: #737373;
  }
  & > svg.orange {
    color: #ffc414;
  }
  & > .iconlabel {
    margin-left: 11px;
  }
  & .maintext {
    font-weight: 700;
  }
  ${(props) => props.between && justifyContentBetweenStyles}
`;

export const iconStyle = { marginRight: "6px" };

export const chipStyle = { marginLeft: "6px" };

// run statusess
export const RunStatusesError = {
  error: "error",
  aborted: "aborted"
};

export const RunStatusesSuccess = {
  normal: "normal",
  finished: "finished"
};

// detailed statusess
export const ErrorStatusesDetailed = {
  error: "error",
  stopping: "stopping"
};
export const NormalStatusesDetailed = {
  startingup: "startingup",
  initializing: "initializing",
  standby: "standby",
  preparing: "preparing",
  ready: "ready",
  processing: "processing"
};

export const ServiceStatusesDetailed = {
  service: "service",
  maintenance: "maintenance"
};

export const UnknownStatusesDetailed = {
  poweroff: "poweroff",
  shutdown: "shutdown",
  shuttingdown: "shuttingdown"
};

// Result statuses
export const STATUSES = {
  ERROR: "ERROR",
  SERVICE: "SERVICE",
  NORMAL: "NORMAL",
  UNKNOWN: "UNKNOWN"
};

export const getStatus = (statuses = []) => {
  const errors = [...Object.keys(ErrorStatusesDetailed)];
  const unknownStatuses = [...Object.keys(UnknownStatusesDetailed)];
  const normalStatuses = [...Object.keys(NormalStatusesDetailed)];
  const serviceStatuses = [...Object.keys(ServiceStatusesDetailed)];

  if (getIntersection(statuses, errors).length) return STATUSES.ERROR;
  if (getIntersection(statuses, unknownStatuses).length)
    return STATUSES.UNKNOWN;

  if (getIntersection(statuses, normalStatuses).length) return STATUSES.NORMAL;
  if (getIntersection(statuses, serviceStatuses).length)
    return STATUSES.SERVICE;
  return STATUSES.UNKNOWN;
};

const getData = (arr) => R.compose(R.map(R.compose(R.trim, R.toLower)))(arr);

export const getIntersection = (values = [], statuses = []) => {
  if (!Array.isArray(values) || !Array.isArray(statuses)) return [];
  return intersection(getData(values), getData(statuses));
};
