import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";
import { signOut } from "../utils/signout";
import { equipmentLabel } from "../constants";

const listItemTextStyles = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "16px",
  letterSpacing: 0,
  textOverflow: "ellipsis",
  overflow: "hidden"
};
const listItemIconStyles = {
  color: "#737373",
  minWidth: 24,
  marginRight: 8
};
const listItemIconSelectedStyles = {
  color: "#0066CC",
  minWidth: 24,
  marginRight: 8,
  marginLeft: -4
};
const listItemStyles = (theme) => ({
  "&$selected": {
    borderLeftColor: "#0066CC",
    borderLeftWidth: 4,
    borderLeftStyle: "solid",
    color: "#0066CC",
    backgroundColor: "#D2E9FF",
    "&:hover": {
      backgroundColor: "#A5D2FF",
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#D2E9FF"
      }
    }
  },
  "&:hover": {
    backgroundColor: "EFEFEF"
  }
});

const useStyles = makeStyles((theme) => {
  return {
    listItem: listItemStyles(theme),
    selected: {},
    listItemText: listItemTextStyles,
    listItemIcon: listItemIconStyles,
    listItemIconSelected: listItemIconSelectedStyles
  };
});

const displayNone = css`
  display: none;
`;
const MenuText = styled.div`
  width: 100%;
  font-size: 16px;
  letter-spacing: 0px;
  color: #333333;
  align-items: center;
  font-weight: 500;
  padding-left: 16px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 48px;
  ${(props) => !props.isVisible && displayNone}
`;

const AppMenuList = ({ name, mobileOpen, client }) => {
  const classes = useStyles();
  return (
    <>
      <MenuText isVisible={mobileOpen} data-testid="toolbar-menu-label">
        {equipmentLabel.pascalCase} Monitoring
      </MenuText>
      <Divider />
      <List>
        <ListItem
          classes={{
            root: classes.listItem,
            selected: classes.selected
          }}
        >
          <ListItemIcon
            data-testid="drawer-menu-item-icon"
            classes={{
              root: classes.listItemIcon
            }}
          >
            <i className="one-icons">user</i>
          </ListItemIcon>
          <ListItemText
            data-testid="drawer-menu-item-name"
            classes={{
              primary: classes.listItemText
            }}
          >
            {name}
          </ListItemText>
        </ListItem>
        <ListItem
          data-testid="drawer-menu-item-logout"
          onClick={() => signOut(client)}
          button
          classes={{
            root: classes.listItem,
            selected: classes.selected
          }}
        >
          <ListItemIcon
            data-testid="drawer-menu-item-icon"
            classes={{
              root: classes.listItemIcon
            }}
          >
            <i className="one-icons">log_off</i>
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.listItemText
            }}
          >
            Log out
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem
          classes={{
            root: classes.listItem,
            selected: classes.selected
          }}
        >
          <ListItemIcon
            data-testid="drawer-menu-item-icon"
            classes={{
              root: classes.listItemIcon
            }}
          >
            <i className="one-icons">help</i>
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.listItemText
            }}
          >
            User Assistance
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};
export default AppMenuList;
