import { differenceBy, uniqBy } from "lodash";
import { getDeviceIdentity } from "../../../instrumentsFilter/redux/reducer";

export const updateDigitalLabDeviceUserNotification = async ({
  mutation,
  updateAction,
  payload
}) => {
  const { notificationSubscriptions, ids, id, email, isOn } = payload;
  try {
    const newValues = isOn
      ? differenceBy(notificationSubscriptions, ids, getDeviceIdentity)
      : uniqBy([...notificationSubscriptions, ...ids], getDeviceIdentity);
    updateAction(newValues);
    await mutation({
      variables: {
        id,
        email,
        notificationSubscriptions: newValues
      }
    });
  } catch (e) {
    updateAction(notificationSubscriptions);
    console.log(e);
  }
};
