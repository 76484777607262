import React, { useCallback, useContext } from "react";
import { connect } from "react-redux";
import GenericSubscription from "../../../../components/shared/GenericSubscription";
import { DeviceStatusesContext } from "./DeviceStatusesWrapper";
import { LIST_SELECTED_DIGITAL_LAB_DEVICE_STATUS } from "../../../../gql/devices/queries";
import { ON_UPDATE_DIGITAL_LAB_DEVICE_STATUS } from "../../../../gql/devices/subscription";
import { Module } from "../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

export const DevicesSubscription = ({ monitor, mapItem = (x) => x }) => {
  const { dispatchAction } = useContext(DeviceStatusesContext);
  const onDeviceUpdateCallback = useCallback((updated) => {
    dispatchAction({
      type: "update",
      payload: mapItem(updated?.data?.onUpdateDigitalLabDeviceStatus)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!monitor.length) {
    return null;
  }
  return (
    <GenericSubscription
      query={LIST_SELECTED_DIGITAL_LAB_DEVICE_STATUS}
      subscription={ON_UPDATE_DIGITAL_LAB_DEVICE_STATUS}
      onUpdateCallback={onDeviceUpdateCallback}
      id={generateID.UUID(Module.DeviceSubscription, `subscription`, "generic")}
    />
  );
};

const mapStateToProps = (state) => ({
  monitor: state.devices.monitor
});

export default connect(mapStateToProps)(DevicesSubscription);
