import React, { useCallback } from "react";
import { connect } from "react-redux";
import { selectGroupInstruments } from "./redux/actions";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import intersectionWith from "lodash/intersectionWith";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import { DeviceComparator } from "./redux/reducer";
import { Module } from "../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const badgeBase = {
  minWidth: 37,
  height: 21,
  borderRadius: "30px",
  padding: "4px 9px",
  fontWeight: 700,
  fontSize: 12,
  lineHeight: 14,
  letterSpacing: 0.12,
  backgroundColor: "#E8E8E8",
  marginRight: 16
};

export const useStyles = makeStyles(() => ({
  badge: badgeBase,
  badgeSelected: {
    ...badgeBase,
    backgroundColor: "#E9F4FF"
  },
  listItem: {
    fontSize: 16,
    "&$selected": {
      backgroundColor: "#D2E9FF",
      borderColor: "#D2E9FF",
      "&:hover": {
        backgroundColor: "#A5D2FF"
      }
    }
  },
  selected: {},
  listItemIcon: {
    minWidth: 28
  },
  listItemText: {
    fontSize: 16
  },
  listItemTextPrimary: {
    fontSize: 16
  }
}));

const DeviceCheckboxGroup = ({
  name = "",
  selected = [],
  selectInstruments,
  deviceIds = []
}) => {
  const classes = useStyles();
  const handleCheck = useCallback(
    (values) => () => {
      selectInstruments(values);
    },
    [selectInstruments]
  );
  const isChecked = useCallback(
    (deviceIdsParam) =>
      intersectionWith(deviceIdsParam, selected, DeviceComparator).length ===
      deviceIdsParam.length,
    [selected]
  );
  return (
    <ListItem
      selected={isChecked(deviceIds)}
      data-testid="device-checkbox-group-item"
      role={undefined}
      dense
      button
      divider
      onClick={handleCheck(deviceIds)}
      classes={{ root: classes.listItem, selected: classes.selected }}
      id={generateID.UUID(Module.FilterName, `filter_list`, "li_item")}
    >
      <ListItemIcon id={generateID.UUID(Module.FilterName, `filter_list`, "li_item_icon")} classes={{ root: classes.listItemIcon }}>
        <Checkbox
          data-testid="device-checkbox-group-item-checkbox"
          edge="start"
          checked={isChecked(deviceIds)}
          tabIndex={-1}
          disableRipple
          color="primary"
          id={generateID.UUID(Module.FilterName, `filter_list`, "li_item_checkout")}
        />
      </ListItemIcon>
      <ListItemText
        classes={{
          root: classes.listItemText,
          primary: classes.listItemTextPrimary
        }}
        primary={name}
        data-testid="device-checkbox-group-item-text"
        id={generateID.UUID(Module.FilterName, `filter_list`, "li_item_text")}
      />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="selected devices in the group"
          title="selected devices in the group"
          id={generateID.UUID(Module.FilterName, `filter_list`, "li_item_button")}
        >
          <Badge
            id={generateID.UUID(Module.FilterName, `filter_list`, "li_item_badge")}
            data-testid="device-checkbox-group-item-badge"
            badgeContent={
              intersectionWith(deviceIds, selected, DeviceComparator).length +
              "/" +
              deviceIds.length
            }
            classes={{
              badge: isChecked(deviceIds)
                ? classes.badgeSelected
                : classes.badge
            }}
            showZero
          />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="number of devices in the group"
          title="number of devices in the group"
          id={generateID.UUID(Module.FilterName, `filter_list`, "li_item_button_icon")}
        ></IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const mapStateToProps = (state) => ({
  selected: state.devices.selected
});

export default connect(mapStateToProps, {
  selectInstruments: selectGroupInstruments
})(DeviceCheckboxGroup);
