import React from "react";
import { connect } from "react-redux";
import { changeFormFilters } from "../redux/actions";
import { useControlStyles } from "./styles";
import { selectValuesByKey } from "../../../../selectors";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import TextField from "@material-ui/core/TextField";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

const LocationControl = ({ location, changeFilter, types }) => {
  const classes = useControlStyles();
  console.log(types);

  return (
    <GridAutocomplete
      id={generateID.UUID(Module.FilterName, `location_control`, "auto_complete")}
      className={classes.margin}
      optionsList={types}
      label="Equipment location"
      value={location || null}
      key={location}
      onAutoCompleteChange={(val) => {
        const value = val ? val : "";
        changeFilter({
          key: "location",
          value: value
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          id={generateID.UUID(Module.FilterName, `location_control`, "textfield")}
          label="Equipment location"
        ></TextField>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  types: selectValuesByKey("location")(state.devices.instruments),
  location: state.devices.controls?.location
});

export default connect(mapStateToProps, {
  changeFilter: changeFormFilters
})(LocationControl);
