import React from "react";
import styled from "styled-components";
import { LocationControl, NameControl, TypeControl } from "./controls";
import DevicesList from "./DevicesList";
import DeviceFilterHeader from "./DeviceFilterHeader";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";

const ControlsStyled = styled.div`
  box-sizing: border-box;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
`;

const InstrumentsFilterMainView = () => {
  return (
    <>
      <ControlsStyled  id={generateID.UUID(Module.FilterName, `control`, "style")} data-testid="controls-view">
        <DeviceFilterHeader />
        <LocationControl />
        <TypeControl />
        <NameControl />
      </ControlsStyled>
      <DevicesList />
    </>
  );
};

export default InstrumentsFilterMainView;
