import * as R from "ramda";
export const filterLocalization = (localization = "", deg = 10) =>
  localization.split(".").slice(0, deg).join(".");

export const groupByKey = (
  key = "",
  arr = [],
  filterGroup = filterLocalization
) =>
  arr.reduce((acc, curr) => {
    if (!(`${key}` in curr)) return acc;
    const loc = filterGroup(`${curr[key]}`);
    if (loc in acc) return { ...acc, [loc]: [...acc[loc], curr] };
    return { ...acc, [loc]: [curr] };
  }, {});

export const findSelectedItem = R.curry(
  (selected, item) =>
    selected.findIndex(
      (val) =>
        val?.inventoryId === item?.inventoryId
    ) !== -1
);
