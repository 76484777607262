import React from "react";
import styled from "styled-components";
import { equipmentLabel, Module } from "../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
`;
export const DeviceFilterAppbarTitle = () => {
  return (
    <Title data-testid="device-filter-appbar-title" id={generateID.UUID(Module.MonitoringName, `appbar`, "title")}>
      Monitored {equipmentLabel.lowerCase}
    </Title>
  );
};
export default DeviceFilterAppbarTitle;
