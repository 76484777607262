import { ClickAwayListener, Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { compose } from "redux";
import styled, { css } from "styled-components";
import { setModalAPIVisibility } from "../../features/appMode/redux/actions";
import { displayNameSelector } from "../../selectors/user";
import { useMobile } from "../../utils/hooks";
import { signOut } from "../../utils/signout";
import UserInfoTopCard from "./UserInfoTopCard";

const UserTopMenuCardStyled = styled.div`
  position: relative;
  .userNameAppBar {
    margin-right: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    color: #333333;
  }
`;

const displayBlock = css`
  display: block;
`;
const transformFormMobile = css`
  transform: translateX(50%);
`;

const UserTopMenuCardMenu = styled.div`
  display: none;
  width: 260px;
  position: absolute;
  top: 100%;
  right: 0;
  ${(props) => props.show && displayBlock}
  border-radius: 0 0 4px 4px;
  background-color: ${(props) => props.theme.card.backgroundColor};
  box-shadow: ${(props) => props.theme.card.shadow};
  z-index: 999;

  ${(props) => props.isMobile && transformFormMobile}
`;

const ActionSection = styled.div`
  margin: 0.5rem 1rem 1rem 1rem;
`;

const useStyles = makeStyles(() => ({
  button: {
    justifyContent: "flex-start",
    textTransform: "none"
  },
  startIcon: {
    color: "#737373"
  }
}));
const UseCardPadding = styled.div`
  padding: 0px !important;
`;

const UserTopMenuCard = ({ client, setOpen, name }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const isMobile = useMobile();
  return (
    <>
      <UseCardPadding data-testid="user-card-padding">
        <ClickAwayListener onClickAway={() => setShow(false)}>
          <UserTopMenuCardStyled>
            <span
              className="userNameAppBar"
              data-testid="user-top-menu-card-user-name"
            >
              {name}
            </span>
            <IconButton
              key="user-1"
              color="inherit"
              data-testid="logout-card"
              onClick={() => setShow(!show)}
            >
              <i className="one-icons">user</i>
            </IconButton>
            <UserTopMenuCardMenu
              data-testid="user-top-menu-card-menu"
              show={show}
              isMobile={isMobile}
            >
              <UserInfoTopCard />
              <Divider />
              <ActionSection>
                <Button
                  classes={{
                    root: classes.button,
                    startIcon: classes.startIcon
                  }}
                  data-testid="start-app-in-tv-mode-button"
                  fullWidth
                  color="default"
                  startIcon={<i className="one-icons">view_monitoring</i>}
                  onClick={() => {
                    setShow(!show);
                    setOpen(true);
                  }}
                >
                  Start app in TV mode
                </Button>
                <Button
                  classes={{
                    root: classes.button,
                    startIcon: classes.startIcon
                  }}
                  fullWidth
                  color="default"
                  startIcon={<i className="one-icons">log_off</i>}
                  onClick={() => signOut(client)}
                  data-testid="logout-btn"
                >
                  Log out
                </Button>
              </ActionSection>
            </UserTopMenuCardMenu>
          </UserTopMenuCardStyled>
        </ClickAwayListener>
      </UseCardPadding>
    </>
  );
};
const mapStateToProps = (store) => ({
  name: displayNameSelector(store),
  email: store.userMonitorDevices.email
});
export default compose(
  connect(mapStateToProps, { setOpen: setModalAPIVisibility }),
  withApollo
)(UserTopMenuCard);
