import styled from "styled-components";
import DeviceCardIconLabel from "../monitoringDevices/devicesViews/deviceCard/deviceCardElements/DeviceCardIconLabel";
import moment from "moment";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";

const HistoryCard = styled.div`
  width: 100%;
  background: #fafafa;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 16px;
  padding: 12px;
  flex-shrink: 0;
`;
const ColumnInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 0 4px;
  flex-wrap: wrap;
  word-break: break-all;
  white-space: break-spaces;
`;
const RunStatus = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 18px;
`;
const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HistoryCardOfLog = ({ historyData }) => {
  return (
    <HistoryCard 
    id={generateID.UUID(Module.HistoryInstrument, `history`, "card_log")}
    data-testid="history-card-log">
      <RunStatus 
      id={generateID.UUID(Module.HistoryInstrument, `history`, "run_status")}
      data-testid="history-card-log-run-status">
        {historyData.runStatus}
      </RunStatus>
      <CardContent id={generateID.UUID(Module.HistoryInstrument, `history`, "card_content")} data-testid="history-card-log-card-content">
        <ColumnInformation id={generateID.UUID(Module.HistoryInstrument, `history`, "column_info")} data-testid="history-card-log-card-content-column-information">
          <DeviceCardIconLabel icon="runId" label={historyData.runId} />
          {historyData.runStartTime ? (
            <DeviceCardIconLabel
              icon="runStartTime"
              label={moment(historyData.runStartTime).format("DD-MMM-YYYY")}
              subtext={moment(historyData.runStartTime).format("h:mm a")}
              id={generateID.UUID(Module.HistoryInstrument, `history`, "device_card_icon")}
            />
          ) : (
            <DeviceCardIconLabel icon="runEndTime" id={generateID.UUID(Module.HistoryInstrument, `history`, "device_car_icon_label")}/>
          )}
        </ColumnInformation>
        <ColumnInformation id={generateID.UUID(Module.HistoryInstrument, `history`, "log_card_content_column")} data-testid="history-card-log-card-content-column-information">
          <DeviceCardIconLabel icon="operator" label={historyData.operator} id={generateID.UUID(Module.HistoryInstrument, `history`, "list_operator")}/>
          {historyData.runEndTime ? (
            <DeviceCardIconLabel
              id={generateID.UUID(Module.HistoryInstrument, `history`, "devicecard_runend")}
              icon="runEndTime"
              label={moment(historyData.runEndTime).format("DD-MMM-YYYY")}
              subtext={moment(historyData.runEndTime).format("h:mm a")}
            />
          ) : (
            <DeviceCardIconLabel icon="runEndTime" label={"Ongoing"} id={generateID.UUID(Module.HistoryInstrument, `history`, "devicecard_ongoing")} />
          )}
        </ColumnInformation>
      </CardContent>
    </HistoryCard>
  );
};

export default HistoryCardOfLog;
