import React from "react";
import Typography from "@material-ui/core/Typography";
import InstrumentStatus from "./InstrumentStatus";
import { equipmentLabel, Module } from "../../../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const DeviceCardInstrumentStatus = ({ detailedStatus }) => {
  return (
    <div data-testid="deviceCardInstrumentStatus">
      <Typography
        data-testid="deviceCardInstrumentStatusLabel"
        variant="caption"
        display="block"
        gutterBottom
        id={generateID.UUID(Module.MonitoringName, `devicecard_status`, "typography")}
      >
        {equipmentLabel.pascalCase} status
      </Typography>
      <InstrumentStatus detailedStatus={detailedStatus} />
    </div>
  );
};

export default DeviceCardInstrumentStatus;
