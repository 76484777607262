import React, { useCallback } from "react";
import { connect } from "react-redux";
import { changeFormFilters } from "../redux/actions";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Search from "@material-ui/icons/Search";
import { useControlStyles } from "./styles";
import { TextField } from "@material-ui/core";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

const NameControl = ({ name, changeFilter }) => {
  const classes = useControlStyles();
  const handleName = useCallback(
    (evt) => {
      const value = evt.target.value;
      changeFilter({
        key: "name",
        value: value
      });
    },
    [changeFilter]
  );
  return (
    <FormControl
      fullWidth
      className={classes.margin}
      variant="outlined"
      data-testid="name-form-control"
      id={generateID.UUID(Module.FilterName, `name_control`, "form_control")}
    >
      <TextField
        data-testid="name-input"
        id="outlined-adornment-name"
        value={name}
        onChange={handleName}
        multiline={true}
        placeholder="Search by Nickname or Serial number or Equipment ID"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" id={generateID.UUID(Module.FilterName, `name_control`, "input_adornment")} data-testid="name-input-adornment">
              <Search  style={{ color: "#737373" }} id={generateID.UUID(Module.FilterName, `name_control`, "search")} data-testid="name-search" />
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  name: state.devices.controls?.name
});

export default connect(mapStateToProps, {
  changeFilter: changeFormFilters
})(NameControl);
