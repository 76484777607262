import React, { useState, useCallback } from "react";
import { HistoryTogglerContext } from "../../contexts";

const HistoryTogglerContextWrapper = ({ children }) => {
  const [historyDevice, setHistoryDevice] = useState(null);
  const historyToggle = useCallback((device) => {
    setHistoryDevice(device);
  }, []);
  const historyClose = useCallback(() => {
    setHistoryDevice(null);
  }, []);
  return (
    <HistoryTogglerContext.Provider
      value={{
        historyDevice,
        historyToggle,
        historyClose
      }}
    >
      {children}
    </HistoryTogglerContext.Provider>
  );
};

export default HistoryTogglerContextWrapper;
