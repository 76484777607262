import React, { useCallback } from "react";
import { connect } from "react-redux";
import { setSelectedInstruments } from "./redux/actions";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { findSelectedItem } from "../../../utils/helpers/array";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
    "&$selected": {
      backgroundColor: "#D2E9FF",
      borderColor: "#D2E9FF",
      "&:hover": {
        backgroundColor: "#A5D2FF"
      }
    }
  },
  selected: {},
  listItemIcon: {
    minWidth: 28
  },
  listItemText: {
    fontSize: 16
  },
  listItemTextPrimary: {
    fontSize: 16
  }
}));

const DeviceCheckbox = ({ selected, selectInstruments, device }) => {
  const classes = useStyles();
  const handleCheck = useCallback(
    (value) => () => selectInstruments(value),
    [selectInstruments]
  );
  const isChecked = useCallback(
    (val) => findSelectedItem(selected)(val),
    [selected]
  );
  return (
    <ListItem
      selected={isChecked({
        inventoryId: device?.inventoryId
      })}
      data-testid="devices-list-item"
      key={`${device?.inventoryId}`}
      role={undefined}
      dense
      button
      divider
      onClick={handleCheck({
        inventoryId: device?.inventoryId
      })}
      classes={{ root: classes.nested, selected: classes.selected }}
    >
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        <Checkbox
          data-testid="devices-list-item-checkbox"
          edge="start"
          checked={isChecked({
            inventoryId: device?.inventoryId
          })}
          tabIndex={-1}
          disableRipple
          color="primary"
        />
      </ListItemIcon>
      <ListItemText
        classes={{
          root: classes.listItemText,
          primary: classes.listItemTextPrimary
        }}
        primary={device.shortIdentifier}
        data-testid="devices-list-item-text"
      />
    </ListItem>
  );
};

const mapStateToProps = (state) => ({
  selected: state.devices.selected
});

export default connect(mapStateToProps, {
  selectInstruments: setSelectedInstruments
})(DeviceCheckbox);
