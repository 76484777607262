export const ID_TOKEN_PATH = "signInUserSession.idToken.payload";
export const SYNC_PROFILE_FIELDS = {
  givenName: "custom:firstName",
  familyName: "custom:lastName",
  name: "preferred_username",
  phone: "custom:phoneNumber",
  siteName: "custom:site"
};
export const DEFAULT_SITE = "Rotkreuz";

export const REQUIRED_APP_ACCESS_GROUP = "StatusUpdateReaders";

export const equipmentLabel = {
  pascalCase: "Equipment",
  lowerCase: "equipment"
};
export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const DEFAULT_DESCRIPTION_CONSTANT = {
  moduleVersion: "MODULE VERSION",
  moduleName: "APP NAME",
  ciJobId: "CI ID JOB",
  commitHash: "COMMIT HASH",
  updatedAt: "UPDATED AT"
};

export const LINKED_INSTANCE = {
  coreLab: "Corelab SAP",
  molecular: "RMD SAP"
};

export const DEFAULT_DEVICE_STATUS = {
  deviceStatus: "UNKNOWN",
  detailedStatus: "PoweredOff",
  runStatus: null,
  runId: null,
  operator: null,
  runStartTime: null,
  runEndTime: null
};

export const Module = {
  MonitoringName: "monitoring",
  FilterName: "filter",
  MonitoringList: "monitoringList",
  DeviceSubscription: "devicesubscription",
  HistoryInstrument: "historyInstrument",
  HistoryDrawer: "historyDrawer" 
}