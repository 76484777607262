import gql from "graphql-tag";

export const CREATE_DIGITAL_LAB_DEVICE_USER = gql`
  mutation createDigitalLabDeviceUser(
    $email: String!
    
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
    
  ) {
    createDigitalLabDeviceUser(
      input: {
        email: $email
        
        familyName: $familyName
        givenName: $givenName
        name: $name
        phoneNumber: $phone
        
      }
    ) {
      id
      email
      familyName
      givenName
      name
      phoneNumber
      notificationSubscriptions {
        inventoryId
      }
      createdAt
      updatedAt

    }
  }
`;

export const UPDATE_DIGITAL_LAB_DEVICE_USER = gql`
  mutation updateDigitalLabDeviceUser(
    $id: ID!
    $email: String!
    $notificationSubscriptions: [InstrumentReferenceInput]
    
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
    
  ) {
    updateDigitalLabDeviceUser(
      input: {
        id: $id
        email: $email
        notificationSubscriptions: $notificationSubscriptions
        
        familyName: $familyName
        givenName: $givenName
        name: $name
        phoneNumber: $phone
        
      }
    ) {
      id
      email
      familyName
      givenName
      name
      phoneNumber
      notificationSubscriptions {
        inventoryId
      }
      createdAt
      updatedAt
      
    }
  }
`;

export const UPDATE_DIGITAL_LAB_DEVICE_USER_FILTER = gql`
  mutation updateDigitalLabDeviceUser(
    $id: ID!
    $email: String!
    $deviceUseState: DeviceUseStateInput
  ) {
    updateDigitalLabDeviceUser(
      input: { id: $id, email: $email, deviceUseState: $deviceUseState }
    ) {
      id
      email
      name
      createdAt
      updatedAt
    }
  }
`;
