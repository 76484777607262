import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { Auth } from "./auth-provider";

process.env.NODE_ENV === "development" && (Amplify.Logger.LOG_LEVEL = "DEBUG");

export const AVAILABLE_MODES = Object.freeze({
  TV: "tv",
  widget: "widget",
  normal: "normal"
});

export const getAWSAppSyncClinet = ({
  mode = "",
  apiKey = "",
  getToken = () => null
}) => {
  switch (mode) {
    case "tv": {
      return new AWSAppSyncClient({
        url: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
        region: process.env.REACT_APP_AWS_PROJECT_REGION,
        auth: {
          type: AUTH_TYPE.API_KEY,
          apiKey
        }
      });
    }
    case "widget": {
      Amplify.configure(awsconfig);
      return new AWSAppSyncClient({
        url: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
        region: process.env.REACT_APP_AWS_PROJECT_REGION,
        disableOffline: true,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: getToken
        }
      });
    }
    default: {
      Amplify.configure(awsconfig);
      return new AWSAppSyncClient({
        url: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
        region: process.env.REACT_APP_AWS_PROJECT_REGION,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () =>
            (await Auth.currentSession()).getAccessToken().getJwtToken()
        }
      });
    }
  }
};
