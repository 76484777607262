import { Button } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";
import NotFoundIcon from "../icons/NotFoundIcon";
import { useMobile } from "../utils/hooks";

const errorScreenMobile = css`
  height: auto;
  transform: none;
`;
const errorScreenH2Mobile = css`
  font-size: 36px;
`;
const errorScreenH5Mobile = css`
  font-size: 12px;
`;

export const ErrorbuttonStyled = styled(Button)`
  && {
    text-transform: none;
    margin-top: 0;
    background-color: #0066cc;
  }
`;
export const ErrorScreenStyled = styled.div`
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 96px);
  transform: translateY(-40px);
  ${(props) => props.isMobile && errorScreenMobile}

  color: #0066cc;
  & > h2 {
    font-size: 34px;
    font-weight: 500;
    text-align: center;
    line-height: 40px;
    margin-bottom: 12px;
    ${(props) => props.isMobile && errorScreenH2Mobile}
  }
  & > h5 {
    font-size: 16px;
    font-weight: 500;
    width: 377px;
    white-space: wrap;
    text-overflow: ellipsis;
    text-align: center;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 24px;
    ${(props) => props.isMobile && errorScreenH5Mobile}
    color: #333;
  }
`;

const NotFound = ({
  text = "Sorry, we can't find page you are looking for.",
  title = "Page not found",
  redirectPath = "/"
}) => {
  const isMobile = useMobile();
  return (
    <ErrorScreenStyled
      isMobile={isMobile}
      data-testid="main-page-not-authenticated-with-error-not-found"
    >
      <NotFoundIcon />
      <h2>{title}</h2>
      <h5>{text}</h5>
      <ErrorbuttonStyled
        data-testid="main-page-action-button-not-found"
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.href = redirectPath;
        }}
      >
        Go to home
      </ErrorbuttonStyled>
    </ErrorScreenStyled>
  );
};

export default NotFound;
