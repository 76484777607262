import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { setModalAPIVisibility } from "./redux/actions";
import { connect } from "react-redux";
import queryString from "query-string";
import { setApiKeyAndFilters } from "../../utils/localStorage";
import { useApiKey } from "../../utils/hooks";
import { equipmentLabel } from "../../constants"; 

const ApiKeyModal = ({
  show = true,
  setOpen,
  selected = [],
  instrumentsView
}) => {
  const api_key = useApiKey();
  const [api, setApi] = useState("");
  const [label, setLabel] = useState("");
  const handleClose = () => setOpen(false);
  const saveApiAndCloseModal = () => {
    console.log("saveApiAndCloseModal");
    setApiKeyAndFilters({ api_key: api });
    setOpen(false);
    setApi("");
  };
  const getLinkToTVMode = () =>
    `${window.location?.origin}/tv/${btoa(
      queryString.stringify({
        selected,
        instrumentsView,
        label
      })
    )}`;

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-api-key">Please type in API KEY</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Type in optional title for selected {equipmentLabel.lowerCase}.
        </DialogContentText>
        <TextField
          autoFocus
          value={label}
          onChange={(evt) => setLabel(evt.target.value)}
          margin="dense"
          id="group"
          label="Title group"
          type="text"
          fullWidth
          style={{ marginBottom: "1.2rem" }}
        />
        {api_key && (
          <DialogContentText id="form-dialog-in-use-api-key">
            Your storage already contain API Key. Click to use existed key:
            <Link
              href={getLinkToTVMode()}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit"
              }}
            >
              <IconButton color="primary" onClick={saveApiAndCloseModal}>
                <i className="one-icons">launch</i>
              </IconButton>
            </Link>
          </DialogContentText>
        )}
        <DialogContentText>
          To open app in the TV mode you must provide API KEY.
        </DialogContentText>
        <TextField
          autoFocus
          value={api}
          onChange={(evt) => setApi(evt.target.value)}
          margin="dense"
          id="api_key"
          label="API KEY"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={saveApiAndCloseModal} color="primary" disabled={!api}>
          <Link
            href={getLinkToTVMode()}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "inherit"
            }}
          >
            Confirm and open TV mode
          </Link>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  selected: state.devices.selected,
  show: state.appMode.apiModalShow,
  instrumentsView: state.userMonitorDevices.instrumentsView
});

export default connect(mapStateToProps, { setOpen: setModalAPIVisibility })(
  ApiKeyModal
);
