export const mainTheme = {
  indicator: {
    normal: "#0066CC",
    error: "#CC0033",
    service: "#FFC414",
    unknown: "#737373"
  },
  card: {
    shadow: "0px 3px 6px #39455059",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D3D3D3"
  },
  list: {
    headerBackgroundColor: "#efefef"
  },
  userLogo: {
    backgroundColor: "#037B84"
  },
  notification: {
    warning: {
      color: "#333333",
      backgroundColor: "#ffffff",
      border: "2px solid #cc0033",
      backgroundIconColor: "#cc0033",
    },
    success: {
      color: "#333333",
      backgroundColor: "#ffffff",
      border: "2px solid #00875A",
      backgroundIconColor: "#00875A",
    },
  },
};
