import React from "react";
import { Rehydrated } from "aws-appsync-react";
import { CircularProgress } from "@material-ui/core";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { AuthenticatedApp } from "../../App";
import Authentication from "../../features/Authentication";
import configureStore from "../../store/configureStore";
import { getAWSAppSyncClinet } from "../../appSyncClient";
import { FullScreenCentered } from "../../components/shared";
import BigScreenMode from "../devices/bigscreen/BigScreenMode";
import { getApiKeyAndFilters } from "../../utils/localStorage";
import ApiKeyModal from "./ApiKeyModal";
import ErrorBoundary from "../../components/ErrorBoundary";
import { Switch, Route } from "react-router-dom";
import ModuleVersion from "../system-version/ModuleVersion";
import FooterSection from "../unique-env-info/FooterSection";
import HeaderSection from "../unique-env-info/HeaderSection";
import BackendInfoWrapper from "../backend-info-context/BackendInfoWrapper";

export const store = configureStore();

process.env.NODE_ENV === "development" && (window.store = store);
export class AppMode extends React.Component {
  state = {
    appSyncClient: null
  };
  componentDidMount() {
    if (this.props.mode === "tv") {
      const { api_key } = getApiKeyAndFilters();
      this.setState({
        appSyncClient: getAWSAppSyncClinet({
          mode: this.props.mode,

          api_key
        })
      });
    } else {
      this.setState({
        appSyncClient: getAWSAppSyncClinet({ mode: this.props.mode })
      });
    }
  }
  render() {
    if (this.state.appSyncClient === null)
      return (
        <FullScreenCentered>
          <CircularProgress size={80} />
        </FullScreenCentered>
      );
    return (
      <ApolloProvider client={this.state.appSyncClient}>
        <Provider store={store}>
          <Rehydrated>
            <>
              {this.props.children}
              <ApiKeyModal />
            </>
          </Rehydrated>
        </Provider>
      </ApolloProvider>
    );
  }
}

export const AppTVMode = () => (
  <ErrorBoundary>
    <AppMode mode="tv">
      <BigScreenMode />
    </AppMode>
  </ErrorBoundary>
);
export const AppNormal = () => (
  <ErrorBoundary>
    <AppMode mode="normal">
      <BackendInfoWrapper>
        <HeaderSection />
        <Switch>
          <Route exact path="/info">
            <ModuleVersion />
          </Route>
          <Route exact path="/">
            <Authentication>
              <AuthenticatedApp />
            </Authentication>
          </Route>
          <Route exact path="/equipmentList/:InventoryId">
            <Authentication>
              <AuthenticatedApp />
            </Authentication>
          </Route>
        </Switch>
        <FooterSection />
      </BackendInfoWrapper>
    </AppMode>
  </ErrorBoundary>
);

export const App = ({ children }) => (
  <ErrorBoundary>
    <AppMode mode="normal">{children}</AppMode>
  </ErrorBoundary>
);

export default AppMode;
