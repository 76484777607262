import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import moment from "moment";
import DeviceCardStatusIndicator from "../deviceCard/deviceCardElements/DeviceCardStatusIndicator";
import { STATUSES } from "../shared/statues";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../../constants";

const DeviceListItemHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 1.4rem;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  background-color: ${(props) => props.theme.card.backgroundColor};
`;
const DeviceMobileIndicatorStyled = styled.span`
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  background-color: ${(props) => {
    switch (props.status) {
      case STATUSES.NORMAL:
        return props.theme.indicator.normal;
      case STATUSES.ERROR:
        return props.theme.indicator.error;
      case STATUSES.SERVICE:
        return props.theme.indicator.service;
      default:
        return props.theme.indicator.unknown;
    }
  }};
`;

const DeviceListItemHeaderTitleStyled = styled.div`
  display: flex;
  width: 400px;
  max-width: 100%;
  justify-content: space-between;
  & > h6 {
    flex: 0 1 150px;
  }
  box-sizing: border-box;
  padding: 0.7rem 1.4rem;
  align-items: center;
  background-color: #efefef;
`;

const DateStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  line-height: 18px;
  & span:nth-child(2) {
    font-weight: 400;
    line-height: 16px;
  }
`;

export const RunStartTimeFormat = ({ runStartTime }) => {
  return (
    <DateStyled id={generateID.UUID(Module.MonitoringList, `list`, "date_style_start")}>
      {runStartTime ? (
        <>
          <span>{moment(runStartTime).format("DD-MMM-YYYY")}</span>
          <span>{moment(runStartTime).format("h:mm a")}</span>
        </>
      ) : (
        <span>-</span>
      )}
    </DateStyled>
  );
};

export const RunEndTimeFormat = ({ runEndTime }) => {
  return (
    <DateStyled id={generateID.UUID(Module.MonitoringList, `list`, "date_style_end")}>
      {runEndTime ? (
        <>
          <span>{moment(runEndTime).format("DD-MMM-YYYY")}</span>
          <span>{moment(runEndTime).format("h:mm a")}</span>
        </>
      ) : (
        <span>Ongoing</span>
      )}
    </DateStyled>
  );
};

export const DeviceListItemHeaderTitle = ({ headers }) => {
  if (!Array.isArray(headers)) return null;
  if (!headers.length) return null;
  return (
    <DeviceListItemHeaderTitleStyled 
    id={generateID.UUID(Module.MonitoringList, `list`, "header_title")}
    data-testid="deviceListItemHeaderTitle">
      {headers.map((header, index) => (
        <Typography 
        id={generateID.UUID(Module.MonitoringList, `list_${index}`, "typography")}
        variant="subtitle1" display="block" key={header}>
          {header}
        </Typography>
      ))}
      <ExpandLessIcon id={generateID.UUID(Module.MonitoringList, `list`, "expandless")} style={{ opacity: 0 }} />
    </DeviceListItemHeaderTitleStyled>
  );
};

const DeviceListItemHeader = ({
  open,
  serialNumber,
  materialNumber,
  equipmentModel,
  equipmentNickName,
  inventoryId,
  ...indicatorProps
}) => {
  return (
    <DeviceListItemHeaderStyled
      data-testid={`deviceListItemHeader-${inventoryId}`}
      id={generateID.UUID(Module.MonitoringList, `list`, "header_style")}
    >
      <Typography
        variant="subtitle1"
        display="block"
        style={{ paddingLeft: "18px", position: "relative" }}
        id={generateID.UUID(Module.MonitoringList, `list`, "typography")}
      >
        <DeviceCardStatusIndicator
          {...indicatorProps}
          renderIndicator={(props) => (
            <DeviceMobileIndicatorStyled
            id={generateID.UUID(Module.MonitoringList, `list`, "indicator")}
              data-testid="mobile-indicator"
              {...props}
            />
          )}
        />
        {equipmentNickName}
      </Typography>
      <Typography
      id={generateID.UUID(Module.MonitoringList, `list`, "typography_1")}
      variant="subtitle1" display="block">
        {equipmentModel}
      </Typography>
      {open ? (
        <ExpandLessIcon data-testid="expand-less" id={generateID.UUID(Module.MonitoringList, `list`, "expandless_icon")}/>
      ) : (
        <ExpandMoreIcon data-testid="expand-more" id={generateID.UUID(Module.MonitoringList, `list`, "expandless_more")}/>
      )}
    </DeviceListItemHeaderStyled>
  );
};

export default DeviceListItemHeader;
