import { Auth } from "aws-amplify";
import { get, pick } from "lodash";
import throttle from "lodash/throttle";
import { store } from "../features/appMode/AppMode";
import { keyIds } from "../features/devices/instrumentsFilter/redux/reducer";
import { UPDATE_DIGITAL_LAB_DEVICE_USER_FILTER } from "../gql/devices/mutation";

const extractVariables = (state) => {
  const {
    name = "",
    type = "",
    location = ""
  } = get(state, "devices.controls");
  const selected =
    get(state, "devices.selected")?.map((item) => pick(item, keyIds)) || [];
  const sortKey = get(state, "devices.sortKey") || "";
  const id = get(state, "userMonitorDevices.id") || "";
  const email = get(state, "userMonitorDevices.email") || "";
  const instrumentsView =
    get(state, "userMonitorDevices.instrumentsView") || "";

  return {
    id,
    email,
    deviceUseState: {
      processingStatusFilter: {
        deviceName: name,
        deviceType: type,
        deviceLocation: location
      },
      processingStatusSelected: selected,
      processingStatusSortKey: sortKey,
      instrumentsView
    }
  };
};

export async function signOut(apolloClient) {
  try {
    if (apolloClient) {
      const _variables = extractVariables(store.getState());
      await apolloClient.mutate({
        mutation: UPDATE_DIGITAL_LAB_DEVICE_USER_FILTER,
        variables: _variables
      });
    }
    await Auth.signOut({ global: true });
    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

let _initialize = false;

export const subscribeToFilterChange = (apolloClient) => {
  if (!_initialize) {
    _initialize = true;
    store.subscribe(
      throttle(
        async () => {
          const _variables = extractVariables(store.getState());
          await apolloClient.mutate({
            mutation: UPDATE_DIGITAL_LAB_DEVICE_USER_FILTER,
            variables: _variables
          });
          process.env.NODE_ENV === "development" &&
            console.log("filter saved!", _variables);
        },
        3000,
        { leading: false }
      )
    );
  }
};
