import { combineReducers } from "redux";
import devicesReducer from "../features/devices/instrumentsFilter/redux/reducer";
import userMonitoringDevicesReducer from "../features/devices/monitoringDevices/redux/reducer";
import appModeReducer from "../features/appMode/redux/reducer";

const rootReducer = combineReducers({
  devices: devicesReducer,
  userMonitorDevices: userMonitoringDevicesReducer,
  appMode: appModeReducer
});

export default rootReducer;
